import React, { useEffect, useRef, useCallback } from 'react'
import { graphviz } from 'd3-graphviz'

const GraphvizDiagram = React.forwardRef(({ data, title }, ref) => {
    const vizRef = useRef(null)

    useEffect(() => {
        //const graphContainer = d3.select(vizRef.current)
        //const height = graphContainer.node().clientHeight;
        //const width = graphContainer.node().clientWidth;
        graphviz(vizRef.current)
            .keyMode('id')
            .width('100%')
            .height('100%')
            .fit(true)
            .zoomScaleExtent([0.5, 2])
            .renderDot(data)
    }, [data])

    const resetZoom = useCallback(() => {
        if (vizRef.current) {
            graphviz(vizRef.current).resetZoom();
        }
    }, [vizRef]);

    return (
        <div className='flex-container'>
            <div className='flex-column' >
                <div ref={ref} className='printable'>
                    <div className='header'>
                        <div className='centre-content'>
                            <figcaption > {title}</figcaption>
                        </div>
                    </div>
                    <div className='graph-frame'
                        ref={vizRef}
                    />
                </div>
            </div>

            <div style={{ padding: '10px' }} >
                <button type='button' className='csbutton'
                    onClick={resetZoom}
                    style={{ display: "block" }}
                >
                    Re-centre
                </button>
            </div>
        </div>
    )
})

export default GraphvizDiagram