import { useContext, useState, useEffect, useRef } from 'react'

import { ModelContext } from '../../Model/ModelProvider'
import { SecondCutContext } from './SecondCutLogic'
import BigPicture from '../../DiagramHandler/BigPicture';
import GraphvizDiagramInteractive from '../../DiagramHandler/GraphvizDiagramInteractive';
import GraphvizDiagram from '../../DiagramHandler/GraphvizDiagram';

import {radCreator} from '../../RADHandler/RADCreator';

import useContextMenu from '../../hooks/useContextMenu';

import { BsHexagon } from 'react-icons/bs';
import { FiPlus } from 'react-icons/fi';
// import { FiCornerLeftUp } from 'react-icons/fi';
import { MdMergeType } from 'react-icons/md';
import { FaPlus } from 'react-icons/fa';


const PAD2Diagram = () => {
    const { getPAD2, getGeneratorById, updatePADEditableSchema } = useContext(ModelContext);
    const { graphId, setGraphId, restore, selectedNodeType } = useContext(SecondCutContext);
    const { editableSchema, modified } = getPAD2();

    const { getRADs, setIsChanged, updateModel } = useContext(ModelContext);

    const [ghost, setGhost] = useState(false)
    const [deliveriesOnly, setDeliveriesOnly] = useState(false)
    const toggleGhost = () => {
        setGhost(!ghost)
    }
    const toggleDeliveriesOnly = () => {
        setDeliveriesOnly(!deliveriesOnly)
    }

    const [graph, setGraph] = useState('');

    const contextRef = useRef(null)
    const inset = { width: 250, height: 350 }
    const [position, isShowContext] = useContextMenu(contextRef, inset);

    const foldAllTaskCMPs = () => {

        const negotiators = editableSchema.filter(s => s.class === 'negotiates' && s.status === 'live');
        const taskCMPs = negotiators.flatMap(s => {
            const generator = getGeneratorById(s.genId);
            return generator?.processType === 'Task Force' ? [s.source] : [];
        });

        let updatedSchema = [...editableSchema];
        taskCMPs.forEach(source => {
            updatedSchema = foldCMP(source, updatedSchema);
        });

        updatePADEditableSchema('pad2', updatedSchema, true);
    }

    const handleFoldCMPClick = (id) => {
        console.log('handleFoldCMPClick', id)
        const updatedSchema = foldCMP(id, editableSchema);

        // Update the model
        updatePADEditableSchema('pad2', updatedSchema, true);
    }

    const foldCMP = (id, schema) => {
        if (!schema.find(s => s.id === id && s.type === 'CMP')) return schema;

        const edges = schema.filter(s => s.source === id);

        // Get the target of the 'negotiates' edge and the CP of the CMP
        const negotiatesTarget = edges.find(s => s.class === 'negotiates')?.target;
        const startsTarget = edges.find(s => s.class === 'starts')?.target;

        // Update the status of the node and its edges
        const updatedSchema = schema.map(s => {
            if (s.id === id || s.source === id || s.target === id) {
                return { ...s, status: 'dead' };
            }
            return s;
        });

        // Add a new start edge if it does not exist
        const newEdgeId = `${negotiatesTarget}_${startsTarget}_starts`;
        if (!updatedSchema.some(s => s.id === newEdgeId)) {
            updatedSchema.push({
                source: negotiatesTarget,
                target: startsTarget,
                class: 'starts',
                id: newEdgeId,
                status: 'live',
                label: 'start',
                tail: '"A"',
                actionType: 'fold'
            });
        }
        return updatedSchema;
    }

    /* const handleRADLister = () => {
        console.log('handleRADLister')
        const rads = editableSchema.filter(s => s.class === 'node' && s.status === 'live')
        console.log('rads', rads)
    } */

    const handleRADCreator = () => {
        const nodes = editableSchema.filter(s => s.class === 'node' && s.status === 'live')
        console.log('handleRADCreator', nodes)
        radCreator(nodes, getRADs, updateModel, setIsChanged);
    }

    useEffect(() => {
        if (!editableSchema) return;
        const intro = `digraph G {
        newrank=true;
        nodesep=0.75;    
        node [shape=box, style=rounded, fontname="Arial"];
        edge [dir=both, arrowhead=empty, arrowtail=odot, arrowsize=1.5, labeldistance=0.8, fontname="Arial", fontsize=12]
        
        \n\n `;
        const outro = `\n}`;

        const pnodes = editableSchema
            .filter(s => s.class === 'node' && (ghost || (!ghost && s.status === 'live')))
            .reduce((acc, node) => {
                if (node.uow !== 'External') {
                    const nodeClass = (node.status === 'dead') ? ' disabled' : '';
                    const pnode = `\t${node.id} [id="${node.id}"; label="${node.label}", class="${nodeClass}"];\n`;
                    return acc + `\n\tsubgraph cluster_${node.uow} { id=cluster_${node.uow}; rank = "same"; style="filled, rounded";\n` + pnode + `}`;
                } else {
                    // Handle 'External' case
                    const ow = `\t${node.id} [id=${node.id}; shape=circle, color=none, label="☁", fontsize=64];\n`;
                    return acc + ow;
                }
            }, '');

        const pedges = editableSchema
            .filter(s => s.class !== 'node' && (ghost || (!ghost && s.status === 'live'))
                && (!deliveriesOnly || (deliveriesOnly && s.class === 'delivers')))
            .reduce((acc, edge) => {
                if (edge.class !== 'external_requests') {
                    const edgeClass = edge.class.concat((edge.status === 'dead') ? ' disabled' : '');
                    const pedge = `\t${edge.source} -> ${edge.target} [id="${edge.id}"; label="${edge.label}", class="${edgeClass}", taillabel = ${edge.tail}];\n`;
                    return acc + pedge;
                } else {
                    const pedge = `\t${edge.source} -> ${edge.target} [id=${edge.id}; label="${edge.label}", class="${edge.class}",tailclip=false, arrowtail=dot ];\n`;
                    return acc + pedge;
                }
            }, '');

        const digraph = intro + pnodes + pedges + outro;
        setGraph(digraph);
        return () => {
            console.log("PAD2Diagram: clear graph");
            setGraph('');
        };
    }, [editableSchema, modified, ghost, deliveriesOnly]);

    const caption = 'Second-cut process architecture diagram'
    const vizRef = useRef(null);
    return (
        <>
            {graph ?
                <div className='flex-column'>
                    <BigPicture Component={GraphvizDiagram} componentProps={{ data: graph, title: caption }} />
                    <GraphvizDiagramInteractive ref={vizRef} data={graph} graphId={graphId} setGraphId={setGraphId}
                        contextRef={contextRef}>
                        <button type='button' className='csbutton'
                            onClick={toggleGhost}
                            style={{ display: "block" }}
                        >
                            👻 {ghost ? ' on' : ' off'}
                        </button>
                        <button type='button' className='csbutton'
                            onClick={toggleDeliveriesOnly}
                            style={{ display: "block" }}
                        >
                            🚚 {deliveriesOnly ? ' on' : ' off'}
                        </button>
                        <button type='button' className='csbutton'
                            onClick={restore}
                            style={{ display: "block" }}
                        >
                            ⏪ Start again
                        </button>
                        <button type='button' className='csbutton'
                            onClick={() => handleRADCreator()}
                            style={{ display: "block" }}
                        >
                            📁 Output RAD List
                        </button>
                        {isShowContext &&
                            <div className="context-menu" style={{ top: position.y, left: position.x }} >
                                <ul className="menu">
                                    <li className="menu__item" onClick={foldAllTaskCMPs}>
                                        <MdMergeType className='menu__icon' />
                                        ' Fold all task CMPs to CP'
                                    </li>
                                    {selectedNodeType === 'CMP' && <li className="menu__item"
                                        onClick={() => handleFoldCMPClick(graphId)}>
                                        <MdMergeType className='menu__icon' />
                                        ' Fold selected CMP to a CP'
                                    </li>}
                                    {/*                                     <li className="menu__item">
                                        <FiCornerLeftUp className='menu__icon' />
                                        ' Short-circuit a delivery chain'
                                    </li> */}
                                    <li className="menu__item">
                                        <BsHexagon className='menu__icon' />
                                        ' Add designed entities'
                                    </li>
                                    <li className="menu__item" onClick={() => handleRADCreator()}>
                                        <FaPlus className='menu__icon' />
                                        ' Create RADs for each process'
                                    </li>
                                    <li className="menu__item">
                                        <FiPlus className='menu__icon' />
                                        ' Create RAD for selected process'
                                    </li>
                                </ul>
                            </div>
                        }
                    </GraphvizDiagramInteractive>
                    <div className='footer'>
                        <div className='centre-content'>
                            <figcaption > {caption}</figcaption>
                        </div>
                    </div>
                </div>
                : <p style={{ textAlign: 'center' }}>Nothing to see here yet!</p>

            }

            {/*    <textarea> {graph}</textarea>  */}
        </>
    )
}

export default PAD2Diagram

