import React from 'react';

const CaseRefinementIcon = ({ width = 20, height = 20, className = '', style = {} }) => {
    return (
        <svg width={`${width*2}px`} height={`${height*3/2}px`} viewBox={`0 0 ${width * 4} ${height * 3 / 2}`}
            className={`case-refinement ${className}`} style={style}>
            {/* Triangle  */}
            <polygon points={`${width / 3},${height / 4} ${width * 4 / 3},${height / 4} ${width * 5 / 6},${height * 11 / 8}`} fill="none" />

            {/* Short line at the  bottom */}
            <line x1={width * 5 / 6} y1={height * 5 / 4} x2={width * 5 / 6} y2={height * 7 / 4} />

            {/* Draw a line that extends out to the left from the mid-height of the polygon and curves upwards, about 12px long in total */}
            <path d={`M${width / 8},${height * 3 / 4} q-5,0 -10,-10`} fill="none" />

            {/* Sidelines */}
            <line x1={width / 8} y1={height * 3 / 4} x2={width / 2} y2={height * 3 / 4} />
            <line x1={width * 9 / 8} y1={height * 3 / 4} x2={width * 9/4} y2={height * 3 / 4} />

            {/* draw another triangle to the right of the first one */}
            <polygon points={`${width * 2},${height / 4} 
            ${width * 3},${height / 4} 
            ${width * 5/2 },${height * 11 / 8}`}
                fill="none" />
            <line x1={width * 5 / 2} y1={height * 5 / 4} x2={width * 5 / 2} y2={height * 7 / 4} />

            <line x1={width *11 / 4} y1={height * 3 / 4} x2={width * 7/2} y2={height * 3 / 4} />   

        </svg>
    );
};

export default CaseRefinementIcon;