// FirstCutUI.js
import React, { useContext } from 'react';
import { FirstCutContext } from './FirstCutLogic';
import PAD1Diagram from './PAD1Diagram';
import PAD1Editor from './PAD1Editor';
import ModalDialog from '../ModalDialog';


const FirstCutUI = ({ isActive }) => {
    const { isModalOpen, handleMerge, handleReplace } = useContext(FirstCutContext);
    console.log("FirstCutUI isActive:", isActive);
    if (!isActive) return null;

    return (
        <div className='pad-container' style={{ position: "relative" }}>
            <div className='flex-pane'>
                <PAD1Diagram />
                <PAD1Editor />
            </div>
            <ModalDialog
                isOpen={isModalOpen}
                message="The first-cut has changed due to edits to either EBEs or Generators. Do you want to merge or replace your edits?"
                onConfirm={handleMerge}
                onCancel={handleReplace}
            />
        </div>
    );
};

export default FirstCutUI;
