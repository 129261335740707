// Compute expanses
// For each activity thread in a role, compute the x and y expanses
// and adjust relative positions to one another. Maximal expanse of threads 
// then sets the expanse of the role.
// Height of a thread is the sum of the heights of all activities in the thread
// plus state lines, and width is max width activity. Add padding for clearances.
// Relative y position of an activity takes into account those that it connects to in other roles.
// A recompute may be required to shuffle activities around to avoid overlaps.
//import { shapes } from './ShapeLibrary/Shapes';
import { useCallback } from 'react';
import { useRAD } from './RADHandlerLogic';

const yOffset = 50; // Vertical offset between activities in the absence of connections
const baseX = 25; // Default X position for all activities
const baseWidth = 20; // Default width
const baseHeight = 20;



export const useLayoutLogic = () => {

    const { orderActivities,
        getRoles, getThreads,
        getConnections, getRoleThreads,
        getCaseConditions, getPartThreads,
        getSubthreads,
        getThreadSequence
    } = useRAD();


    const calculateActivityPositions = useCallback((thread, threadActivities) => {
        const orderedActivities = orderActivities(threadActivities, thread.prestate_id);
        const positions = orderedActivities.map((activity, index) => (
            {
                activity_id: activity.activity_id,
                thread_id: activity.thread_id,
                role_id: thread.role_id,
                x: baseX,
                y: yOffset,
                width: activity.width || baseWidth,
                height: baseHeight,
                index,
            }));

        return positions;
    }, [orderActivities]);


    const calcOffsetY = (expanse, expanseArray) => {
        // iterate through expanses in index order up to the current expanseindex
        // summing up the y values
        // of the threads that are part of the activity.
        //console.log('expanse', expanse, expanseArray);
        const yPos = expanseArray
            .filter(e => e.thread_id === expanse.thread_id && e.index <= expanse.index)
            .reduce((acc, curr) => acc + curr.y, 0);
        return yPos;
    }

    const threadExpanse = useCallback((thread, threadActivities) => {
        const positions = calculateActivityPositions(thread, threadActivities);

        // Use reduce to find minY and maxY
        const maxY = positions.reduce((acc, curr) => acc + curr.y, 0);
        const maxX = positions.reduce((acc, curr) => Math.max(acc, curr.x + curr.width), 0);

        const finalExtent = {
            width: maxX + 30, // padding
            height: maxY + 100, // padding
        };
        const position = { x: thread.x, y: thread.y };
        //console.log('thread position', thread.thread_id, position);
        const expanse = { thread_id: thread.id, role_id: thread.role_id, ...position, ...finalExtent, positions };

        return expanse;
    }, [calculateActivityPositions]);

    const calcWidth = useCallback((activity) => {
        // Calculate the width of the activity. Only refinements are other than default width.
        // This could get quite hairy as refinement sub-threads are potentially nested.
        // Just stick to one level for now.
        if (activity.class === 'case-refinement') {
            const count = getCaseConditions(activity.activity_id).length
            return baseWidth * count + 55;
        }
        else if (activity.class === 'part-refinement') {
            const count = getPartThreads(activity.activity_id).length;
            return baseWidth * count + 55;
        }
        else if (activity.class === 'part-repeat') {
            return baseWidth + 40;
        }
        return baseWidth;
    }, [getCaseConditions, getPartThreads]);

    const computeExpanses = useCallback(() => {
        // The expanses array shadows the rad state with computed positions and sizes
        // of roles, threads, activities, etc. It is used to render the RAD diagram.
        // The decoupling is necessary to avoid re-renders when the editRAD is updated.

        // Calculate the positions of activities per thread.
        // Then modify the interaction y offsets using the connections max y value.
        // Then calculate the expanses of the threads and roles


        const roles = getRoles().map(role => ({ id: role.id, x: role.x, y: role.y }));

        // Compute expanses for each thread and role
        const roleExpanses = roles.map(role => {
            const roleThreads = getRoleThreads(role.id);
            // Compute thread expanses
            const threadExpanses = roleThreads.map((thread, index) => {
                const activities = getThreadSequence(thread.id, thread.prestate_id);
                const threadActivities = activities.map(activity => ({ ...activity, width: calcWidth(activity) }));
                const expanse = threadExpanse(thread, threadActivities);
                //console.log('thread expanse', expanse);
                return { ...expanse, index }; // Return new  expanse
            });
            // Compute role expanse based on threads
            const extent = threadExpanses.reduce((expanse, thread) => ({
                xMin: Math.min(expanse.xMin, thread.x),
                xMax: Math.max(expanse.xMax, thread.x + thread.width),
                yMin: Math.min(expanse.yMin, thread.y),
                yMax: Math.max(expanse.yMax, thread.y + thread.height),
                threadCount: expanse.threadCount + 1
            }), { yMin: Infinity, yMax: -Infinity, xMin: Infinity, xMax: -Infinity, threadCount: 0 });

            // Now, set the height and width
            // If they have not been set, set them to  default values.
            extent.xMin = extent.xMin === Infinity ? 0 : extent.xMin;
            extent.xMax = extent.xMax === -Infinity ? 100 : extent.xMax;

            extent.yMin = extent.yMin === Infinity ? 0 : extent.yMin;
            extent.yMax = extent.yMax === -Infinity ? 160 : extent.yMax;

            extent.height = extent.yMax;  //- extent.yMin;
            extent.width = extent.xMax; //- extent.xMin;

            const finalExtent = {
                width: extent.width + 20, // padding
                height: extent.height + 20  // padding
            };

            const position = { x: role.x, y: role.y };
            const expanse = { role_id: role.id, ...position, ...finalExtent };
            //console.log('role expanse', expanse);
            return { ...expanse, threadExpanses };
        });
        //console.log('roleExpanses', roleExpanses);
        const rolePositions = roleExpanses.map(role => ({ role_id: role.role_id, x: role.x, y: role.y, width: role.width, height: role.height, threadCount: role.threadExpanses.length }));
        const threadPositions = roleExpanses.map(role => role.threadExpanses).flat().map(thread => ({ thread_id: thread.thread_id, role_id: thread.role_id, x: thread.x, y: thread.y, width: thread.width, height: thread.height }))
        //console.log('threadPositions', threadPositions);
        let activityPositions = roleExpanses.map(role => role.threadExpanses).flat().map(thread => thread.positions).flat();
        //console.log('activityPositions', activityPositions);

        // Compute the connections expanse
        const cons = getConnections();
        // get the set of activities that have shared connections

        const connectionExpanses = cons.map(connection => {
            const { id, interactions: conInteractions } = connection;

            const interactions = activityPositions.filter(interaction => conInteractions.includes(interaction.activity_id));
            //console.log('interactions', interactions);
            const threads = threadPositions
                .filter(thread => interactions.map(interaction => interaction.thread_id).includes(thread.thread_id));

            //console.log('threads', threads);
            const roles = roleExpanses.filter(role => threads.map(thread => thread.role_id).includes(role.role_id));

            // flatten out interactions, thread and roles into a single array
            const iPositions = interactions
                .map(i => {
                    //console.log('i', i)
                    const thread = threads.find(thread => thread.thread_id === i.thread_id);
                    const role = roles.find(role => role.role_id === i.role_id);
                    const yPos = calcOffsetY(i, activityPositions);
                    //console.log('yPos', yPos);
                    return {
                        interaction_id: i.activity_id,
                        thread_id: i.thread_id,
                        role_id: i.role_id,
                        index: i.index,
                        xPos: i.x,
                        yPos,
                        width: i.width,
                        height: i.height,
                        thread_x: thread ? thread.x : 0,  // Pick only x from thread
                        thread_y: thread ? thread.y : 0,  // Pick only y from thread
                        role_x: role ? role.x : 0,        // Pick only x from role
                        role_y: role ? role.y : 0,         // Pick only y from role
                        centreX: i.x + i.width / 2 + (thread ? thread.x : 0) + (role ? role.x : 0),
                        centreY: yPos + i.height / 2 + (thread ? thread.y : 0) + (role ? role.y : 0),
                    };
                });
            //console.log('iPositions', iPositions)

            const maxCentreY = iPositions.reduce((acc, curr) => Math.max(acc, curr.centreY), 0);

            // Update activity positions so that the activity has a new y position
            // 
            activityPositions.forEach(ap => {
                const foundInteraction = iPositions.find(ip => ip.interaction_id === ap.activity_id);
                if (foundInteraction) {
                    const role = rolePositions.find(rp => rp.role_id === ap.role_id);
                    const yPos = activityPositions
                        .filter(e => e.thread_id === foundInteraction.thread_id && e.index <= foundInteraction.index)
                        .reduce((acc, curr) => acc + curr.y, 0);
                    const newOffsetY = maxCentreY - yPos + yOffset - foundInteraction.height / 2 - foundInteraction.thread_y - foundInteraction.role_y;
                    if (ap.index > 0) {
                        ap.y = newOffsetY; // Update the y position based on the connection's yPos. Mutates the activityPositions array :(
                        //.log('activity position modified', ap);
                    }
                    else {
                        if (role && role.threadCount === 1) {
                            role.y = maxCentreY - yPos - yOffset + foundInteraction.height
                            //console.log('role position modified', role);
                        }
                        // The first activity in a thread is positioned at the top of the thread.
                        // We need to adjust the thread's y position to match the connection's yPos.
                        else {
                            const thread = threadPositions.find(rp => rp.thread_id === ap.thread_id);
                            if (thread) {
                                thread.y = maxCentreY - role.y - yPos - foundInteraction.height / 2
                                //console.log('thread position modified', thread);
                            }
                        }
                    }
                }
            });

            // Resize the thread and role based on the new activity positions
            threadPositions.forEach(tp => {
                const threadActivities = activityPositions.filter(activity => activity.thread_id === tp.thread_id);
                const maxY = threadActivities.reduce((acc, curr) => acc + curr.y, 0);
                tp.height = maxY + 100;
            });

            rolePositions.forEach(rp => {
                const roleThreads = threadPositions.filter(thread => thread.role_id === rp.role_id);
                const maxY = roleThreads.reduce((acc, curr) => Math.max(acc, curr.y + curr.height), 100);
                rp.height = maxY + 20;
            });


            const expanse = {
                connection_id: id,
                interactions: iPositions,
                centreY: maxCentreY,
            };
            //console.log('connection expanse', expanse);

            return { ...expanse };
        }
        );

        const newExpanses = {
            roles: rolePositions,
            activityThreads: threadPositions,
            activities: activityPositions,
            connections: connectionExpanses
        };

        return newExpanses;
    }, [calcWidth, threadExpanse, getRoles, getThreadSequence,
        getConnections, getRoleThreads]);

    const buildFlatRADTree = useCallback(() => {
        function postOrderTraversal(node) {
            // Accumulate children's values recursively, then add the current node's value
            return node.children.reduce((acc, child) => acc.concat(postOrderTraversal(child)), []).concat(node.value);
        }

        function addNode(tree, parentId, newNode) {
            // Find the parent node in the tree by comparing id properties
            function findNode(node, id) {
                if (node.value.id === id) {
                    return node;
                }
                for (let child of node.children) {
                    let found = findNode(child, id);
                    if (found) return found;
                }
                return null; // Not found
            }

            const parentNode = findNode(tree, parentId);
            if (parentNode) {
                // Add the new node to the children of the found parent node
                parentNode.children.push({ value: newNode, children: [] });
                if (newNode.class === 'case-refinement' || newNode.class === 'part-refinement' || newNode.class === 'part-repeat') {
                    // Add the thread's activities to the tree
                    const subThreads = getSubthreads(newNode.class, newNode.id);
                    subThreads.map(thread => {
                        addNode(tree, newNode.activity_id, thread);
                        const orderedActivities = getThreadSequence(thread.id, thread.prestate_id);
                        // A thread is a sequence of activities.
                        orderedActivities.forEach((activity) => {
                            const subNode = { ...activity, id: activity.activity_id };
                            addNode(tree, thread.id, subNode);
                        });
                        return null;
                    });
                }
            } else {
                console.log(`Parent node with id ${parentId} not found.`);
            }
        }

        function createNode(value) {
            return { value, children: [] };
        }
        let tree = createNode({ id: 'root', name: 'Root Node' });

        // Incrementally add nodes
        const roles = getRoles();
        roles.map(role => {
            return addNode(tree, 'root', role);
        });
        const threads = getThreads();
        threads.map(thread => {
            addNode(tree, thread.role_id, thread)

            const orderedActivities = getThreadSequence(thread.id, thread.prestate_id);
            // A thread is a sequence of activities.
            orderedActivities.forEach((activity) => {
                const newNode = { ...activity, id: activity.activity_id };
                addNode(tree, thread.id, newNode);
            });

            return null;
        });
        //console.log(JSON.stringify(tree, null, 4));
        console.log(postOrderTraversal(tree));
        return tree;
    }, [getRoles, getThreads, getSubthreads, getThreadSequence]);

    return { computeExpanses, buildFlatRADTree };
}