import { useState, useCallback } from 'react';
import * as d3 from 'd3';

export const useZoom = (diagramRef, viewBoxWidth, viewBoxHeight) => {
    const [currentScale, setCurrentScale] = useState(1);

    const applyZoom = (scale) => {
        const svg = d3.select(diagramRef.current).select('svg');
        svg.call(zoomBehavior.transform, d3.zoomIdentity.scale(scale));
    };

    function zoomed(event) {
        setCurrentScale(event.transform.k);
        const svg = d3.select(diagramRef.current).select('svg');
        const g = svg.select('g');
        g.attr('transform', event.transform);
    }

    const zoomBehavior = d3.zoom()
        .extent([[0, 0], [viewBoxWidth, viewBoxHeight]])
        .scaleExtent([0.5, 3])
        .on("zoom", zoomed);

    const zoomIn = () => {
        const svgElement = d3.select(diagramRef.current).select('svg');
        zoomBehavior.scaleBy(svgElement.transition().duration(200), 1.1);
        const newScale = currentScale * 1.1;
        setCurrentScale(newScale);
        return newScale;
    };

    const zoomOut = () => {
        const svgElement = d3.select(diagramRef.current).select('svg');
        zoomBehavior.scaleBy(svgElement.transition().duration(200), 0.9);
        const newScale = currentScale * 0.9;
        setCurrentScale(newScale);
        return newScale;
    };

    const resetZoom = () => {
        const svgElement = d3.select(diagramRef.current).select('svg');
        svgElement.transition().duration(300).call(zoomBehavior.transform, d3.zoomIdentity);
        setCurrentScale(1);
        return 1; // Reset scale to 1
    };


    function zoomToFit() {
        const svg = d3.select(diagramRef.current).select('svg');
        const g = svg.select('g');
        const bounds = g.node().getBBox();

        // No need to proceed if there's nothing to fit
        if (bounds.width === 0 || bounds.height === 0) return;

        const parent = svg.node();
        const fullWidth = parent.clientWidth;
        const fullHeight = parent.clientHeight;

        // Calculate the scale needed to fit the g element
        const widthScale = fullWidth / bounds.width;
        const heightScale = fullHeight / bounds.height;
        const scale = Math.min(widthScale, heightScale);

        // Adjust the scale to add some padding around the content
        const paddedScale = scale * 0.95; // For instance, 95% of the calculated scale

        // Calculate the center of the g element
        const midX = bounds.x + bounds.width / 2;
        const midY = bounds.y + bounds.height / 2;

        // Calculate the translation needed to center the g element
        const translateX = fullWidth / 2 - midX * paddedScale;
        const translateY = fullHeight / 2 - midY * paddedScale;

        // Apply the transformation
        g.transition()
            .duration(500)
            .call(
                zoomBehavior.transform,
                d3.zoomIdentity.translate(translateX, translateY).scale(paddedScale)
            );
    }

    const setScale = (scale) => {
        applyZoom(scale);
        setCurrentScale(scale);
        return scale;
    };

    return { zoomIn, zoomOut, resetZoom, zoomToFit, zoomBehavior, setScale, currentScale };
};

export const useDragBehavior = (handleChange) => {
    // Higher-order functions to create drag behavior
    const dragBehavior = useCallback(() => {
        let offsetX, offsetY;
        let dragStartPoint = null;

        function dragstarted(event, d) {
            dragStartPoint = { x: event.x, y: event.y };
            let transform = d3.select(this).attr("transform");
            let translate = transform ? transform.substring(10, transform.length - 1).split(",") : [0, 0];
            translate = translate.map(d => +d);

            offsetX = event.x - translate[0];
            offsetY = event.y - translate[1];

            d3.select(this).raise();

        }

        function dragged(event, d) {
            d3.select(this).attr("transform", `translate(${event.x - offsetX}, ${event.y - offsetY})`);
        }

        function dragended(event, d) {
            const distance = Math.hypot(event.x - dragStartPoint.x, event.y - dragStartPoint.y);

            if (distance < 5) { // Threshold in pixels to consider it a click instead of a drag
                return;
            }

            const el = d3.select(this);
            // Roles and Threads can be dragged
            if (el.classed('role-group')) {
                const role_id = el.attr("data-id"); // Assuming each role/thread has a data-id attribute
                //console.log('drag ended on role: ', role_id);
                handleChange({ type: 'updateRole', payload: { id: role_id, x: event.x - offsetX, y: event.y - offsetY } });
            }
            else if (el.classed('thread-group')) {
                const thread_id = el.attr("data-id");
                //console.log('drag ended on thread: ', thread_id);
                handleChange({ type: 'updateThread', payload: { id: thread_id, x: event.x - offsetX, y: event.y - offsetY } });
            }
            else {
                console.log('dragged element not implemented yet');
            }
        }

        return d3.drag()
            .on("start", dragstarted)
            .on("drag", dragged)
            .on("end", dragended);
    }, [handleChange]);

    // Empty drag function for now
    const dragStateBehaviour = useCallback(() => {
        function dragstarted(event, d) {
            // Since we only want to move the second endpoint,
            // we only need the starting position of the drag for that point.
            const secondEndPoint = d.endPoints[1];
            this.dragOffset = {
                x: event.x - secondEndPoint[0],
                y: event.y - secondEndPoint[1],
            };
            d3.select(this).raise();
        }

        function dragged(event, d) {
            const newX = event.x - this.dragOffset.x;
            const newY = event.y - this.dragOffset.y;
            const firstEndPoint = d.endPoints[0];

            // Update the endpoint in the data 'd'
            d.endPoints[1] = [newX, newY];

            // Since we're dealing with a line, update x2 and y2 attributes
            d3.select(this).select('line')
                .attr('x2', newX)
                .attr('y2', newY);

            // Move the state description
            d3.select(this).select('ellipse')
                .attr('cx', (newX - firstEndPoint[0]) / 2)
                .attr('cy', (newY - firstEndPoint[1]) / 2);

            d3.select(this).select('text')
                .attr('x', (10 + newX - firstEndPoint[0]) / 2)
                .attr('y', (newY - firstEndPoint[1]) / 2);
        }

        function dragended(event, d) {
            const distance = Math.hypot(event.x - d.endPoints[1][0], event.y - d.endPoints[1][1]);

            if (distance < 5) { // Threshold in pixels to consider it a click instead of a drag
                return;
            }

            const x = event.sourceEvent.pageX; //sourceEvent.pageX - event.sourceEvent.offsetX + d.x;
            const y = event.sourceEvent.pageY;

            const el = d3.select(this);
            // States can be dragged but only for connection open or end states into other threads within the role
            if (el.classed('state-group')) {
                console.log('drag ended: ', el);
                // look for a valid drop target
                // ... something, something
                const elementAtDrop = document.elementFromPoint(x,y);
                console.log('position at drag state end', x , y )
                console.log('element at drag state end', elementAtDrop);
                const amIaValidDropTarget = elementAtDrop.closest('g.state-group');
                console.log('am I a valid drop target', amIaValidDropTarget);
            }
            else {
                console.log('dragged element not implemented yet');
            }
        }

        return d3.drag()
            .on("start", dragstarted)
            .on("drag", dragged)
            .on("end", dragended);
    }, []);

    return { dragBehavior, dragStateBehaviour };
};
