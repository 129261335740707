import React from 'react'

export default function Settings() {
    return (
        <div className='page'>
            <h2>Settings</h2>
            <p>ipsem lorum etc</p>
        </div>
    );
}
