
// Desc: Main entry point for the application
// react
import React from 'react'
import { Routes, Route } from 'react-router-dom';

// styles
import './App.css';
import './css/main.css';
import './css/Site.css';
import './css/Window.css';

import routes from './routes';

// components
import NavBar from './features/NavBar/Navbar';
import Providers from './Providers';
import AppFooter from './AppFooter';

function App() {
  return (
    <Providers>
      <div className="site-container">
        <main className="main">
          <NavBar />
          <Routes>
            {routes.map(({ path, element }, index) => (
              <Route key={index} path={path} element={element} />
            ))}
          </Routes>
        </main>
        <AppFooter />
      </div>
    </Providers>
  );
}

export default App;