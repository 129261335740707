import { useState, useContext, useEffect } from 'react'

import { ModelContext } from '../../Model/ModelProvider';
import { FirstCutContext } from './FirstCutLogic'

const PADForm = ({ onSubmit }) => {
    const { graphId } = useContext(FirstCutContext)
    const { getPAD1 } = useContext(ModelContext);
    const { editableSchema } = getPAD1();
    const selectedElement = editableSchema.find(s => s.id === graphId)

    const label = selectedElement ? selectedElement.label : ''

    const [isChanged, setIsChanged] = useState(false)

    const [newLabel, setNewLabel] = useState('')

    useEffect(() => {
        setNewLabel(label)
        setIsChanged(false)
    },
        [label])

    const handleChange = (e) => {
        console.log('handleChange: ', e.target.value);
        setNewLabel(e.target.value)
        setIsChanged(true)
        //console.log('showLabel: ', label, 'newLabel: ', newLabel, 'isChanged: ', isChanged);
    }

    const handleKeyDown = (e) => {
        const trimmedText = e.target.value.trim()
        if (e.key === 'Enter' && trimmedText) {
            handleSubmit(e)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('handleSubmit: ', graphId, newLabel);
        if (graphId) { // Check if graphId is not null or undefined
            onSubmit({ id: graphId, label: newLabel });
        }
    }

    const handleReset = () => {
        // back out current changes
        setNewLabel(label)
        setIsChanged(false)
    }

    return (
        <div>
            {/* <p>Current selection?: {label ? label : 'None'}</p>
            <p>Changed: {isChanged ? 'Yes' : 'No'}</p> */}
            <form className="form-inline" onSubmit={handleSubmit} >
                <fieldset>
                    <input
                        value={newLabel}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        type="text"
                        name="label"
                        style={{ display: 'inline-block', width: '100%' }}
                        disabled={!graphId}
                    />
                    <p style={{ fontSize: 'smaller' }}>Enter to save, Esc to cancel</p>
                    <div className='button-container'>
                        <div className='right-buttons'>
                            <button name='submitBtn' type='submit' className='csbutton' disabled={!isChanged}
                                style={{ float: 'right', margin: '5px 10px' }}>Save</button>
                            <button name='resetBtn' type='button' className='csbutton' disabled={!isChanged}
                                onClick={handleReset} style={{ float: 'right', margin: '5px' }} >Cancel</button>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    )

}

export default PADForm