import React, { useContext } from 'react'
import { BsTrash } from "react-icons/bs"

import { GeneratorContext } from './GeneratorLogic';
import { ModelContext } from '../Model/ModelProvider';

const GeneratorListItem = ({ genSource }) => {

    const { generatorList } = useContext(GeneratorContext)
    const { getEBEById } = useContext(ModelContext);

  //  console.log('GeneratorListItem', genSource);
    const gens = generatorList.filter(g => g.source === genSource);
    const sourceEBE = getEBEById(genSource);
    const renderedSublistItems = gens.map((g) => {
        return <GeneratorSublistItem key={g.id} {...g} />
    })

    return (
        <div key={genSource}>
            <li key={'li' + genSource} id={genSource}>{sourceEBE ? sourceEBE.name : genSource}</li>
            <ul className="js-model-list"
                style={{
                    margin: '0px 0px 1px 0px'
                }}
                key={'ul_' + genSource}
                id={'ul_' + genSource}
            >
                {renderedSublistItems}</ul>
        </div>
    )
}

export default GeneratorListItem

const GeneratorSublistItem = (targetGen) => {
    const { id, target, description, processType } = targetGen

    const { selectedGenerator, setSelectedGenerator, removeGenerator } = useContext(GeneratorContext)
    const { getEBEById } = useContext(ModelContext);
    const selectedID = selectedGenerator ? selectedGenerator.id : null;

    const targetEBE = getEBEById(target);

    const setCurrentGenerator = () => {
        selectedID === id
            ? setSelectedGenerator(null)
            : setSelectedGenerator(targetGen)
    }

    const onDelete = () => {
        removeGenerator(targetGen)
        setSelectedGenerator(null)
    }

    return (

        <li id={id} onClick={setCurrentGenerator}
            className={selectedID === id
                ? 'checked'
                : ''}
            style={{
                paddingLeft: '100px',
                cursor: 'pointer'
            }}
        >
            <em >{description}</em>
            <strong> {targetEBE.name} </strong>
            <span>[{processType}]</span>
            <span className="remove" title="Delete this Generator!"
                style={{ visibility: (selectedGenerator && selectedGenerator.id === id) ? 'visible' : 'hidden' }}
                onClick={() => onDelete()}>
                <BsTrash
                    className="cs-icon"
                ></BsTrash>
            </span>
        </li>
    )
}

