
const compare = (a, b) => {
    if (a.id < b.id) { return -1 }
    if (a.id > b.id) { return 1 }
    return 0
}

const traverseList = (edge, index, deliveries, partialChain = [], allChains = []) => {
    const updatedPartialChain = [...partialChain, { ...edge, pos: index }];
    allChains.push(updatedPartialChain);

    const nextEdges = deliveries.filter(s => s.target === edge.source);
    if (nextEdges.length === 0) return allChains;

    return nextEdges.flatMap(nextEdge => traverseList(nextEdge, index + 1, deliveries, updatedPartialChain, allChains));
};

export const getDeliveryChains = (editSchema) => {
    if (!(Array.isArray(editSchema) && editSchema.length > 0)) return [];

    const d = editSchema.filter(s => s.class === 'delivers' && s.source !== s.target);
    if (!(Array.isArray(d) && d.length > 0)) return [];
    const deliveries = [...d]
        .map(e => ({ ...e, targetNode: editSchema.find(s => s.id === e.target)?.label }))
        .sort(compare)

    const chains = deliveries
        .flatMap((e, index) => traverseList(e, 0, deliveries))
        .filter(chain => chain.length > 1);

    const chainList = chains
        .map(deliveryChain => ({
            id: deliveryChain[0].target + deliveryChain[deliveryChain.length - 1].source + '_' + (deliveryChain.some(c => c.status === 'dead') ? 'deadChain' : 'liveChain'),
            live: deliveryChain.some(c => c.status === 'dead') ? 'deadChain' : 'liveChain',
            deliveryChain
        }))
        .reduce((unique, item) => unique.find(u => u.id === item.id) ? unique : [...unique, item], [])
        .sort(compare);

    return chainList
};

export const getShortCircuits = (editSchema) => {
    const safeSchema = JSON.parse(JSON.stringify(editSchema));
    return [...safeSchema]
        .filter(s => s.actionType === 'short-circuit')
        .map(shortPath => {
            const sourceNodeLabel = safeSchema.find(s => s.id === shortPath.source)?.label || '';
            const targetNodeLabel = safeSchema.find(s => s.id === shortPath.target)?.label || '';
            const deadPaths = getDeliveryChains(safeSchema)
                .filter(chain => chain.live === 'deadChain' && chain.chain.every(edge => edge.replacedBy === shortPath.id));
            const result = {
                shortPath: {
                    ...shortPath,
                    sourceNode: sourceNodeLabel,
                    targetNode: targetNodeLabel
                },
                deadPaths
            };
            return result;
        });
};

export const getShortCircuitList = (editSchema) => {
    const scs = editSchema.filter(s => s.actionType === 'short-circuit');
    if (!(Array.isArray(scs) && scs.length > 0)) return [];

    const sclist = scs.map(path => {
        const res = {
            ...path,
            sourceNodeLabel: editSchema.find(s => s.id === path.source)?.label,
            targetNodeLabel: editSchema.find(s => s.id === path.target)?.label,
        }
        return res;
    })
    return sclist;
}

export const getDeadPaths = (Id, editSchema) => {
    console.log('getDeadPaths', Id, getDeliveryChains(editSchema).filter(chain => chain.live === 'deadChain'))
    const deadPaths = getDeliveryChains(editSchema)
        .filter(path => path.live === 'deadChain'
            && path.deliveryChain.every(edge => edge.replacedBy === Id));
    return deadPaths;
}