// routes.js
import Model from './features/Model/Model';
import RADManager from './features/RADHandler/RADManager';
import About from './staticpages/About';
import Examples from './staticpages/Examples';
import Language from './staticpages/Language';
import Settings from './staticpages/Settings';
import Help from './staticpages/Help';
import NoPage from './staticpages/NoPage';

const routes = [
    { path: "/", element: <Model /> },
    { path: "rad/:radId?", element: <RADManager /> },
    { path: "about", element: <About /> },
    { path: "examples", element: <Examples /> },
    { path: "language", element: <Language /> },
    { path: "settings", element: <Settings /> },
    { path: "help", element: <Help /> },
    { path: "*", element: <NoPage /> }
];

export default routes;
