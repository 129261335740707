import SetZoom from '../SetZoom';

const ZoomControls = ({ onZoomIn, onZoomOut, onReset, onFit, setScale, currentScale }) => {
    return (
        <div className='right-button-container'>
            <SetZoom label={currentScale} setScale={setScale} />
            <button className='csbutton' onClick={onZoomIn}>Zoom In</button>
            <button className='csbutton' onClick={onZoomOut}>Zoom Out</button>
            <button className='csbutton' onClick={onFit}>Fit all</button>
        </div>
    );
};

export default ZoomControls;