import GeneratorLogic from '././GeneratorLogic';
import GeneratorUI from './GeneratorUI';

const GeneratorHandler = () => {
    return (
        <GeneratorLogic>
            <GeneratorUI />
        </GeneratorLogic>
    );
};

export default GeneratorHandler;