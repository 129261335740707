import { useEffect } from "react";
export const useOutsideCallback = (el, callback) => {
  useEffect(() => {
    const handler = (event) => {
      if (el.current && !el.current.contains(event.target)) {
        callback(); // Call the passed function
      }
    };

    // Add event listeners
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      // Cleanup the event listeners
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [el, callback]);

  // The hook doesn't need to return anything in this case
};
