import React, { useEffect, useRef, useCallback } from 'react';
import * as d3 from 'd3'
//import { RADEditorContext } from './RADEditor';
//import { shapes } from './ShapeLibrary/Shapes';
import {
    drawRoleGroup, drawStartRoleGroup, drawInteractionGroup, drawActionGroup,
    drawTriggerGroup, drawCaseRefinementGroup, drawPartRefinementGroup, drawPartRepeatGroup,
    drawEllipsisGroup
} from './ShapeLibrary/DrawShapes';
import { useDrop } from '../DropContext';

const shapeContainerSize = { width: 120, height: 420 };

const roleAt = { x: 10, y: 30 };
const triggerAt = { x: 10, y: 110 };
const interactionAt = { x: 10, y: 140 };
const actionAt = { x: 10, y: 175 };
const startRoleAt = { x: 10, y: 210 };
const caseRefinementAt = { x: 10, y: 240 };
const partRefinementAt = { x: 10, y: 280 };
const partRepeatAt = { x: 10, y: 320 };
const ellipsisAt = { x: 10, y: 360 };

const RADShapes = () => {
    const { setDraggedShape } = useDrop();
    const shapesRef = useRef(null);
    const ghostRef = useRef(null);

    const dragStartPoint = useRef({ x: null, y: null });

    // Drag area for the ghost element
    const showOverlay = () => {
        const overlay = document.getElementById('rad-overlay');
        if (overlay) {
            overlay.style.display = 'block'; // Show overlay
        }
    };

    const hideOverlay = () => {
        const overlay = document.getElementById('rad-overlay');
        if (overlay) {
            overlay.style.display = 'none'; // Hide overlay
        }
    };

    // Function to handle drag logic
    const handleDragStart = useCallback((event, d) => {
        dragStartPoint.current = { x: event.sourceEvent.pageX, y: event.sourceEvent.pageY };
        console.log('drag start', d);
        // Create ghost element
        if (!ghostRef.current) {
            showOverlay();

            document.body.style.cursor = 'grabbing';
            const ghostEl = document.createElement('div');
            ghostEl.id = 'ghost';
            ghostEl.className = d.class;
            ghostEl.style.position = 'absolute';
            ghostEl.style.visibility = 'hidden';
            const offsetX = event.sourceEvent.offsetX;
            const offsetY = event.sourceEvent.offsetY;

            // Calculate the initial position of the ghost element
            ghostEl.style.left = `${event.sourceEvent.pageX - offsetX + d.x}px`;
            ghostEl.style.top = `${event.sourceEvent.pageY - offsetY + d.y}px`;
            ghostEl.style.width = `${d.width}px`; // Adjust dimensions as needed
            ghostEl.style.height = `${d.height}px`; // Adjust dimensions as needed
            ghostEl.style.borderRadius = `${d.rx || 0}px`; // Adjust dimensions as needed
            ghostEl.style.borderRadius = `${d.ry || 0}px`; // Adjust dimensions as needed
            ghostEl.style.pointerEvents = 'none'; // Make it non-interactive
            ghostEl.style.opacity = '0.7'; // Make it semi-transparent
            ghostEl.style.zIndex = '1000';
            if (d.class === 'activity start-role') {
                // draw diagonals
                ghostEl.innerHTML = `<svg width="20" height="20">
                <rect width="20" height="20"  />
                <line x1="0" y1="0" x2="20" y2="20"  />
                <line x1="20" y1="0" x2="0" y2="20"  />
                </svg>`;
            }
            else if (d.class === 'case-refinement') {
                ghostEl.innerHTML = `<svg width="30" height="30">
                <polygon points="15,25 25,5 5,5" />
                <line x1="5" y1="15" x2="10" y2="15" />
                <line x1="20" y1="15" x2="25" y2="15" />
                <line x1="15" y1="25" x2="15" y2="30" />
                </svg>`;
            }
            else if (d.class === 'part-refinement') {
                ghostEl.innerHTML = `<svg width="30" height="30">
                <polygon points="5,25 15,5 25,25" />
                <line x1="5" y1="15" x2="10" y2="15" />
                <line x1="20" y1="15" x2="25" y2="15" />
                <line x1="15" y1="25" x2="15" y2="30" />
                </svg>`;
            }
            else if (d.class === 'part-repeat') {
                ghostEl.innerHTML = `<svg width="30" height="30">
                <polygon points="5,25 15,5 25,25" />
                <line x1="5" y1="15" x2="10" y2="15" />
                <line x1="15" y1="25" x2="15" y2="30" />
                <text x="15" y="25" fontSize="36" textAnchor="right">*</text>
                </svg>`;
            }
            else if (d.class === 'activity trigger') {
                ghostEl.innerHTML = `<svg width="20" height="30">
                <path d="M0,0  l5,0 l3,3 l7,0 v-3 l5,5  l-5,5 v-3 l-7,0 l-3,3 l-5,0 l3,-5 Z" />
                <line x1="10" y1="-5" x2="10" y2="15" />
                </svg>`;
            }
            else if (d.class === 'activity ellipsis') {
                ghostEl.innerHTML = `<svg width="20" height="20">
                <path d="M10,0  v5 l5,3 l-10,3 l10,3 l-10,3 l5,3 v5" />
                </svg>`;
            }
            else {
                // nothing
            }

            document.body.appendChild(ghostEl);
            ghostRef.current = ghostEl;
        }
    }, [ghostRef]);

    const handleDrag = useCallback((event, d) => {
        if (!ghostRef.current) return;
        // Update the position of the ghost element
        ghostRef.current.style.visibility = 'visible';
        ghostRef.current.style.left = `${event.sourceEvent.pageX}px`;
        ghostRef.current.style.top = `${event.sourceEvent.pageY}px`;
    }, [ghostRef]);

    const handleDragEnd = useCallback((event, d) => {
        const dragEndPoint = { x: event.sourceEvent.pageX, y: event.sourceEvent.pageY };
        const distance = Math.sqrt(Math.pow(dragEndPoint.x - dragStartPoint.current.x, 2) + Math.pow(dragEndPoint.y - dragStartPoint.current.y, 2));

        if (ghostRef.current) {
            if (distance > 30) { // probably accidental drag if less than 30px
                document.body.style.cursor = 'default';
                const x = event.sourceEvent.pageX; //sourceEvent.pageX - event.sourceEvent.offsetX + d.x;
                const y = event.sourceEvent.pageY; //sourceEvent.pageY - event.sourceEvent.offsetY + d.y;
                setDraggedShape({ ...d, x, y });
                //console.log('drag end', d, x, y);
            }
            else {
                console.log('drag end - too short');
            }
            document.body.removeChild(ghostRef.current);
            ghostRef.current = null;
        }
        hideOverlay();
    }, [ghostRef, setDraggedShape]);

    const drag = d3.drag()
        .on("start", handleDragStart)
        .on("drag", handleDrag)
        .on("end", handleDragEnd);


    const addRoleGroup = useCallback((svg) => {
        drawRoleGroup(svg, roleAt, drag);
    }, [drag]);

    const addInteraction = useCallback((svg) => {
        drawInteractionGroup(svg, interactionAt, drag)
    }, [drag]);

    const addAction = useCallback((svg) => {
        const action = drawActionGroup(svg, actionAt)

        action.call(drag);

    }, [drag]);

    const addStartRole = useCallback((svg) => {
        const startRole = drawStartRoleGroup(svg, startRoleAt);
        startRole.call(drag);
    }, [drag]);

    const addCaseRefinement = useCallback((svg) => {
        const caseRefinement = drawCaseRefinementGroup(svg, caseRefinementAt);

        caseRefinement
            .call(drag);
    }, [drag]);

    const addPartRefinement = useCallback((svg) => {
        const partRefinement = drawPartRefinementGroup(svg, partRefinementAt);

        partRefinement.call(drag);

    }, [drag]);

    const addPartRepeat = useCallback((svg) => {
        drawPartRepeatGroup(svg, partRepeatAt, drag);
    }, [drag]);


    const addTrigger = useCallback((svg) => {
        const trigger = drawTriggerGroup(svg, triggerAt);
        trigger.call(drag);
    }, [drag]);

    const addEllipsis = useCallback((svg) => {
        const ellipsis = drawEllipsisGroup(svg, ellipsisAt);
        ellipsis.call(drag);
    }, [drag]);

    useEffect(() => {
        if (!shapesRef.current) return;
        // Set up the drawing area
        //console.log('Draw shapes');
        d3.select(shapesRef.current).selectAll("*").remove();

        const svgShapes = d3.select(shapesRef.current)
            .append('svg')
            .attr("width", shapeContainerSize.width)
            .attr("height", shapeContainerSize.height)

        addRoleGroup(svgShapes);
        addTrigger(svgShapes);
        addInteraction(svgShapes);
        addAction(svgShapes);
        addStartRole(svgShapes);
        addCaseRefinement(svgShapes);
        addPartRefinement(svgShapes);
        addPartRepeat(svgShapes);
        addEllipsis(svgShapes);
    }, [addRoleGroup, addStartRole, addInteraction, addAction,
        addCaseRefinement, addPartRefinement, addTrigger, addEllipsis, addPartRepeat]);

    return (
        <div>
            <div className='header'>
                <div className='centre-content'>
                    <figcaption >Shapes</figcaption>
                </div>
            </div>
            <div className='rad-shapes-container'
                ref={shapesRef}>
            </div>
        </div>);
};

export default React.memo(RADShapes);