/**
 * Custom hook that provides a function to print the content of a given component.
 *
 * @returns {Function} The printComponent function.
 */

export const usePrintComponent = () => {
    const printComponent = (ref) => {
        const printWindow = window.open('', 'PRINT', 'height=600,width=800');

        printWindow.document.write('<html><head><title>' + document.title + '</title>');
        printWindow.document.write('</head><body >');
        printWindow.document.write(ref.current.innerHTML);
        printWindow.document.write('</body></html>');

        printWindow.document.close();
        printWindow.focus();

        setTimeout(() => {
            printWindow.print();
            printWindow.close();
        }, 1000);
    };

    return printComponent;
};

export default usePrintComponent;