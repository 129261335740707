import React, { createContext, useContext, useRef, useState, useCallback, useEffect } from 'react';
import { ModelContext } from '../Model/ModelProvider';
import { useParams } from 'react-router-dom';
import toggleFullScreen from '../UI/ScreenMode';
import { useOutsideEvent } from '../hooks/useOutsideEvent';
import { nanoid } from 'nanoid';
import { useNavigate } from 'react-router-dom';

export const RADManagerContext = createContext();

const RADManagerLogic = ({ children }) => {
    const navigate = useNavigate();
    const { radId } = useParams();
    const { getRADById, updateModel, setIsChanged } = useContext(ModelContext);
    const [currentRAD, setCurrentRAD] = useState(null);

    useEffect(() => {
        if (radId) {
            setCurrentRAD(getRADById(radId));
        } else {
            setCurrentRAD(null);
        }
    }, [radId, getRADById]);

    const dropdownref = useRef(null);
    const [dropdown, setDropdown] = useOutsideEvent(dropdownref, false);
    const toggleDropdown = () => setDropdown(!dropdown);

    const [fullScreen, setFullScreen] = useState(false);
    const toggleScreenMode = (element) => {
        toggleFullScreen(element);
        setFullScreen(!fullScreen);
    };

    const setupRAD = ({ process_id, label }) => {
        console.log("RADCreator: addRAD");
        const newRAD = {
            id: nanoid(),
            process_id,
            label,
            status: 'initial',
            version: 0,
        };
        return newRAD; // This component does not render anything
    }

    const handleNewRAD = () => {
        const rad = setupRAD({ process_id: null, label: 'New RAD' });
        console.log('addRAD', rad);

        return newRAD(rad);
    }

    const newRAD = (rad) => {
        console.log("RADManager: newRAD", rad);
        // Update the rads array in the model with the new RAD
        updateModel(
            "ADD_RAD",
            rad,
            () => setIsChanged(true)
        ).then(() => {
            setCurrentRAD(rad);
            navigate(`/rad/${rad.id}`);  
        }
        ).catch((error) => {
            console.error('Error adding RAD:', error);
        });
        return null; // This component does not render anything
    }

    const handleUpdateRAD = useCallback((updatedRad) => {
        // Update the rads array in the model with the updated RAD
        updateModel(
            "UPDATE_RAD",
            updatedRad,
            () => setIsChanged(true)
        );
        return null;
    }, [updateModel, setIsChanged]);

    const handleDeleteRAD = useCallback((rad) => {
        updateModel("DELETE_RAD", rad, () => setIsChanged(true))
            .then(() => {
                setCurrentRAD(null);
                navigate('/rad');  
            })
            .catch((error) => {
                console.error('Error deleting RAD:', error);
            });
    }, [updateModel, setIsChanged, navigate]);

    const handleDeleteAllRADs = useCallback(() => {
        console.log("RADManager: deleteAllRADs");
        updateModel("DELETE_ALL_RADS", null, () => setIsChanged(true))
            .then(() => {
                setCurrentRAD(null);
                navigate('/rad');  
            })
            .catch((error) => {
                console.error('Error deleting all RADs:', error);
            });
        return null;
    }, [updateModel, setIsChanged, navigate]);

    return (
        <RADManagerContext.Provider value={{
            currentRAD, dropdownref, dropdown, setDropdown,
            toggleDropdown, fullScreen, toggleScreenMode,
            setupRAD, handleNewRAD, handleUpdateRAD, handleDeleteRAD,
            handleDeleteAllRADs
        }}>
            {children}
        </RADManagerContext.Provider>
    );
};

export default RADManagerLogic;
