import React, { useContext } from 'react';
import { RADManagerContext } from './RADManagerLogic';
import RADHandler from './Editor/RADHandler';
import RADSelector from './RADSelector';
import FullScreenButton from './FullScreenButton';
import { DropProvider } from './DropContext';
import RADShapes from './Editor/RADShapes';

const RADManagerUI = () => {
    const { currentRAD, fullScreen, toggleScreenMode, handleNewRAD, handleUpdateRAD,
    handleDeleteRAD, handleDeleteAllRADs } = useContext(RADManagerContext);
    return (
        <div id='rad-manager'>
            <div className="rad-bar rad-bar-fixed" >
                <ul className='button-container'>
                    <li>
                        <button className="csbutton"
                            type="button"
                            onClick={() => handleNewRAD()}
                        >
                            New
                        </button>
                    </li>
                    <li>
                        <RADSelector />
                    </li>
                    {/* if a rad is selected allow the user to delete it */}
                    {currentRAD &&
                        <li>
                            <button className="csbutton"
                                type="button"
                                onClick={() => handleDeleteRAD(currentRAD)}
                            >
                                Delete
                            </button>
                        </li>
                    }
                    <li>
                        <button className="csbutton"
                            type="button"
                            onClick={() => handleDeleteAllRADs()}
                        >
                            Delete All (Temporary)
                        </button>
                    </li>
                </ul>
                <div className="centre-content">
                    <div className="centre-text">
                        Role Activity Diagram: {currentRAD?.label}
                    </div>
                </div>
                <FullScreenButton fullScreen={fullScreen} toggleScreenMode={toggleScreenMode} element_id={'rad-manager'} />
            </div>
            <div id="rad-container" className='rad-container'>
                <div className='window'>
                    <div className='pane'>
                        <div className='flex-container'>
                            <DropProvider>
                                <RADShapes />
                                <RADHandler currentRAD={currentRAD} onRadChange={handleUpdateRAD} />
                            </DropProvider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RADManagerUI;
