import React, { useState, useContext, useRef } from 'react'
import PADEditorItem from './PADEditorItem';
import PADForm from './PADForm';
import { ModelContext } from '../../Model/ModelProvider'


const PAD1Editor = () => {
    const { getPAD1, updatePADEditableSchema } = useContext(ModelContext);
    const { editableSchema } = getPAD1();

    const [isCollapsed, setIsCollapsed] = useState(false)

    // A lot of this code should be higher up, probably in the reducer itsself.
    function editLabel({ id, label }) {
        // dispatch an update to model reducer
        const item = editableSchema.find(s => s.id === id)
        const newItem = { ...item, label: label }
        console.log('newItem: ', newItem);
        const newSchema = editableSchema.map(s => s.id === id ? newItem : s)
        updatePADEditableSchema('pad1', newSchema, true)
    }

    const renderedListItems = editableSchema
        .filter(s => s.class === 'node')
        .map((item) => {
            return <PADEditorItem key={item.id} process={item} />
        })

    const listRef = useRef(null)

    return (
        <>
            {editableSchema
                ? <div className='narrow-pane'>
                    <button className={isCollapsed ? 'collapsible closed' : 'collapsible open'} type='button'
                        onClick={() => setIsCollapsed(!isCollapsed)}>
                        PAD Label Editor
                    </button>
                    <div className='content' style={{ display: isCollapsed ? 'none' : 'block' }}>
                        <PADForm onSubmit={editLabel} />
                        <div className='pad-header' >
                            <h3> PAD List</h3>
                        </div>
                        <ul ref={listRef}
                            className="js-model-list js-pad-list"
                            position='relative'>
                            {renderedListItems}
                        </ul>
                        <footer className='footer'>
                            <div className="centre-content">
                                <div className="centre-text" 
                                style = {{fontStyle: 'italic', fontSize: 10}}>
                                    Select a process or relationship from the list or diagram to edit its label.
                                </div>
                            </div>
                        </footer>

                    </div>
                </div>
                : <p> Schema has not been generated.</p>}
        </>
    )
}

export default PAD1Editor