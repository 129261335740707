import React from 'react';

const SpringIcon = ({ width = 20, height = 20, className = '', style = {} }) => {
    return (
        <svg width={`${width}px`} height={`${height}px`} viewBox={`0 0 ${width * 3 / 2} ${height * 3 / 2}`}
            className={`ellipsis ${className}`} style={style}>
            <path d={`M10,0 v5 l5,3 l-10,3 l10,3 l-10,3 l5,3 v5`} />
        </svg>
    );
};

export default SpringIcon;
