import { useContext, useState, useEffect, useCallback } from 'react';
import { ModelContext } from '../Model/ModelProvider';

const UOWDiagramLogic = ({ children }) => {
    const { getUoWEBEs, getGenerators } = useContext(ModelContext);
    const [graph, setGraph] = useState('');

    const createMarkdown = useCallback((nodes, edges) => {
        const intro = `digraph G {
	        node [shape=hexagon, fontname="Arial, fontsize=14"];
            edge [labelfontname="Arial, labelfontsize=12, labeldistance=0.5"];
	        \n\n  `;

        if (!Array.isArray(nodes) || nodes.length === 0) return undefined

        const wnodes = nodes.map(({ id, name }) => `\t${id} [label="${name}"];\n`).join('');

        const externalNodes = edges
            .filter(edge => edge.source === "External")
            .map(edge => `\t${edge.source}_${edge.target} [shape = circle, color=none, label="☁", fontsize = 64];\n`);

        const allWnodes = wnodes + externalNodes.join('');

        const wedges = edges.map(edge => {
            if (edge.source === "External") {
                return `\t${edge.source}_${edge.target} -> ${edge.target} [label="${edge.description}", tailclip=false];\n`;
            } else {
                return `\t${edge.source} -> ${edge.target} [label="${edge.description}"];\n`;
            }
        }).join('');

        const outro = `\n}`;
        const graph = intro + allWnodes + wedges + outro;
        return graph;
    }, []);

    useEffect(() => {
        const newGraph = createMarkdown(getUoWEBEs(), getGenerators());
        if (newGraph !== graph) {
            console.log('useEffect: calling createMarkdown from UOWDiagram');
            setGraph(newGraph);
        }
    }, [getUoWEBEs, getGenerators, createMarkdown, graph])

    // Children is a function that receives the state and handlers
    return children({ graph });
};

export default UOWDiagramLogic;
