import React, { useContext, useEffect, useRef } from 'react'
import { ModelContext } from '../../Model/ModelProvider'
import { SecondCutContext } from './SecondCutLogic';


const PAD2ListItem = ({ process: { id, label, type } }) => {
    const { getPAD2 } = useContext(ModelContext)
    const { editableSchema } = getPAD2();
    const { graphId, setGraphId } = useContext(SecondCutContext)

    const renderedSublistItems = editableSchema
        .filter(s => s.class !== 'node' && s.source === id && s.status === 'live')
        .map(r => { return <PAD2SublistItem key={r.source + r.target + r.class} rid={r.source + '_' + r.target + '_' + r.class} {...r} /> })

    const selectedItemRef = useRef(null); // Ref for the selected item

    useEffect(() => {
        if (graphId === id && selectedItemRef.current) {
            selectedItemRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
    }, [graphId, id]);

    return (
        <div key={id}>
            <li key={'li' + id} id={id}
                onClick={() => { graphId === id ? setGraphId('') : setGraphId(id) }}
                className={graphId === id
                    ? 'checked'
                    : ''}
                ref={id === graphId ? selectedItemRef : null}
            >
                <strong> {label} </strong>
                <span>[{type}]</span>
            </li>
            <ul className="js-model-list"
                style={{
                    margin: '0px 0px 1px 0px'
                }}
                key={'ul_' + id}
                id={'ul_' + id}
            >
                {renderedSublistItems}</ul>
        </div>
    )
}

const PAD2SublistItem = ({ rid, target, label }) => {
    const { graphId, setGraphId } = useContext(SecondCutContext)
    const { getPAD2 } = useContext(ModelContext)
    const { editableSchema } = getPAD2();

    const process = editableSchema.find(s => s.class === 'node' && s.id === rid) ?? { label: '' }
    const { label: processLabel } = process

    const sublistRef = useRef(null)
    useEffect(() => {
        if (graphId === rid && sublistRef.current) {
            sublistRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' })
        }
    },
        [graphId, rid])

    return (
        <li ref={rid === graphId ? sublistRef : null} id={rid}
            onClick={() => { graphId === rid ? setGraphId('') : setGraphId(rid) }}
            className={graphId === rid
                ? 'checked'
                : ''}
            style={{
                paddingLeft: '100px'
            }}
        >
            <em >{label}</em>
            <strong> {processLabel} </strong>
        </li >
    )
}

export default PAD2ListItem