
import RADComponentEditor from './RADComponentEditor';
import RADDataEditor from './RADDataEditor';

const RADEditor = () => {
    return (
        <div>
            <RADDataEditor />
            <RADComponentEditor />
        </div>
    );
};

export default RADEditor;
