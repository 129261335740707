import React, { useContext } from 'react';
import { GeneratorContext } from './GeneratorLogic';
import GeneratorListItem from './GeneratorListItem';

const GeneratorList = () => {
    const { usedSources } = useContext(GeneratorContext);

    const renderedListItems = usedSources.map((gen) => {
        return <GeneratorListItem key={gen} genSource={gen} />;
    });

    return (
        <div>
            <div className="liheader">
                <h3>Generator List </h3>
            </div>
            <ul className="js-model-list">{renderedListItems}</ul>
        </div>
    );
};


export default GeneratorList;