import RADHandlerLogic from './RADHandlerLogic';
import RADHandlerUI from './RADHandlerUI';

const RADHandler = ({ currentRAD, onRadChange }) => {
    return (
        <RADHandlerLogic currentRAD={currentRAD} onRadChange={onRadChange}  >
            <RADHandlerUI />
        </RADHandlerLogic>
    );
};

export default RADHandler;