// fileUpload.js
const uploadJsonFile = (file, callback) => {
  if (file && file.type === "application/json") {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const content = JSON.parse(e.target.result);
        callback(null, content);
      } catch (error) {
        callback(error);
      }
    };
    reader.readAsText(file);
  } else {
    callback(new Error("Invalid file type"));
  }
};

export default uploadJsonFile;
