import React from 'react';
import '../../css/Tab.css';

const Tabs = ({ tabs, children, setActiveTab }) => {
    console.log("Tabs: tabs", tabs);
    if (!tabs || tabs.length === 0) {
        // Optionally, handle the case when there are no tabs
        return null;
    }

    const handleTabClick = (tabKey) => {
        if (setActiveTab) {
            setActiveTab(tabKey);
        }
    };

    return (
        <div>
            <div className="tab">
                {tabs.map(tab => (
                    <button
                        key={tab.key}
                        onClick={() => handleTabClick(tab.key)}
                        className={""}
                    >
                        {tab.label}
                    </button>
                ))}
            </div>
            <div className="tabcontent">
                {children}
            </div>
        </div>
    );
};


export default Tabs;