import React, { useContext, useRef } from "react";
import { BsHexagon, BsTrash } from "react-icons/bs";
import { EBEContext } from "./EBEController";

const EBEListItem = ({ ebe, isHighlighted }) => {
  const { selectedEBE, setSelectedEBE, removeEBE, setPartialName } = useContext(EBEContext);
  const selectedID = selectedEBE ? selectedEBE.id : null;
  const {id, name, isUOW } = ebe
  const testRef = useRef(null);
 
  const uowstar = isUOW ? (
    <span className="uow">
      <BsHexagon
        className="cs-icon"
        title="UoW"
        onClick={(e) => e.stopPropagation()}
      />
    </span>
  ) : null;

  const handleSelectEBE = (ebe) => {
    selectedID === ebe.id ? setSelectedEBE(null) : setSelectedEBE(ebe);
    setPartialName("");
  };

  return (
    <li
      onClick={() => handleSelectEBE(ebe)}
      ref={testRef}
      className={`${selectedID === id ? "checked" : ""} ${isHighlighted ? "maybe" : ""}`}
    >
      {name}
      <span className="remove" title="Delete this EBE!" onClick={() => removeEBE(ebe)}>
        <BsTrash
          className="cs-icon" 
        ></BsTrash>
      </span>
      {uowstar}
    </li>
  );
};

export default EBEListItem;
