// This component needs factoring out into a RAD context provider.

import { nanoid } from 'nanoid';

const initRAD = {
    id: "",
    process_id: "",
    name: "",
    viewpoint: "",
    description: "",
    roles: []
}

export const radCreator = (nodes, getRADs, updateModel, setIsChanged) => {
    console.log("RADCreator: schema", nodes);
    // Exclude any nodes that are already in the rads array
    const rads = getRADs();
    const existingRADs = rads.map(r => r.process_id);
    const newNodes = nodes.filter(n => !existingRADs.includes(n.id));
    // Get all RADs that are not in the nodes array and update the process_id to null

    const orphanedRADs = rads
        .filter(r => !nodes.map(n => n.id).includes(r.process_id))
        .map(r => ({ ...r, process_id: null }));

    // Update the rads array in the model with the new RADs
    // and the orphaned RADs with null process_id
    const newRADs = newNodes
        .map(n => (setupRAD(n)))
        .concat(orphanedRADs);

    // Update the rads array in the model with the new RADs
    updateModel(
        "UPDATE_MODEL_PART",
        { partKey: "rads", value: [...rads, ...newRADs] }, // Merge existing and new RADs
        () => setIsChanged(true)
    );
    return null; // This component does not render anything
};

export const setupRAD = ({process_id, label}) => {
    console.log("RADCreator: addRAD");
    const rad = {
        id: nanoid(),
        process_id,
        label,
        status: 'initial'
    };
    const newRAD = { ...initRAD, ...rad };
    return newRAD; // This component does not render anything
}
