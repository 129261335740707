import React from 'react';

const ConnectionIcon = ({ width = 20, height = 20, className = '', style = {} }) => {
    return (
        <svg width={`${width}px`} height={`${height}px`} viewBox={`0 0 ${width * 3 / 2} ${height * 3 / 2}`}
            className={`connection ${className}`} style={style}>

            <line x1={0 } y1={height *2/3 } x2={width * 2 } y2={height *2/3} />
        </svg>
    );
};

export default ConnectionIcon;