import { shapes } from './Shapes';
import * as d3 from 'd3';
import { orderActivitiesWithPoints } from '../RADHandlerLogic';

const baseWidth = 20; // Default width
export const drawLabel = (container, data) => {
    // Render labels
    const bindingData = data ? [data] : container.datum() ? [container.datum()] : [];
    const labels = container.selectAll('text.label').data(bindingData);

    // Enter + update pattern for 'text'
    labels.enter().append('text').classed('label', true)
        .merge(labels)
        .attr('x', 35) // Position to the right of the shape
        .attr('y', d => d.height / 2) // Vertically center
        .attr('dy', '0.35em') // Adjustment for vertical centering
        .attr('font-size', '12px')
        .text(d => d.label || "")

    // Remove any excess rects.
    labels.exit().remove();
};

// Utility to draw roles, abstracting away the enter-update-exit pattern
export const drawRoleGroups = (container, rolesData, at, expanses = null, onDrag, onClick) => {
    const data = rolesData.map(role => {
        if (!expanses) return { ...role, x: 0, y: 0 };
        const expanse = expanses.roles.find(e => e.role_id === role.id);
        //console.log('drawRoleGroup', role, expanse);
        return { ...role, ...expanse };
    });
    //console.log('drawRoleGroups', data);
    // Binding data to role groups, using a class to differentiate
    const roleGroups = container.selectAll("g.role-group")
        .data(data, d => d.id);

    // Enter new role groups
    const roleGroupsEnter = roleGroups.enter().append("g")
        .attr("class", "role-group")
        .attr('data-id', d => d.id)
        .attr("cursor", "grab")
        .call(onDrag) // Attach drag behavior

    // Append rectangles for new roles
    roleGroupsEnter.append('rect')
        .attr("class", "role")
        .attr("data-id", d => d.id)
        .attr("rx", d => d.width / 10 || 0)
        .attr("ry", d => d.height / 10 || 0)
        .on("click", onClick); // Attach click behavior


    // Update group transform including for entered groups
    roleGroupsEnter.merge(roleGroups)
        .attr('transform', d => `translate(${(d.x || 0) + at.x}, ${(d.y || 0) + at.y})`)

    // Add/Update labels for both new and existing groups
    roleGroupsEnter.merge(roleGroups).selectAll('text.role-name')
        .data(d => [d]) // Important: Wrap data in an array to ensure one text per group
        .join('text')
        .attr("class", "role-name")
        .attr('x', d => d.width / 2) // Center label within the role rectangle
        .attr('y', -18) // Position above the rectangle
        .attr('text-anchor', 'middle')
        .attr('font-size', '18px')
        .attr('font-weight', 'bold')
        .text(d => d.label || "new role");

    roleGroupsEnter.merge(roleGroups).selectAll('text.role-tag-tick')
        .data(d => d.preexists ? [d] : []) // Create a tick element only if preexists is true
        .join('text')
        .attr("class", "role-tag-tick")
        .attr('x', d => d.width - 20)
        .attr('y', -2)
        .text('✓')
        .attr('font-weight', 'bold');

    roleGroupsEnter.merge(roleGroups).selectAll('text.role-tag-multiple')
        .data(d => d.multiple ? [d] : []) // Create an 'n' element only if multiple is true
        .join('text')
        .attr("class", "role-tag-multiple")
        .attr('x', d => d.width - 10) // Further to the right; adjust as needed
        .attr('y', 0)
        .text('n');


    // Append/update rectangle sizes last to ensure data is not re-bound incorrectly
    roleGroupsEnter.merge(roleGroups).select('rect.role')
        .attr("width", d => d.width)
        .attr("height", d => d.height);

    // Exit and remove old roles
    roleGroups.exit().remove();
};

export const drawThreadGroups = (container, threadsData, at, expanses, onDrag, onClick) => {
    const data = threadsData.map(thread => {
        if (!expanses || expanses.length === 0) return { ...thread, x: 0, y: 0 };
        //console.log('drawThreadGroups', thread, expanses)
        const expanse = expanses.activityThreads.find(e => e.thread_id === thread.id);
        //console.log('drawThreadGroup', thread, expanse);
        return { ...thread, ...expanse };
    });
    const threadGroups = container.selectAll("g.thread-group")
        .data(data, d => d.id);

    const threadGroupsEnter = threadGroups.enter().append("g")
        .data(data, d => d.id)
        .attr("class", "thread-group")
        .attr('data-id', d => d.id)
        .attr("cursor", "grab")
        .call(onDrag);

    threadGroupsEnter.append('rect')
        .attr("class", "thread")
        .attr("data-id", d => d.id)
        .attr("rx", 5)
        .attr("ry", 5)
        .on("click", onClick);

    threadGroupsEnter.merge(threadGroups)
        .attr('transform', d => `translate(${(d.x || 0) + at.x}, ${(d.y || 0) + at.y})`);

    threadGroupsEnter.merge(threadGroups).select('rect')
        .attr("width", d => d.width)
        .attr("height", d => d.height)

    threadGroups.exit().remove();
}

const calcOffsetY = (expanse, expanseArray) => {
    // iterate through expanses in index order up to the current expanseindex
    // summing up the y values
    // of the threads that are part of the activity.
    if (!expanse) return { x: 0, y: 0 };
    const yPos = expanseArray
        .filter(e => e.thread_id === expanse.thread_id && e.index <= expanse.index)
        .reduce((acc, curr) => acc + curr.y, 0);
    return { ...expanse, y: yPos };
}

export const drawActivityGroups = (container, activitiesData, at, expanses, onDrag, onClick) => {
    //console.log('drawActivityGroups expanses', expanses);
    const data = activitiesData.map(activity => {
        if (!expanses || expanses.length === 0) return { ...activity, x: 0, y: 0 };
        const expanse = expanses.activities.find(e => e.activity_id === activity.id);
        //calculate the y position of the activity
        //console.log('drawActivity', expanse, expanses.activities);
        const expanseWithOffset = calcOffsetY(expanse, expanses.activities);
        const d = { ...activity, ...expanseWithOffset };
        return d;
    });

    const activityGroups = container.selectAll("g.activity")
        .data(data, d => d.id)
        .join(
            enter => enter.append("g")
                .attr("class", d => d.class)
                .attr('data-id', d => d.id)
                .attr("cursor", "grab")
                .on("click", onClick),
            update => update,
            exit => exit.remove()
        );

    activityGroups.each(function (d) {
        // Convert D3 selection to standard array and iterate
        const drawFunction = drawingFunctionMap[d.class];
        if (drawFunction) {
            // Use call to apply drawing function to the current element
            const shapeContainer = d3.select(this);
            drawFunction(shapeContainer, d);
        }
    });

    activityGroups.attr('transform', d => `translate(${(d.x || 0) + at.x}, ${(d.y || 0) + at.y})`);

    activityGroups.exit().remove();

}

export const drawCaseRefinementGroups = (container, caseRefinementsData, at, expanses, onDrag, onClick) => {
    const data = caseRefinementsData.map(caseRefinement => {
        if (!expanses || expanses.length === 0)
            return { ...caseRefinement, x: 0, y: 0 };
        const expanse = expanses.activities.find(e => e.activity_id === caseRefinement.id);
        const expanseWithOffset = calcOffsetY(expanse, expanses.activities);
        const d = { ...caseRefinement, ...expanseWithOffset };
        return d;
    });

    const caseRefinementGroups = container.selectAll("g.case-refinement-group")
        .data(data, d => d.id)
        .join(
            enter => enter.append("g")
                .attr("class", "case-refinement-group")
                .attr('data-id', d => d.id)
                .attr("cursor", "grab")
                .on("click", onClick),
            update => update,
            exit => exit.remove()
        );

    caseRefinementGroups.each(function (d) {
        // Convert D3 selection to standard array and iterate
        const drawFunction = drawingFunctionMap[d.class];
        if (drawFunction) {
            // Use call to apply drawing function to the current element
            const shapeContainer = d3.select(this);
            drawFunction(shapeContainer, d, onClick);
        }
    });

    caseRefinementGroups.attr('transform', d => `translate(${(d.x || 0) + at.x}, ${(d.y || 0) + at.y})`);
    caseRefinementGroups.exit().remove();
}

export const drawPartRefinementGroups = (container, partRefinementsData, at, expanses, onDrag, onClick) => {
    const data = partRefinementsData.map(partRefinement => {
        if (!expanses || expanses.length === 0) return { ...partRefinement, x: 0, y: 0 };
        const expanse = expanses.activities.find(e => e.activity_id === partRefinement.id);
        const expanseWithOffset = calcOffsetY(expanse, expanses.activities);
        const d = { ...partRefinement, ...expanseWithOffset };
        return d;
    });

    const partRefinementGroups = container.selectAll("g.part-refinement-group")
        .data(data, d => d.id)
        .join(
            enter => enter.append("g")
                .attr("class", "part-refinement-group")
                .attr('data-id', d => d.id)
                .attr("cursor", "grab")
                .on("click", onClick),
            update => update,
            exit => exit.remove()
        );

    partRefinementGroups.each(function (d) {
        // Convert D3 selection to standard array and iterate
        const drawFunction = drawingFunctionMap[d.class];
        if (drawFunction) {
            // Use call to apply drawing function to the current element
            const shapeContainer = d3.select(this);
            drawFunction(shapeContainer, d, onClick);
        }
    });

    partRefinementGroups.attr('transform', d => `translate(${(d.x || 0) + at.x}, ${(d.y || 0) + at.y})`);
    partRefinementGroups.exit().remove();
}

export const drawPartRepeatGroups = (container, partRepeatsData, at, expanses, onDrag, onClick) => {
    const data = partRepeatsData.map(partRepeat => {
        if (!expanses || expanses.length === 0) return { ...partRepeat, x: 0, y: 0 };
        const expanse = expanses.activities.find(e => e.activity_id === partRepeat.id);
        const expanseWithOffset = calcOffsetY(expanse, expanses.activities);
        const d = { ...partRepeat, ...expanseWithOffset };
        return d;
    });

    const partRepeatGroups = container.selectAll("g.part-repeat-group")
        .data(data, d => d.id)
        .join(
            enter => enter.append("g")
                .attr("class", "part-repeat-group")
                .attr('data-id', d => d.id)
                .attr('data-class', d => d.class)
                .attr("cursor", "grab")
                .on("click", onClick),
            update => update,
            exit => exit.remove()
        );

    partRepeatGroups.each(function (d) {
        // Convert D3 selection to standard array and iterate
        const drawFunction = drawingFunctionMap[d.class];
        if (drawFunction) {
            // Use call to apply drawing function to the current element
            const shapeContainer = d3.select(this);
            //console.log('drawPartRepeatGroups shapeContainer', shapeContainer);
            drawFunction(shapeContainer, d, onClick);
        }
    });

    partRepeatGroups.attr('transform', d => `translate(${(d.x || 0) + at.x}, ${(d.y || 0) + at.y})`);
    partRepeatGroups.exit().remove();
}


export const drawStartRole = (container, data) => {
    drawStartRoleRect(container, data);
}

export const drawStartRoleRect = (container, data) => {
    //console.log('drawStartRoleRect', data);
    const bindingData = data ? [data] : container.datum() ? [container.datum()] : [];
    drawRect(container, data);

    const diag1 = container.selectAll('line.diag1').data(bindingData);
    diag1.enter().append('line').classed('diag1', true).merge(diag1)
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', d => d.width)
        .attr('y2', d => d.height);

    diag1.exit().remove();

    const diag2 = container.selectAll('line.diag2').data(bindingData);
    diag2.enter().append('line').classed('diag2', true).merge(diag2)
        .attr('x1', d => d.width)
        .attr('y1', 0)
        .attr('x2', 0)
        .attr('y2', d => d.height);

    diag2.exit().remove();
    drawLabel(container, data);

};

export const drawTrigger = (container, data) => {
    drawTriggerShape(container, data);
}

export const drawTriggerShape = (container, data) => {
    const bindingData = data ? [data] : container.datum() ? [container.datum()] : [];
    const tline = container.selectAll('line.trig').data(bindingData);
    tline.enter().append('line').classed('trig', true).merge(tline)
        .attr('x1', 10)
        .attr('y1', -5)
        .attr('x2', 10)
        .attr('y2', d => d.height + 5);
    tline.exit().remove()

    const tpath = container.selectAll('path.trig').data(bindingData);
    tpath.enter().append('path').classed('trig', true).merge(tpath)
        .attr('d', `M0,0  l5,0 l3,3 l7,0 v-3 l5,5  l-5,5 v-3 l-7,0 l-3,3 l-5,0 l3,-5 Z`);
    tpath.exit().remove();

    drawLabel(container, data);
};

export const drawEllipsis = (container, data) => {
    drawEllipsisShape(container, data);
}

export const drawEllipsisShape = (container, data) => {
    const bindingData = data ? [data] : container.datum() ? [container.datum()] : [];

    const epath = container.selectAll('path.ellipsis').data(bindingData);
    epath.enter().append('path').classed('ellipsis', true).merge(epath)
        .attr('d', `M10,0  v5 l5,3 l-10,3 l10,3 l-10,3 l5,3 v5`);
    epath.exit().remove();

    drawLabel(container, data);

};

export const drawInteraction = (container, data) => {
    const bindingData = data ? [data] : container.datum() ? [container.datum()] : [];
    drawRect(container, data);
    if (data && data.initiator) {
        // Draw hatching
        const hatch1 = container.selectAll('line.hatch1').data(bindingData);
        hatch1.enter().append('line').classed('hatch1', true).merge(hatch1)
            .attr('x1', 0)
            .attr('y1', d => d.height)
            .attr('x2', d => d.width)
            .attr('y2', 0);

        hatch1.exit().remove();

        const hatch2 = container.selectAll('line.hatch2').data(bindingData);
        hatch2.enter().append('line').classed('hatch2', true).merge(hatch2)
            .attr('x1', 0)
            .attr('y1', d => d.height / 2)
            .attr('x2', d => d.width / 2)
            .attr('y2', 0);

        hatch2.exit().remove();

        const hatch3 = container.selectAll('line.hatch3').data(bindingData);
        hatch3.enter().append('line').classed('hatch3', true).merge(hatch3)
            .attr('x1', d => d.width / 2)
            .attr('y1', d => d.height)
            .attr('x2', d => d.width)
            .attr('y2', d => d.height / 2);

        hatch3.exit().remove();
    }
    else if (data && !data.initiator) {
        // Remove hatching if it exists
        container.selectAll('line.hatch1').remove();
        container.selectAll('line.hatch2').remove();
        container.selectAll('line.hatch3').remove();
    }
}
export const drawAction = (container, data) => {
    drawRect(container, data);
}

const drawRect = (container, data) => {
    // Select all 'rect' elements within the group and bind the data for the rectangles.
    // If 'group' already has data bound to it, this data will be used for the rectangles.
    const bindingData = data ? [data] : container.datum() ? [container.datum()] : [];

    // Bind the data to the rect elements
    const rects = container.selectAll('rect')
        .data(bindingData);

    // Enter + update pattern for 'rect'
    rects.enter().append('rect').merge(rects)
        .attr("width", d => d.width)
        .attr("height", d => d.height)
        .attr('x', 0)
        .attr('y', 0)

    // Remove any excess rects.
    rects.exit().remove();

    // Similar pattern for 'line' and 'text' elements.
    const lines1 = container.selectAll('line.line1').data(bindingData);
    lines1.enter().append('line').classed('line1', true).merge(lines1)
        .attr('x1', d => d.width / 2)
        .attr('y1', -5)
        .attr('x2', d => d.width / 2)
        .attr('y2', 0);
    lines1.exit().remove();

    const lines2 = container.selectAll('line.line2').data(bindingData);
    lines2.enter().append('line').classed('line2', true).merge(lines2)
        .attr('x1', d => d.width / 2)
        .attr('y1', d => d.height)
        .attr('x2', d => d.width / 2)
        .attr('y2', d => d.height + 5);
    lines2.exit().remove();

    drawLabel(container, data);
}

export const drawStateLabel = (container, data) => {
    drawStateLabelShape(container, data);
}

const drawStateLabelShape = (container, data) => {
    const bindingData = data ? [data] : container.datum() ? [container.datum()] : [];

    const sell = container.selectAll('ellipse.state-label').data(bindingData);
    sell.enter().append('ellipse').classed('state-label', true).merge(sell)
        .attr('cx', d => d.width / 2)
        .attr('cy', d => d.height / 2)
        .attr('rx', d => d.rx || 8)
        .attr('ry', d => d.ry || 4);
    sell.exit().remove()

    drawLabel(container, data);
}

export const drawPartRefinement = (container, data, onClick) => {
    const bindingData = data ? [data] : container.datum() ? [container.datum()] : [];

    const path = container.selectAll('path.rpath').data(bindingData);
    path.enter().append('path').classed('rpath', true).merge(path)
        .attr('d', d => {
            // Starting point
            const startX = baseWidth / 2;
            const startY = -d.height / 2;

            // Vertical line down to start curve
            const verticalEndY = 0;

            // Control points for cubic bezier curve to create the curve to the right
            // Adjust these points to control the shape of the curve
            const cp1X = startX; // First control point - same x as starting point to go down vertically
            const cp1Y = verticalEndY + 2; // Move the first control point down a bit to start the curve
            const cp2X = startX + 5; // Second control point - move to the right to create the horizontal end
            const cp2Y = verticalEndY + 5; // Adjust the y to control the curve's height
            const endX = startX + 34; // End point x - to the right of the start
            const endY = verticalEndY + 5; // End point y - same y as vertical end to finish horizontally

            // Construct the path string
            return `M${startX},${startY} V${verticalEndY} C${cp1X},${cp1Y} ${cp2X},${cp2Y} ${endX},${endY}`;
        })
    path.exit().remove();

    drawPartThreads(container, data, onClick);
}

const drawPartThreads = (container, data, onClick) => {
    if (!data.partThreads) return;
    const parts = data.partThreads
        .sort((a, b) => a.index - b.index)
        .map((part) => {
            const width = baseWidth;
            const height = data.height || 20;
            return { ...part, width, height };
        });
    //console.log('drawPartThreads', parts);

    const partGroups = container.selectAll('g.part-group').data(parts, d => d.id);
    partGroups.enter()
        .append('g')
        .classed('part-group', true)
        .attr('data-id', d => d.id)
        .attr('cursor', 'grab')
        .on('click', onClick)
        .merge(partGroups)
        .attr('transform', (d, i) => `translate(${i * (d.width + 10) + 30} ,-5 )`)
        .each(function (d) {
            const partContainer = d3.select(this);
            //console.log('drawParts part', d);
            drawPartRefinementShape(partContainer, d);
        });

    partGroups.exit().remove();

}

export const drawPartRepeat = (container, data, onClick) => {
    const bindingData = data ? [data] : container.datum() ? [container.datum()] : [];

    const path = container.selectAll('path.rpath').data(bindingData);
    path.enter().append('path').classed('rpath', true).merge(path)
        .attr('d', d => {
            // Starting point
            const startX = baseWidth / 2;
            const startY = -d.height / 2;

            // Vertical line down to start curve
            const verticalEndY = 0;

            // Control points for cubic bezier curve to create the curve to the right
            // Adjust these points to control the shape of the curve
            const cp1X = startX; // First control point - same x as starting point to go down vertically
            const cp1Y = verticalEndY + 2; // Move the first control point down a bit to start the curve
            const cp2X = startX + 5; // Second control point - move to the right to create the horizontal end
            const cp2Y = verticalEndY + 5; // Adjust the y to control the curve's height
            const endX = startX + 34; // End point x - to the right of the start
            const endY = verticalEndY + 5; // End point y - same y as vertical end to finish horizontally

            // Construct the path string
            return `M${startX},${startY} V${verticalEndY} C${cp1X},${cp1Y} ${cp2X},${cp2Y} ${endX},${endY}`;
        })
    path.exit().remove();

    drawPartRepeatThread(container, data, onClick);
    drawPartRepeatReturnState(container, data);
}


const drawPartRepeatReturnState = (container, data) => {
    const bindingData = data ? [data] : container.datum() ? [container.datum()] : [];
    const path = container.selectAll('path.retpath').data(bindingData);
    path.enter()
        .append('path')
        .classed('retpath', true)
        .merge(path)
        .attr('data-class', 'part-repeat-return')
        .attr('d', d => {
            // Starting point
            const startX = 30 + baseWidth / 2;
            const startY = d.height;

            // Vertical line down to start curve
            const verticalEndY = d.height + 10;

            // Control points for cubic bezier curve to create the curve to the right
            // Adjust these points to control the shape of the curve
            const cp1X = startX; // First control point - same x as starting point to across horizontally
            const cp1Y = verticalEndY; // Move the first control point down a bit to start the curve
            const cp2X = startX - 30; // Second control point - move to the right to create the horizontal end
            const cp2Y = verticalEndY; // Adjust the y to control the curve's height
            const endX = startX - 30; // End point x - to the right of the start
            const endY = verticalEndY + 10; // End point y - same y as vertical end to finish horizontally

            // Construct the path string
            return `M${startX},${startY} V${verticalEndY} C${cp1X},${cp1Y} ${cp2X},${cp2Y} ${endX},${endY}`;
        })
    path.exit().remove();

    //console.log('drawPartRepeatReturnState', container, data);
}

const drawPartRepeatThread = (container, data, onClick) => {
    if (!data.partThread) return;
    //console.log('drawPartRepeatThread', data.partThread);
    // As a part repeat thread is one standard width , replace the width in the data with this.
    // Bit of a hack.
    const bindingData = { ...data.partThread, width: baseWidth, height: data.height || 20 };
    const partGroup = container.selectAll('g.part-repeat-thread').data([bindingData], d => d.id);

    // Enter selection
    const partGroupEnter = partGroup.enter()
        .append('g')
        .classed('part-repeat-thread', true)
        .attr('data-id', d => d.id)
        .attr('data-class', 'part-repeat')
        .attr('cursor', 'grab')
        .on('click', onClick);

    // Update selection - merge enter and update selections for further operations
    const partGroupUpdate = partGroupEnter.merge(partGroup)
        .attr('transform', `translate(30, -5)`);

    // Exit selection - remove elements that are no longer in the data
    partGroup.exit().remove();

    // Call functions with the update selection, which includes the entered elements
    drawPartRefinementShape(partGroupUpdate, bindingData);
    drawAsterisk(partGroupUpdate);

    // Handling the labels
    const repeatLabel = partGroupUpdate.selectAll('text.label').data(d => [d]);
    repeatLabel.enter()
        .append('text')
        .classed('label', true)
        .merge(repeatLabel)
        .attr('x', d => d.width / 2)
        .attr('y', -5)
        .attr('dy', '0.35em')
        .attr('text-anchor', 'middle')
        .text(d => d.label || "repeat");
}

const drawPartRefinementShape = (container, data) => {
    const bindingData = data ? [data] : container.datum() ? [container.datum()] : [];
    //console.log('drawPartRefinementShape', data);
    const ppoly = container.selectAll('polygon.ppoly').data(bindingData);
    ppoly.enter().append('polygon').classed('ppoly', true).merge(ppoly)
        .attr('points', d => `4,${d.height - 4} ${d.width / 2},4 ${d.width - 4}, ${d.height - 4}`);
    ppoly.exit().remove()

    const pline1 = container.selectAll('line.pline1').data(bindingData);
    pline1.enter().append('line').classed('pline1', true).merge(pline1)
        .attr('x1', d => (!d.index || d.index === 0) ? 0 : -15)
        .attr('y1', d => d.height / 2)
        .attr('x2', d => (!d.index || d.index === 0) ? 0 : 7)
        .attr('y2', d => d.height / 2);
    pline1.exit().remove()

    const pline3 = container.selectAll('line.pline3').data(d => [d]);
    pline3.enter().append('line').classed('pline3', true).merge(pline3)
        .attr('x1', d => d.width / 2)
        .attr('y1', d => d.height - 4)
        .attr('x2', d => d.width / 2)
        .attr('y2', d => d.height + 5);
    pline3.exit().remove()

}

const drawCaseRefinement = (container, data, onClick) => {
    // draw a curved path from the top to the bottom of the case refinement
    const bindingData = data ? [data] : container.datum() ? [container.datum()] : [];

    const path = container.selectAll('path.rpath').data(bindingData);
    path.enter().append('path').classed('rpath', true).merge(path)
        .attr('d', d => {
            // Starting point
            const startX = baseWidth / 2;  // This needs to be abstracted
            const startY = -d.height / 2;

            // Vertical line down to start curve
            const verticalEndY = 0;

            // Control points for cubic bezier curve to create the curve to the right
            // Adjust these points to control the shape of the curve
            const cp1X = startX; // First control point - same x as starting point to go down vertically
            const cp1Y = verticalEndY + 2; // Move the first control point down a bit to start the curve
            const cp2X = startX + 5; // Second control point - move to the right to create the horizontal end
            const cp2Y = verticalEndY + 5; // Adjust the y to control the curve's height
            const endX = startX + 34; // End point x - to the right of the start
            const endY = verticalEndY + 5; // End point y - same y as vertical end to finish horizontally

            // Construct the path string
            return `M${startX},${startY} V${verticalEndY} C${cp1X},${cp1Y} ${cp2X},${cp2Y} ${endX},${endY}`;
        })
    path.exit().remove();


    const caseLabel = container.selectAll('text.label').data(d => [d]);
    caseLabel.enter().append('text').classed('label', true).merge(caseLabel)
        .attr('x', 0)
        .attr('y', 0)
        .attr('dy', '0.35em')
        .attr('text-anchor', 'end')
        .text(d => d.label || "case");

    drawCaseConditions(container, data, onClick);

}

const drawCaseConditions = (container, data, onClick) => {
    if (!data.conditions) return;
    const conditions = data.conditions
        .sort((a, b) => a.index - b.index)
        .map((condition) => {
            const width = baseWidth;
            const height = data.height || 20;
            return { ...condition, width, height };
        });


    const conditionGroups = container.selectAll('g.condition-group').data(conditions, d => d.id);
    conditionGroups.enter()
        .append('g')
        .classed('condition-group', true)
        .attr('data-id', d => d.id)
        .attr("cursor", "grab")
        .on("click", onClick)
        .merge(conditionGroups)
        .attr('transform', (d, i) => `translate(${i * (d.width + 10) + 30},-5 )`)
        .each(function (d) {
            const conditionContainer = d3.select(this);
            //console.log('drawCaseConditions condition', d);
            drawCaseRefinementShape(conditionContainer, d);
            const conditionLabel = conditionContainer.selectAll('text.condition-label').data(d => [d]);
            conditionLabel.enter().append('text').classed('condition-label', true).merge(conditionLabel)
                .attr('x', -4)
                .attr('y', -2)
                .attr('dy', '0.35em')
                .text(d => d.label || "c?");
            conditionLabel.exit().remove();
        });

    conditionGroups.exit().remove();
}

export const drawCaseRefinementShape = (container, data) => {
    //console.log('drawCaseRefinementShape', data);
    const bindingData = data ? [data] : container.datum() ? [container.datum()] : [];
    const cpoly = container.selectAll('polygon.cpoly').data(bindingData);
    cpoly.enter().append('polygon').classed('cpoly', true).merge(cpoly)
        .attr('points', d => `${d.width / 2},${d.height - 4} ${d.width - 4},4 4,4`)
    cpoly.exit().remove()

    const cline1 = container.selectAll('line.cline1').data(bindingData);
    cline1.enter().append('line').classed('cline1', true).merge(cline1)
        .attr('x1', d => (!d.index || d.index === 0) ? 0 : -15)
        .attr('y1', d => d.height / 2)
        .attr('x2', d => (!d.index || d.index === 0) ? 0 : 7)
        .attr('y2', d => d.height / 2);
    cline1.exit().remove()

    const cline3 = container.selectAll('line.cline3').data(bindingData);
    cline3.enter().append('line').classed('cline3', true).merge(cline3)
        .attr('x1', d => d.width / 2)
        .attr('y1', d => d.height - 4)
        .attr('x2', d => d.width / 2)
        .attr('y2', d => d.height + 2);
    cline3.exit().remove()
}

const drawingFunctionMap = {
    'activity start-role': drawStartRole,
    'activity action': drawAction,
    'activity interaction': drawInteraction,
    'activity trigger': drawTrigger,
    'activity ellipsis': drawEllipsis,
    'part-refinement': drawPartRefinement,
    'case-refinement': drawCaseRefinement,
    'part-repeat': drawPartRepeat,
}

// 
export const drawInteractionLines = (container, connections, expanses, onClick) => {
    if (!expanses) return;
    let linesLayer = container.select('.lines-layer');
    if (linesLayer.empty()) {
        linesLayer = container.append('g').classed('lines-layer', true);
    }

    // Go from leftmost x to rightmost x, iterate interactions array
    // in sequenced pairs. For each pair, draw a line from the source
    // to the target.
    //.log('drawInteractionLines connections', expanses.connections);
    const data = expanses.connections.flatMap(connection => {
        // Sort the interactions based on the x-position.
        const sortedInteractions = connection.interactions.sort((a, b) => a.centreX - b.centreX);

        // Map the sorted interactions to pairs of coordinates.
        return sortedInteractions.slice(0, -1).map((interaction, index) => {
            // Get the next interaction to form the coordinate pair.
            const nextInteraction = sortedInteractions[index + 1];
            // Return the coordinate pair: current x and y, next x and y.
            return {
                class: 'connection',
                id: `${interaction.interaction_id}-${nextInteraction.interaction_id}`,
                connection_id: connection.connection_id,
                from: interaction.interaction_id,
                to: nextInteraction.interaction_id,
                x1: interaction.centreX,
                y1: connection.centreY,
                x2: nextInteraction.centreX,
                y2: connection.centreY
            };
        });
    });

    linesLayer.selectAll('line.connection')
        .data(data, d => d.id)
        .join(
            enter => enter.append('line')
                .classed('connection', true)
                .attr('data-id', d => d.id)
                .attr('data-connection-id', d => d.connection_id)
                .attr('data-from', d => d.from)
                .attr('data-to', d => d.to)
                .on('click', onClick),
            update => update,
            exit => exit.remove()
        )
        .attr('x1', d => d.x1 > d.x2 ? d.x1 - 10 : d.x1 + 10)
        .attr('y1', d => d.y1)
        .attr('x2', d => d.x1 > d.x2 ? d.x2 + 10 : d.x2 - 10)
        .attr('y2', d => d.y2);

    // Fat line to make it easier to select
    // Just more painful than it's worth trying to get this to work.
    /* linesLayer.selectAll('line.section')
        .data(data, d => d.id)
        .join(
            enter => enter.append('line')
                .classed('section', true)
                .attr('id', d => d.from)
                .attr('data-id', d => d.id)
                .attr('stroke', 'transparent')
                .attr('stroke-width', 10) 
                .attr('cursor', 'pointer'),
            update => update,
            exit => exit.remove()
        )
        .attr('x1', d => d.x1 > d.x2 ? d.x1 - 10 : d.x1 + 10)
        .attr('y1', d => d.y1)
        .attr('x2', d => d.x1 > d.x2 ? d.x2 + 10 : d.x2 - 10)
        .attr('y2', d => d.y2);

    linesLayer.selectAll('line.section').on('click', onClick); */

    container.append(() => linesLayer.node());
};

// Draw state lines. RAD knowledge should be moved out of here.
export const drawStateLines = (container, prestate, activities, states, expanses, onClick, onDrag) => {
    if (!expanses) return;
    if (!prestate) return; // error really

    const data = activities.map(activity => {
        const expanse = expanses.activities.find(e => e.activity_id === activity.id);
        const expanseWithOffset = calcOffsetY(expanse, expanses.activities);
        const d = { ...activity, ...expanseWithOffset };
        //console.log('drawStateLine data', d);

        return d;
    });
    const activitiesData = orderActivitiesWithPoints(data, prestate);
    // Filter out activities without end points and add class
    const validActivities = activitiesData.filter(a => a.endPoints[0] && a.endPoints[1])
        .map(a => ({ ...a, class: 'state' }));

    const stateLines = container.selectAll("g.state-group")
        .data(validActivities, d => d.id)
        .join(
            enter => enter.append("g")
                .attr("class", 'state-group')
                .attr('data-id', d => d.id)
                .attr("cursor", "grab")
                .on("click", onClick)
                .call(onDrag),
            update => update,
            exit => exit.remove()
        );

    stateLines.each(function (d) {
        // Convert D3 selection to standard array and iterate
        const stateContainer = d3.select(this);
        drawStateLine(stateContainer, d);
        const state = states.find(s => s.id === d.id);
        const newdata = { ...d, label: state?.label, show: state?.show };
        drawStateDescription(stateContainer, newdata);
    });

    stateLines.attr('transform', d => `translate(${(d.x || 0)}, ${(d.y || 0)})`);

    stateLines.exit().remove();
};

export const drawStateLine = (container, data) => {
    //console.log('drawStateLine', data);

    // Select the line elements and bind the data
    const stateline = container.selectAll('line.state').data([data]);

    // Enter selection: Create new line elements for new data points
    stateline.enter()
        .append('line')
        .classed('state', true)
        .merge(stateline) // Merge selection: Update existing elements with new data points
        .attr('x1', d => d.endPoints[0][0])
        .attr('y1', d => d.endPoints[0][1])
        .attr('x2', d => d.endPoints[1][0])
        .attr('y2', d => d.endPoints[1][1]);

    // Exit selection: Remove elements for which there is no new data
    stateline.exit().remove();

    return;
}

const drawStateDescription = (container, data) => {
    //console.log('drawStateDescription', data);

    // Select the group element and bind the data
    const stateDescriptionGroup = container.selectAll('g.state-description').data([data], d => d.id); // Assuming each data has a unique 'id'

    // Enter selection: Create new group element for new data points
    const enterStateDescriptionGroup = stateDescriptionGroup
        .enter()
        .append('g')
        .attr('data-id', d => d.id) // same as the state-group for drop behaviour purposes
        .classed('state-description', true) // Apply static class here
        .classed('show', data.show);

    // Merge selection: Update existing group element with new data points
    const mergedStateDescriptionGroup = enterStateDescriptionGroup.merge(stateDescriptionGroup);

    // Update the transform attribute of the group element
    mergedStateDescriptionGroup
        .attr('transform', `translate(${data.endPoints[0][0]}, ${data.endPoints[0][1]})`)
        .classed('show', data.show); // Ensure dynamic class is updated for all elements

    stateDescriptionGroup.exit().remove();

    // Select the ellipse element and bind the data
    const ellipse = mergedStateDescriptionGroup.selectAll('ellipse.state-marker').data([data]);

    // Enter selection: Create new ellipse element for new data points
    const enterEllipse = ellipse.enter().append('ellipse').classed('state-marker', true);

    // Merge selection: Update existing ellipse element with new data points
    const mergedEllipse = ellipse.merge(enterEllipse);

    // Update the attributes of the ellipse element
    mergedEllipse
        .attr('cx', 0)
        .attr('cy', (data.endPoints[1][1] - data.endPoints[0][1]) / 2)
        .attr('rx', d => d.rx || 8)
        .attr('ry', d => d.ry || 4);

    // Exit selection: Remove ellipse elements for which there is no new data
    ellipse.exit().remove();

    // Select the line element and bind the data
    const line1 = mergedStateDescriptionGroup.selectAll('line.sline1').data([data]);

    // Enter selection: Create new line element for new data points
    const enterLine1 = line1.enter().append('line').classed('sline1', true);

    // Merge selection: Update existing line element with new data points
    const mergedLine1 = line1.merge(enterLine1);

    // Update the attributes of the line element
    mergedLine1
        .attr('x1', 0)
        .attr('y1', (data.endPoints[1][1] - data.endPoints[0][1]) / 2 - 4)
        .attr('x2', 0)
        .attr('y2', (data.endPoints[1][1] - data.endPoints[0][1]) / 2);

    // Exit selection: Remove line elements for which there is no new data
    line1.exit().remove();

    // Select the text element and bind the data
    const text = mergedStateDescriptionGroup.selectAll('text.state-label').data([data]);

    // Enter selection: Create new text element for new data points
    const enterText = text.enter().append('text').classed('state-label', true);

    // Merge selection: Update existing text element with new data points
    const mergedText = text.merge(enterText);

    // Update the attributes of the text element
    mergedText
        .attr('x', 10)
        .attr('y', (data.endPoints[1][1] - data.endPoints[0][1]) / 2)
        .attr('dy', '0.35em')
        .attr('font-size', '12px')
        .attr('font-style', 'italic')
        .text(data.label || "");

    // Exit selection: Remove text elements for which there is no new data
    text.exit().remove();
}


// Draw shapes for the Shapes component
export const drawRoleGroup = (container, at, drag) => {
    // Draw a single role shape and label for the Shapes component
    const role = container.selectAll("g.role")
        .data(shapes.filter(s => s.class === 'role'), d => d.id)
        .enter().append("g")
        .attr("class", d => d.class)
        .attr('transform', `translate(${at.x}, ${at.y})`)
        .attr("cursor", "grab")
        .attr('opacity', 1)

    // Draw a rounded rectangle for the role (not drawRect) using the data properties
    role.append('rect')
        .attr("class", "role")
        .attr("data-id", d => d.id)
        .attr("rx", d => d.width / 10)
        .attr("ry", d => d.height / 10)
        .attr("width", d => d.width)
        .attr("height", d => d.height)


    // Draw the role label
    role.append('text')
        .attr("class", "role-name")
        .attr('x', d => d.width / 2)
        .attr('y', -5)
        .attr('text-anchor', 'middle')
        .text(d => d.label || "Role")
        .attr('font-size', '14px');

    role.call(drag);

    return role;
}

export const drawInteractionGroup = (container, at, onDrag) => {
    // Draw a single interaction shape and label for the Shapes component
    const interaction = container.selectAll("g.interaction")
        .data(shapes.filter(s => s.class.includes('interaction')), d => d.id)
        .enter().append("g")
        .attr("class", d => d.class)
        .attr('transform', `translate(${at.x}, ${at.y})`)
        .attr("cursor", "grab")

    drawRect(interaction);
    interaction.call(onDrag);

    return interaction;
};

export const drawActionGroup = (container, at) => {
    const action = container.selectAll("g.action")
        .data(shapes.filter(s => /\baction\b/.test(s.class)), d => d.id) // regex to match word action otherwise you'll get interaction too.
        .enter().append("g")
        .attr("class", d => d.class)
        .attr('transform', `translate(${at.x}, ${at.y})`)
        .attr("cursor", "grab")

    drawRect(action);

    return action;
}

export const drawStartRoleGroup = (container, at) => {
    const startRole = container.selectAll("g.start-role")
        .data(shapes.filter(s => s.class.includes('start-role')), d => d.id)
        .enter().append('g')
        .attr('class', d => d.class)
        .attr('transform', `translate(${at.x}, ${at.y})`)
        .attr("cursor", "grab")
        .attr("data-label", d => d.label);

    drawStartRoleRect(startRole);

    return startRole;
}

export const drawTriggerGroup = (container, at) => {
    const trigger = container.selectAll("g.trigger")
        .data(shapes.filter(s => s.class.includes('trigger')), d => d.id)
        .enter().append("g")
        .attr("class", d => d.class)
        .attr('transform', `translate(${at.x}, ${at.y})`)
        .attr("cursor", "grab")
        .attr("data-label", d => d.label)

    drawTriggerShape(trigger);

    return trigger;
};

export const drawEllipsisGroup = (container, at) => {
    const ellipsis = container.selectAll("g.ellipsis")
        .data(shapes.filter(s => s.class.includes('ellipsis')), d => d.id)
        .enter().append("g")
        .attr("class", d => d.class)
        .attr('transform', `translate(${at.x}, ${at.y})`)
        .attr("cursor", "grab")
        .attr("data-label", d => d.label)

    drawEllipsisShape(ellipsis);

    return ellipsis;
};

export const drawStateLabelGroup = (container, at) => {
    const radstate = container.selectAll("g.state-label")
        .data(shapes.filter(s => s.class.includes('state-label')), d => d.id)
        .enter().append("g")
        .attr("class", d => d.class)
        .attr('data-id', d => d.id)
        .attr('transform', `translate(${at.x}, ${at.y})`)
        .attr("cursor", "grab")
        .attr("data-label", d => d.label)

    drawStateLabelShape(radstate);

    return radstate;
}

export const drawPartRefinementGroup = (container, at) => {
    const partRefinement = container.selectAll("g.part-refinement-group")
        .data(shapes.filter(s => s.class.includes('part-refinement')), d => d.id)
        .enter().append("g")
        .attr("class", "part-refinement-group")
        .attr('transform', `translate(${at.x}, ${at.y})`)
        .attr("cursor", "grab")
        .attr("data-label", d => d.label)

    drawPartRefinementShape(partRefinement);
    drawLabel(partRefinement);

    return partRefinement;
}

const drawAsterisk = (part) => {
    const asterisk = part.selectAll('text.asterisk').data(d => [d]);
    asterisk.enter().append('text').classed('asterisk', true).merge(asterisk)
        .attr('x', d => 4 + d.width / 2)
        .attr('y', d => d.height - 8)
        .attr('dy', '0.35em')
        .attr('font-size', '36px')
        .text('*');
    asterisk.exit().remove();

    return asterisk;
}

export const drawPartRepeatGroup = (container, at, drag) => {
    const partRepeat = container.selectAll("g.part-repeat-group")
        .data(shapes.filter(s => s.class.includes('part-repeat')), d => d.id)
        .enter().append("g")
        .attr("class", "part-repeat-group")
        .attr('transform', `translate(${at.x}, ${at.y})`)
        .attr("cursor", "grab")
        .attr("data-label", d => d.label)

    drawPartRefinementShape(partRepeat);
    drawAsterisk(partRepeat);
    drawLabel(partRepeat);
    partRepeat.call(drag);
    return partRepeat;
}

export const drawCaseRefinementGroup = (container, at) => {
    const caseRefinement = container.selectAll("g.case-refinement-group")
        .data(shapes.filter(s => s.class.includes('case-refinement')), d => d.id)
        .enter().append("g")
        .attr("class", "case-refinement-group")
        .attr('transform', `translate(${at.x}, ${at.y})`)
        .attr("cursor", "grab")
        .attr("data-label", d => d.label)

    drawCaseRefinementShape(caseRefinement);
    drawLabel(caseRefinement);

    return caseRefinement;
}
