import React from 'react';

const InteractionIcon = ({ width = 20, height = 20, className = '', style = {} }) => {
    return (
        <svg width={`${width}px`} height={`${height}px`} viewBox={`0 0 ${width * 3/2} ${height * 3/2}`} 
            className={`interaction ${className}`} style={style}>
            {/* Rectangle */}
            <rect x={width / 4} y={height / 4} width={width} height={height} fill="none" />

            {/* Short lines at the top and bottom */}
            <line x1={width * 3/4} y1={0} x2={width * 3/4} y2={width / 4} />
            <line x1={width * 3/4} y1={height *5/4 } x2={width *3/4} y2={height * 3/2} />
        </svg>
    );
};

export default InteractionIcon;