import { useContext, useEffect, useState } from 'react'
import { ModelContext } from '../Model/ModelProvider';

import FirstCutUI from './FirstCut/FirstCutUI';
import FirstCutLogic from './FirstCut/FirstCutLogic';
import SecondCutLogic from './SecondCut/SecondCutLogic';
import SecondCutUI from './SecondCut/SecondCutUI';
import CollapsibleComponent from "../UI/CollapsibleComponent";
import Tabs from "../UI/Tabs";

// styles
import '../../css/PAD.css'
import '../../css/Tab.css'
import '../../css/ContextMenu.css'

const PADHandler = () => {
    const { getPAD2, replacePADEditableSchema } = useContext(ModelContext);
    const { replaced, modified } = getPAD2();
    const [activeTab, setActiveTab] = useState('pad1');


    useEffect(() => {
        // Whenever the second cut schema is replaced, stealth replace the editable schema
        // This is to avoid the PAD2SecondCut components trying to find things that aren't there anymore
        if (replaced && !modified) {
            console.log("PADHandler: silent replace editableSchema", 'pad2');
            replacePADEditableSchema('pad2');
        }
    }, [replaced, modified, replacePADEditableSchema]);

    const tabData = [
        { key: 'pad1', label: 'First Cut' },
        { key: 'pad2', label: 'Second Cut' }
    ];

    return (
        <CollapsibleComponent label="Process Architecture">
            <Tabs tabs={tabData} setActiveTab={setActiveTab}>
                <FirstCutLogic>
                    <FirstCutUI isActive={activeTab === 'pad1'} />
                </FirstCutLogic>
                <SecondCutLogic >
                    <SecondCutUI isActive={activeTab === 'pad2'} />
                </SecondCutLogic>
            </Tabs>
        </CollapsibleComponent >
    )
}

export default PADHandler