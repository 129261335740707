import { useRADDispatch, useRAD } from './RADHandlerLogic';

export const useDropLogic = (diagramRef, draggedShape, viewBoxWidth, viewBoxHeight) => {
    const { addRole, addThread, addOpeningActivity, addTerminalActivity, addActivityToState, addActivityToActivity,
        addOpeningActivityToSubthread, addRefinement } = useRADDispatch();
    const {
        getRole, getThread, getState, getActivity, getPartThread, getCaseCondition, getSubthreads
    } = useRAD();

    const locateDropShape = () => {
        const svg = diagramRef.current.querySelector('svg');
        if (!svg) return;
        const gElem = svg.querySelector('g');

        // Convert the screen coordinates to g coordinates
        // Account for the window scroll position
        const x = draggedShape.x - window.scrollX;
        const y = draggedShape.y - window.scrollY;

        const pt = svg.createSVGPoint();
        pt.x = x;
        pt.y = y;
        const transformedPt = pt.matrixTransform(svg.getScreenCTM().inverse());

        // Check if the transformed point is within the diagram area
        if (transformedPt.x >= 0 && transformedPt.y >= 0
            && transformedPt.x <= viewBoxWidth && transformedPt.y <= viewBoxHeight) {
            const gPt = svg.createSVGPoint();
            gPt.x = x;
            gPt.y = y;
            const transformedGPt = gPt.matrixTransform(gElem.getScreenCTM().inverse());
            return { transformedGPt, transformedPt };
        }
        else {
            return { undefined };
        };
    }
    /*
     * Adding shapes by drop to the diagram
    */
    const addDroppedItem = (shape) => {

        // Validate the shape
        if (!shape || !shape.class) {
            //addMessage("Drop action failed. No drop shape provided.");
            return;
        }
        // Get the element at the drop point
        const elementAtDrop = document.elementFromPoint(shape.x, shape.y);

        // Guards.
        const { transformedGPt } = locateDropShape();
        if (!transformedGPt) {
           // addMessage("Drop action failed. Ensure the drop is on the drawing area.");
            return; // No drop target found
        }
        if (!elementAtDrop || !elementAtDrop.closest('svg')) {
            // The drop point is not on the SVG
            //addMessage("Drop action failed. Ensure the drop is on the drawing area.");
            //console.log('Dropped outside SVG');
        }
        if (shape.class === 'role' && elementAtDrop.classList.contains('role')) {
            //addMessage("Cannot drop role on a role.");
            return;
        }
        // End of guards

        const shape_class = shape.class;
        console.log('shape class', shape_class);
        if (shape_class === 'role') {
            // Add a new role
            const position = transformedGPt;
            addRole({ position });
            return;
        }

        const groupElement = elementAtDrop.closest('g');
        if (!groupElement) {
            //addMessage("No group element found at drop point.");
            return;
        }

        const dataId = groupElement ? groupElement.getAttribute('data-id') : null;
        const dataClass = groupElement ? groupElement.classList : null;
        console.log('group elementAtDrop', elementAtDrop, groupElement, dataId, dataClass);

        if (['part-refinement', 'case-refinement', 'part-repeat'].includes(shape_class)) {
            if (dataClass.contains('interaction') || dataClass.contains('action') || dataClass.contains('trigger')) {
                // Add a refinement to the activity
                console.log('add refinement to activity', shape_class, dataId);
                const activity_id = groupElement.getAttribute('data-id');
                const activity = getActivity(activity_id);
                console.log('activity', activity_id, activity);
                if (activity) {
                    const thread = getThread(activity.thread_id);
                    // Add a refinement to the activity
                    addRefinement({ activity, shape_class, thread });
                }
            }
            else {
                //addMessage("Refinements can only be added to actions, interaction or triggers.");
            }
        }
        else if (shape_class.includes('activity')) {
            console.log('act class ' + shape_class + ', el drop ' + elementAtDrop.classList + ', el parent ' + dataClass);

            if (dataClass.contains('role-group')) {
                // Activity dropped on a role will 
                // create a new thread
                // (adjust the position to be relative to the role)
                // and become the first activity in the thread

                const role = getRole(dataId);
                if (!role) {
                    //addMessage("Cannot add thread to non-existent role.");
                    return;
                }

                const position = { x: transformedGPt.x - role.x, y: transformedGPt.y - role.y };
                //addMessage("Adding thread to role " + dataId + " at position " + position.x + ", " + position.y);

                const newThread = addThread({ role_id: dataId, position });
                console.log('new thread', newThread);
                // Add an activity node to the new thread
                addOpeningActivity({ thread: newThread, shape_class });
                //addMessage("Added activity to new thread." + newThread);

            } else if (dataClass.contains('thread-group')) {
                // Add a terminal activity to the target thread.
                const thread = getThread(dataId);
                console.log('add activity node to thread', thread, shape_class, dataId);
                addTerminalActivity({ thread, shape_class })

            }
            else if (dataClass.contains('state-group') || dataClass.contains('state-description')) {
                // Add an activity to a state line. This will insert the activity
                // making the state its prestate. A new postsate will be created, which 
                // will become the prestate of the next activity in the thread.
                const groupElement = elementAtDrop.closest('g.state-group');
                // get the data-id of the group element
                const state_id = groupElement.getAttribute('data-id');
                const state = getState(state_id)
                if (state) {
                    // States are anonymous in themselves, so look up the tree for the thread this is part of
                    const threadGroupElement = elementAtDrop.closest('g.thread-group');
                    const thread_id = threadGroupElement.getAttribute('data-id');
                    const thread = getThread(thread_id);
                    if (!thread) {
                        //addMessage("Cannot add activity to non-existent thread.");
                        return;
                    }
                    addActivityToState({ thread, shape_class, thread_state: state_id })
                }
            } else if (dataClass.contains('activity')) {
                // Add an activity to an existing activity. This will insert the activity
                // with a new prestate (which will become the poststate of the existing activity),
                // and acquire the poststate of the existing activity as its own poststate.
                const activity = getActivity(dataId);
                console.log('activity', dataId, activity);
                if (activity) {
                    const { thread_id, poststate_id } = activity;
                    const thread = getThread(thread_id);
                    addActivityToActivity({ thread, shape_class, thread_state: poststate_id });
                }
            }
            else if (dataClass.contains('part-repeat-thread')) {
                // Add activity to the part repeat thread.
                const part_id = groupElement.getAttribute('data-id');
                const part_repeat = getPartThread(part_id);
                if (part_repeat) {
                    console.log('add activity to part repeat', part_repeat);
                    addOpeningActivityToSubthread({ subthread: part_repeat, shape_class })
                }
            }
            else if (dataClass.contains('part-repeat-group')) {
                const part_id = groupElement.getAttribute('data-id');
                const part_repeat = getSubthreads('part-repeat', part_id)[0];
                if (part_repeat) {
                    addOpeningActivityToSubthread({ subthread: part_repeat, shape_class })
                }
            }
            else if (dataClass.contains('part-group')) {
                const part_id = groupElement.getAttribute('data-id');
                const part_thread = getPartThread(part_id);
                if (part_thread) {
                    addOpeningActivityToSubthread({ subthread: part_thread, shape_class })
                }
            }
            else if (dataClass.contains('condition-group')) {
                const case_id = groupElement.getAttribute('data-id');
                const case_condition = getCaseCondition(case_id);
                if (case_condition) {
                    addOpeningActivityToSubthread({ subthread: case_condition, shape_class })
                }
            }
            else {
                console.log("failed to add shape to dataclass", shape_class, dataClass);
            }
        } else {
            console.log('dragged shape outside role or role child');
            //addMessage("Dragged shape outside role. ");
            //console.log('dragged shape outside role or role child');
        }
    }
    return { locateDropShape, addDroppedItem };
}

