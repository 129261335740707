import React, { createContext, useState, useContext, useReducer, useEffect, useMemo, useCallback } from 'react';
import { nanoid } from 'nanoid';
export const RADDispatchContext = createContext(); // Change state
export const RADContext = createContext();  // Get state
export const RADSelectionContext = createContext(); // Get selected component

// Helper functions
function addInteractions(connection, interactions) {
    return {
        ...connection,
        interactions: [...new Set([...connection.interactions, ...interactions])]
    };
}
function findConnectionWithId(connections, interactions) {
    return connections.find(con => con.interactions.some(id => interactions.includes(id)));
}

function radReducer(state, action) {
    const applyChanges = (newState) => ({
        ...newState,
        version: (newState.version || 0) + 1, // Increment version number
    });
    //console.log('radReducer', action);
    switch (action.type) {
        case 'updateRAD': {
            const newState = { ...state, ...action.payload };
            return applyChanges(newState);
        }
        case 'addRole': {
            // Idempotent role addition
            const roleExists = state.roles.some(role => role.id === action.payload.id);
            if (roleExists) {
                return state;
            }
            const newState = { ...state, roles: [...state.roles, action.payload] };
            return applyChanges(newState);
        }
        case 'addThread': {
            // Idempotent thread addition
            const threadExists = state.activityThreads.some(thread => thread.id === action.payload.id);
            if (threadExists) {
                return state;
            }
            const newState = { ...state, activityThreads: [...state.activityThreads, action.payload] };
            return applyChanges(newState);
        }
        case 'addActivity': {
            const newActivity = action.payload[0];
            const updatedActivity = action.payload[1];

            // Update an existing activity if updatedActivity is provided
            const activitiesUpdated = updatedActivity
                ? state.activities.map(activity =>
                    activity.id === updatedActivity.id ? updatedActivity : activity)
                : state.activities;

            // Add newActivity if it's not already present (based on id)
            const activityExists = activitiesUpdated.some(activity => activity.id === newActivity.id);
            const activitiesFinal = activityExists
                ? activitiesUpdated
                : [...activitiesUpdated, newActivity];

            const updatedThreads = updatedActivity
                ? state.activityThreads.map(thread =>
                    thread.id === updatedActivity.thread_id
                        ? { ...thread, poststate_id: updatedActivity.poststate_id }
                        : thread
                ) : state.activityThreads;

            const newState = { ...state, activities: activitiesFinal, activityThreads: updatedThreads };

            return applyChanges(newState);
        }
        case 'addActivityToSubthread': {
            const newActivity = action.payload[0];
            const updatedActivity = action.payload[1];

            // Update an existing activity if updatedActivity is provided
            const activitiesUpdated = updatedActivity
                ? state.activities.map(activity =>
                    activity.id === updatedActivity.id ? updatedActivity : activity)
                : state.activities;

            // Add newActivity if it's not already present (based on id)
            const activityExists = activitiesUpdated.some(activity => activity.id === newActivity.id);
            const activitiesFinal = activityExists
                ? activitiesUpdated
                : [...activitiesUpdated, newActivity];


            const newState = { ...state, activities: activitiesFinal };

            return applyChanges(newState);
        }
        case 'addConnection': {
            // Idempotent connection addition
            // payload is an array of two activity ids
            // For it to have got this far it must be valid
            const connectionPair = action.payload.interactions;

            // Find if there's an existing connection that should be updated
            const foundObject = findConnectionWithId(state.connections, connectionPair);

            // Either update the found object or create a new connection entry
            const newConnection = foundObject
                ? addInteractions(foundObject, connectionPair)
                : { id: action.payload.id, class: action.payload.class, interactions: connectionPair };

            // Update the state with the new or updated connection
            const newState = {
                ...state,
                connections: foundObject
                    ? state.connections.map(con => con.id === foundObject.id ? newConnection : con)
                    : [...state.connections, newConnection]
            };

            return applyChanges(newState);
        }
        case 'deleteConnection': {
            //
            const newState = { ...state, connections: state.connections.filter(connection => connection.id !== action.payload.id) };
            return applyChanges(newState);
        }
        case 'deleteConnectionSection': {
            const { from, to } = action.payload;
            let interactions = state.connections.find(connection => connection.id === action.payload.id).interactions;

            const fromIndex = interactions.indexOf(from);
            const toIndex = interactions.indexOf(to);

            // If 'to' directly follows 'from', remove 'to'.
            if (fromIndex !== -1 && toIndex === fromIndex + 1) {
                interactions.splice(toIndex, 1);
            }

            console.log('Deleting connection section', interactions);
            const newState = {
                ...state,
                connections: state.connections.map(con => con.id === action.payload.id ? { ...con, interactions } : con)
            };
            return applyChanges(newState);
        }
        case 'addState': {
            // Idempotent state addition
            const stateExists = state.states.some(state => state.id === action.payload.id);
            return stateExists
                ? state
                : applyChanges({ ...state, states: [...state.states, action.payload] });
        }
        case 'addCaseRefinement': {
            // Idempotent case refinement addition
            const caseRefinementExists = state.caseRefinements.some(refinement => refinement.id === action.payload.id);
            if (caseRefinementExists) {
                return state;
            }
            // Add a mandatory minimum two conditions
            const newConditions = [
                {
                    id: nanoid(),
                    class: 'case-condition',
                    label: 'c1',
                    case_refinement_id: action.payload.id,
                    prestate_id: action.payload.prestate_id,
                    poststate_id: action.payload.poststate_id,
                    index: 0
                },
                {
                    id: nanoid(),
                    class: 'case-condition',
                    label: 'c2',
                    case_refinement_id: action.payload.id,
                    prestate_id: action.payload.prestate_id,
                    poststate_id: action.payload.poststate_id,
                    index: 1
                }
            ]

            const newState = { ...state, caseRefinements: [...state.caseRefinements, action.payload], caseConditions: [...state.caseConditions, ...newConditions] };
            return applyChanges(newState);
        }
        case 'addCaseCondition': {
            const caseRefinementExists = state.caseRefinements.some(refinement => refinement.id === action.payload.id);
            if (!caseRefinementExists) {
                return state;
            }
            const newCondition =
            {
                id: nanoid(),
                class: 'case-condition',
                label: 'c?',
                case_refinement_id: action.payload.id,
                prestate_id: action.payload.prestate_id,
                poststate_id: action.payload.poststate_id
            }
            const index = state.caseConditions.filter(condition => condition.case_refinement_id === action.payload.id).length;
            const newState = { ...state, caseConditions: [...state.caseConditions, { ...newCondition, index }] };
            return applyChanges(newState);
        }
        case 'insertCaseCondition': {
            const caseRefinement = state.caseRefinements.find(refinement => refinement.id === action.payload.case_refinement_id);
            if (!caseRefinement) {
                return state;
            }
            const { prestate_id, poststate_id } = caseRefinement;
            const newCondition =
            {
                id: nanoid(),
                class: 'case-condition',
                label: '',
                case_refinement_id: action.payload.case_refinement_id,
                prestate_id,
                poststate_id,
            }
            // insert the new condition prior to the sibling condition
            const { index } = state.caseConditions.find(sibling => sibling.id === action.payload.id);
            // increment the index of all conditions after the insertion point
            const updatedConditions = state.caseConditions
                .filter(condition => condition.case_refinement_id === action.payload.case_refinement_id)
                .map(condition => condition.index >= index ? { ...condition, index: condition.index + 1 } : condition);
            const otherConditions = state.caseConditions
                .filter(condition => condition.case_refinement_id !== action.payload.case_refinement_id);
            const newState = { ...state, caseConditions: [...otherConditions, { ...newCondition, index }, ...updatedConditions] };
            return applyChanges(newState);
        }
        case 'addPartRefinement': {
            // Idempotent part refinement addition
            const partRefinementExists = state.partRefinements.some(refinement => refinement.id === action.payload.id);
            if (partRefinementExists) {
                return state;
            }
            // Add a mandatory minimum two threads
            const newThreads = [
                {
                    id: nanoid(),
                    class: 'part-thread',
                    label: 't1',
                    part_refinement_id: action.payload.id,
                    prestate_id: action.payload.prestate_id,
                    poststate_id: action.payload.poststate_id,
                    index: 0
                },
                {
                    id: nanoid(),
                    class: 'part-thread',
                    label: 't2',
                    part_refinement_id: action.payload.id,
                    prestate_id: action.payload.prestate_id,
                    poststate_id: action.payload.poststate_id,
                    index: 1
                }
            ]
            const newState = { ...state, partRefinements: [...state.partRefinements, action.payload], partThreads: [...state.partThreads, ...newThreads] };
            return applyChanges(newState);
        }
        case 'addPartThread': {
            const partRefinementExists = state.partRefinements.some(refinement => refinement.id === action.payload.id);
            if (!partRefinementExists) {
                return state;
            }
            const newThread =
            {
                id: nanoid(),
                class: 'part-thread',
                label: 't?',
                part_refinement_id: action.payload.id,
                prestate_id: action.payload.prestate_id,
                poststate_id: action.payload.poststate_id
            }
            const index = state.partThreads.filter(thread => thread.part_refinement_id === action.payload.id).length;

            const newState = { ...state, partThreads: [...state.partThreads, { ...newThread, index }] };
            return applyChanges(newState);
        }
        case 'insertPartThread': {
            const partRefinementExists = state.partRefinements.some(refinement => refinement.id === action.payload.part_refinement_id);
            if (!partRefinementExists) {
                return state;
            }
            const newThread =
            {
                id: nanoid(),
                class: 'part-thread',
                label: 't?',
                part_refinement_id: action.payload.part_refinement_id,
                prestate_id: action.payload.prestate_id,
                poststate_id: action.payload.poststate_id
            }
            // insert the new thread at the specified index
            const { index } = state.partThreads.find(sibling => sibling.id === action.payload.id);
            const updatedThreads = state.partThreads
                .filter(thread => thread.part_refinement_id === action.payload.part_refinement_id)
                .map(thread => thread.index >= index ? { ...thread, index: thread.index + 1 } : thread);
            const otherThreads = state.partThreads
                .filter(thread => thread.part_refinement_id !== action.payload.part_refinement_id);
            const newState = { ...state, partThreads: [...otherThreads, { ...newThread, index }, ...updatedThreads] };
            return applyChanges(newState);
        }
        case 'addPartRepeat': {
            // Idempotent part repeat addition
            // Note that a part repeat has a single subthread that looks exactly like a part-refinement subthread
            const partRepeatExists = state.partRepeats.some(repeat => repeat.id === action.payload.id);
            if (partRepeatExists) { return state; }
            const subThread =
            {
                id: nanoid(),
                class: 'part-repeat-thread',
                label: 'repeat',
                part_repeat_id: action.payload.id,
                prestate_id: action.payload.prestate_id,
                poststate_id: action.payload.poststate_id,
                index: 0
            }

            const newState = { ...state, partRepeats: [...state.partRepeats, action.payload], partThreads: [...state.partThreads, subThread] };
            return applyChanges(newState);
        }
        case 'updateRole': {
            const newState = { ...state, roles: state.roles.map(role => role.id === action.payload.id ? { ...role, ...action.payload } : role) };
            return applyChanges(newState);
        }
        case 'updateThread': {
            // Maintain absolute positions of threads. 
            // The only user update action on threads is dragging so this checks bounds and shifts
            // everything accordingly. Offset 30,20 is is the min distance of a thread from the role edge.
            // Maybe that doesn't belong here but it's a good place to start.
            const { x, y } = action.payload;
            const role_id = state.activityThreads.find(thread => thread.id === action.payload.id).role_id;

            const updatedX = x < 30 ? 30 : x;
            const updatedY = y < 20 ? 20 : y;
            const shiftX = x < 30 ? -x + 30 : 0;
            const shiftY = y < 20 ? -y + 20 : 0;

            const updatedThreads = state.activityThreads
                .filter(thread => thread.role_id === role_id)
                .map(thread => thread.id === action.payload.id
                    ? { ...thread, x: updatedX, y: updatedY }
                    : { ...thread, x: thread.x + shiftX, y: thread.y + shiftY });
            // update the updatedThreads   
            const otherThreads = state.activityThreads
                .filter(thread => thread.role_id !== role_id);
            const newState = { ...state, activityThreads: [...otherThreads, ...updatedThreads] };
            return applyChanges(newState);
        }
        case 'updateActivity': {
            const newState = { ...state, activities: state.activities.map(activity => activity.id === action.payload.id ? { ...activity, ...action.payload } : activity) };
            return applyChanges(newState);
        }
        case 'updateCaseRefinement': {
            const newState = { ...state, caseRefinements: state.caseRefinements.map(refinement => refinement.id === action.payload.id ? { ...refinement, ...action.payload } : refinement) };
            return applyChanges(newState);
        }
        case 'updatePartRefinement': {
            const newState = { ...state, partRefinements: state.partRefinements.map(refinement => refinement.id === action.payload.id ? { ...refinement, ...action.payload } : refinement) };
            return applyChanges(newState);
        }
        case 'updatePartRepeat': {
            const newState = { ...state, partRepeats: state.partRepeats.map(repeat => repeat.id === action.payload.id ? { ...repeat, ...action.payload } : repeat) };
            return applyChanges(newState);
        }
        case 'updateCaseCondition': {
            const newState = { ...state, caseConditions: state.caseConditions.map(condition => condition.id === action.payload.id ? { ...condition, ...action.payload } : condition) };
            return applyChanges(newState);
        }
        case 'updatePartThread': {
            const newState = { ...state, partThreads: state.partThreads.map(thread => thread.id === action.payload.id ? { ...thread, ...action.payload } : thread) };
            return applyChanges(newState);
        }
        case 'updateState': {
            const newState = { ...state, states: state.states.map(state => state.id === action.payload.id ? { ...state, ...action.payload } : state) };
            return applyChanges(newState);
        }
        case 'deleteRole': {
            // Safe delete of a role and all its threads and activities
            // I feel this could be snappier.
            const role_id = action.payload.id;
            const threadsToRemove = state.activityThreads
                .filter(thread => thread.role_id === role_id)
                .map(thread => thread.id);
            const updatedThreads = state.activityThreads
                .filter(thread => thread.role_id !== role_id);
            const updatedActivities = state.activities
                .filter(activity => !threadsToRemove.includes(activity.thread_id));
            const updatedCaseRefinements = state.caseRefinements
                .filter(refinement => !threadsToRemove.includes(refinement.thread_id));
            const updatedPartRefinements = state.partRefinements
                .filter(refinement => !threadsToRemove.includes(refinement.thread_id));
            const updatedPartRepeats = state.partRepeats
                .filter(repeat => !threadsToRemove.includes(repeat.thread_id));
            const newState = {
                ...state,
                roles: state.roles.filter(role => role.id !== role_id),
                activityThreads: updatedThreads,
                activities: updatedActivities,
                caseRefinements: updatedCaseRefinements,
                partRefinements: updatedPartRefinements,
                partRepeats: updatedPartRepeats
            }
            return applyChanges(newState);
        }
        case 'deleteThread': {
            const thread_id = action.payload.id;
            const activitiesToRemove = state.activities.filter(activity => activity.thread_id === thread_id).map(activity => activity.id);
            const updatedActivities = state.activities.filter(activity => !activitiesToRemove.includes(activity.thread_id));
            const updatedCaseRefinements = state.caseRefinements.filter(refinement => refinement.thread_id !== thread_id);
            const updatedPartRefinements = state.partRefinements.filter(refinement => refinement.thread_id !== thread_id);
            const updatedPartRepeats = state.partRepeats.filter(repeat => repeat.thread_id !== thread_id);
            const newState = {
                ...state,
                activityThreads: state.activityThreads.filter(thread => thread.id !== thread_id),
                activities: updatedActivities,
                caseRefinements: updatedCaseRefinements,
                partRefinements: updatedPartRefinements,
                partRepeats: updatedPartRepeats
            }
            return applyChanges(newState);
        }
        case 'deleteActivity': {
            console.log('deleteActivity', action.payload);
            const newState = { ...state, activities: state.activities.filter(activity => activity.id !== action.payload.id) };
            return applyChanges(newState);
        }
        case 'deleteCaseRefinement': {
            const newState = { ...state, caseRefinements: state.caseRefinements.filter(refinement => refinement.id !== action.payload.id) };
            return applyChanges(newState);
        }
        case 'deletePartRefinement': {
            const newState = { ...state, partRefinements: state.partRefinements.filter(refinement => refinement.id !== action.payload.id) };
            return applyChanges(newState);
        }
        case 'deletePartRepeat': {
            const updatedThreads = state.partThreads.filter(thread => thread.part_repeat_id !== action.payload.id);
            const newState = { ...state, partRepeats: state.partRepeats.filter(repeat => repeat.id !== action.payload.id), partThreads: updatedThreads };
            return applyChanges(newState);
        }
        case 'deleteCaseCondition': {
            const condition = state.caseConditions.find(condition => condition.id === action.payload.id);
            const caseRefinement = state.caseRefinements.find(refinement => refinement.id === condition.case_refinement_id);
            if (!caseRefinement) {
                return state;
            }
            const otherConditions = state.caseConditions.filter(condition => condition.case_refinement_id !== caseRefinement.id);
            const updatedConditions = state.caseConditions
                .filter(condition => condition.case_refinement_id === caseRefinement.id && condition.id !== action.payload.id);
            // update the index of all conditions after the deletion point
            const index = state.caseConditions.find(condition => condition.id === action.payload.id).index;
            const updatedConditionsFinal = updatedConditions
                .map(condition => condition.index > index ? { ...condition, index: condition.index - 1 } : condition);
            const newState = { ...state, caseConditions: [...otherConditions, ...updatedConditionsFinal] };
            return applyChanges(newState);
        }
        case 'deletePartThread': {
            const partThread = state.partThreads.find(thread => thread.id === action.payload.id);
            const partRefinement = state.partRefinements.find(refinement => refinement.id === partThread.part_refinement_id);
            if (!partRefinement) {
                return state;
            }
            const otherThreads = state.partThreads.filter(thread => thread.part_refinement_id !== partRefinement.id);
            const updatedThreads = state.partThreads
                .filter(thread => thread.part_refinement_id === partRefinement.id && thread.id !== action.payload.id);
            // update the index of all threads after the deletion point
            const index = state.partThreads.find(thread => thread.id === action.payload.id).index;
            const updatedThreadsFinal = updatedThreads
                .map(thread => thread.index > index ? { ...thread, index: thread.index - 1 } : thread);
            const newState = { ...state, partThreads: [...otherThreads, ...updatedThreadsFinal] };
            return applyChanges(newState);
        }
        case 'deleteState': {
            const newState = { ...state, states: state.states.filter(state => state.id !== action.payload.id) };
            return applyChanges(newState);
        }
        case 'switchRAD': {
            const newState = { ...action.payload };
            // Migrate the RAD to a new version. Quite brutal, but it's a start.
            let version = state.version || 0;
            if (!Array.isArray(newState.partRepeats)) {
                newState.partRepeats = [];
                version = -1
            }
            if (!Array.isArray(newState.partRefinements)) {
                newState.partRefinements = [];
                version = -1
            }
            if (!Array.isArray(newState.caseRefinements)) {
                newState.caseRefinements = [];
                version = -1
            }
            if (!Array.isArray(newState.states)) {
                newState.states = [];
                version = -1
            }
            if (!Array.isArray(newState.activityThreads)) {
                newState.activityThreads = [];
                version = -1
            }
            if (!Array.isArray(newState.activities)) {
                newState.activities = [];
                version = -1
            }
            if (!Array.isArray(newState.roles)) {
                newState.roles = [];
                version = -1
            }
            if (!Array.isArray(newState.connections)) {
                newState.connections = [];
                version = -1
            }
            if (version === -1) {
                console.log('switch update to RAD');
                // Something changed, so we need to update the model
                applyChanges(newState);
            }
            //console.log('switchRAD', newState);
            return newState;
        }
        case 'cleanRAD': {
            // Remove objects that have no parent
            // Maybe not necessay in long run, once the logic is solid
            const roles = state.roles.map(role => role.id);
            const updatedThreads = state.activityThreads.filter(thread => roles.includes(thread.role_id));
            const threads = state.activityThreads.map(thread => thread.id);
            const updatedActivities = state.activities.filter(activity => threads.includes(activity.thread_id));
            const updatedCaseRefinements = state.caseRefinements ? state.caseRefinements.filter(refinement => threads.includes(refinement.thread_id)) : [];
            const updatedPartRefinements = state.partRefinements ? state.partRefinements.filter(refinement => threads.includes(refinement.thread_id)) : [];
            const updatedPartRepeats = state.partRepeats ? state.partRepeats.filter(repeat => threads.includes(repeat.thread_id)) : [];
            const caseRefs = updatedCaseRefinements.map(refinement => refinement.id);
            const updatedConditions = state.caseConditions ? state.caseConditions.filter(condition => caseRefs.includes(condition.case_refinement_id)) : [];
            const partRefs = updatedPartRefinements.map(refinement => refinement.id);
            const updatedPartThreads = state.partThreads ? state.partThreads.filter(thread => partRefs.includes(thread.part_refinement_id)) : [];
            const updatedConnections = state.connections ? state.connections.filter(connection => threads.includes(connection.source_activity_id) && threads.includes(connection.target_activity_id)) : [];

            const newState = {
                ...state,
                activityThreads: updatedThreads,
                activities: updatedActivities,
                caseRefinements: updatedCaseRefinements,
                partRefinements: updatedPartRefinements,
                partRepeats: updatedPartRepeats,
                caseConditions: updatedConditions,
                partThreads: updatedPartThreads,
                connections: updatedConnections
            };
            return applyChanges(newState);
        }
        default:
            return state;
    }
}

export const orderActivitiesWithPoints = (activities, prestate_id) => {
    // Order activities based on their preState and postState
    function orderNextActivity(prestate_id, remainingActivities, orderedActivities = []) {
        //console.log('order preState', prestate_id, remainingActivities, orderedActivities);
        const nextActivityIndex = remainingActivities.findIndex(activity => activity.prestate_id === prestate_id);
        if (nextActivityIndex === -1) {
            //console.log('no next activity', orderedActivities);
            // Base case: No next activity found, end recursion
            return orderedActivities;
        }
        const nextActivity = remainingActivities[nextActivityIndex];
        const newRemaining = remainingActivities.filter((_, index) => index !== nextActivityIndex);
        const newOrdered = [...orderedActivities, nextActivity];
        // Recursive call with the postState of the current activity as the new preState
        return orderNextActivity(nextActivity.poststate_id, newRemaining, newOrdered);
    }

    const orderedActivities = orderNextActivity(prestate_id, activities);
    //console.log('orderedActivities for points', orderedActivities);
    // Calculate connection points for each ordered activity
    if (orderedActivities.length === 0) {
        return [];
    }
    const connectionPoints = orderedActivities.map((activity, index) => {
        // Calculate bottom center of the current activity
        const bottomCenter = activity
            ? [activity.x + activity.width / 2, activity.y + activity.height]
            : [0, 0]; // This can't happen
        // Calculate top center of the next activity, if there is a next activity
        const nextActivity = orderedActivities[index + 1];
        const topCenter = nextActivity
            ? [nextActivity.x + nextActivity.width / 2, nextActivity.y]
            : [activity.x + activity.width / 2, activity.y + activity.height + 20]; // last activity gets a default end point
        return { id: activity.poststate_id, endPoints: [bottomCenter, topCenter] };
    });
    // Add a connection point for the first activity 
    const firstActivity = orderedActivities[0];
    //console.log('firstActivity', firstActivity);
    const firstActivityConnection = {
        id: firstActivity.prestate_id,
        endPoints: [[firstActivity.x + firstActivity.width / 2, firstActivity.y - 20],
        [firstActivity.x + firstActivity.width / 2, firstActivity.y]]
    };

    const connectionPointsWithFirst = [firstActivityConnection, ...connectionPoints];

    return connectionPointsWithFirst;
};


const initialRAD = {
    id: "",
    version: undefined,
    principal_process_id: "", // either a 1:1 to the PAD process or the principal process in a merged RAD
    name: "", // arbitrary name
    viewpoint: "", // explain what this RAD is used for in modelling terms eg, descriptive, partial, design, etc
    description: "",
    lead_role: "",
    merged: false,
    roles: [],
    activityThreads: [],
    activities: [],
    caseRefinements: [], // { id: { id, question label, conditions: [] } } An array of conditional subthreads
    partRefinements: [],    // {id, threads: []} An array of parallel subthreads
    partRepeats: [], // {id, label} A single subthread repeated ( => not a loop, but n parallel threads)
    states: [],
    caseConditions: [],
    partThreads: [],
    connections: []
};

const RADHandlerLogic = ({ currentRAD, onRadChange, children }) => {
    const [state, dispatch] = useReducer(radReducer, { ...initialRAD, ...currentRAD });
    const [prevRAD, setPrevRAD] = useState(currentRAD);
    const [isUpdating, setIsUpdating] = useState(false);
    const [selectedComponent, setSelectedComponent] = useState(null);
    const [isConnecting, setIsConnecting] = useState(false);

    useEffect(() => {
        if (currentRAD && currentRAD.id !== prevRAD?.id) {
            // console.log('RADHandler prop update', currentRAD);
            // Reset the state with the new rad
            dispatch({ type: 'switchRAD', payload: { ...currentRAD } });
            setPrevRAD(currentRAD);
        }
        else if (!currentRAD && prevRAD) {
            //console.log('RADHandler initial rad', currentRAD);
            // Reset the state with the new rad
            dispatch({ type: 'switchRAD', payload: { ...initialRAD } });
            setPrevRAD(currentRAD);
        }
        else {
            //console.log('RADHandler rad same ', currentRAD);
        }
    }, [currentRAD, prevRAD]);

    // Effect for handling state updates (fire-and-forget to global state)
    useEffect(() => {
        if (isUpdating) {
            //console.log('The RAD state has updated, updating global state', state.roles);
            onRadChange(state);
            setIsUpdating(false);
        }
    }, [state, onRadChange, isUpdating]);

    const _orderActivities = useCallback((activities, prestate_id) => {
        // Recursive helper function to order activities by their state sequence
        function orderNextActivity(prestate_id, remainingActivities, orderedActivities = []) {
            const nextActivityIndex = remainingActivities.findIndex(activity => activity.prestate_id === prestate_id);

            if (nextActivityIndex === -1) {
                // Base case: No next activity found
                return orderedActivities;
            }

            const nextActivity = remainingActivities[nextActivityIndex];
            const newRemaining = remainingActivities.filter((_, index) => index !== nextActivityIndex);
            const newOrdered = [...orderedActivities, {...nextActivity, index: orderedActivities.length}];

            // Recursive call with the postState of the current activity as the new preState
            return orderNextActivity(nextActivity.poststate_id, newRemaining, newOrdered);
        }

        return orderNextActivity(prestate_id, activities);
    }, []);

    const getDetails = useMemo(() => {

        const getRADName = () => state.label;
        const getRADEditable = () => {
            return {
                label: state.label,
                principal_process_id: state.principal_process_id,
                process_id: state?.process_id,
                viewpoint: state.viewpoint,
                description: state.description,
            };
        }

        const getRole = (id) => {
            const role = state.roles.find(r => r.id === id);
            return role;
        };

        const getThread = (id) => {
            const thread = state.activityThreads.find(t => t.id === id);
            return thread;
        };
        const getActivity = (id) => {
            const activity = state.activities.find(a => a.id === id);
            return activity;
        };

        const getRoles = () => state.roles;
        const getThreads = () => state.activityThreads;
        const getStates = () => state.states;
        const getActivities = () => state.activities;
        const getCaseRefinements = () => state.caseRefinements;
        const getPartRefinements = () => state.partRefinements;
        const getPartRepeats = () => state.partRepeats;

        const getRoleThreads = (role_id) => state.activityThreads.filter(thread => thread.role_id === role_id);
        const getThreadActivities = (thread_id) => state.activities.filter(activity => activity.thread_id === thread_id);
        const getThreadCaseRefinements = (thread_id) => state.caseRefinements.filter(refinement => refinement.thread_id === thread_id);
        const getThreadPartRefinements = (thread_id) => state.partRefinements.filter(refinement => refinement.thread_id === thread_id);
        const getThreadPartRepeats = (thread_id) => state.partRepeats.filter(refinement => refinement.thread_id === thread_id);
        const getCaseConditions = (id) => state.caseConditions.filter(c => c.case_refinement_id === id);
        const getPartThreads = (id) => state.partThreads.filter(t => t.part_refinement_id === id);

        const getPartRepeatThread = (id) => state.partThreads.find(t => t.part_repeat_id === id);

        const getSubthreads = (classname, id) => {
            if (classname === 'case-refinement') {
                return getCaseConditions(id);
            }
            if (classname === 'part-refinement') {
                return getPartThreads(id);
            }
            if (classname === 'part-repeat') {
                return [getPartRepeatThread(id)];
            }
            return null;
        };
        const getThreadSequence = (thread_id, prestate_id) => {
            // For a given thread id, return the sequence of activities
            // as an ordered array

            const mergedArray = [
                ...getThreadActivities(thread_id),
                ...getThreadCaseRefinements(thread_id),
                ...getThreadPartRefinements(thread_id),
                ...getThreadPartRepeats(thread_id)
            ];

            //console.log('mergedArray', mergedArray);

            const activities = mergedArray
                .map(a => ({
                    activity_id: a.id,
                    class: a.class,
                    thread_id: a.thread_id,
                    prestate_id: a.prestate_id,
                    poststate_id: a.poststate_id,
                    label: a.label,
                }));

            const orderedActivities = _orderActivities(activities, prestate_id);
            return orderedActivities;
        };
        const getThreadStates = (thread_id) => {
            // For a given thread id , get all of the states represented in its activities
            // This removes the need for having thread and rol id in the (rad)state object

            const activities = getThreadActivities(thread_id);
            const states = activities.map(a => a.prestate_id);
            const poststates = activities.map(a => a.poststate_id);
            const allStates = [...new Set([...states, ...poststates])];
            return state.states.filter(s => allStates.includes(s.id));
        };

        const getState = (id) => {
            const radstate = state.states.find(s => s.id === id);
            return radstate;
        };

        const getThreadPrestate = (thread) => {
            return getState(thread.prestate_id);
        };

        const getCaseRefinement = (id) => {
            const refinement = state.caseRefinements.find(r => r.id === id);
            return refinement;
        };

        const getPartRefinement = (id) => {
            const refinement = state.partRefinements.find(r => r.id === id);
            return refinement;
        };

        const getPartRepeat = (id) => {
            const repeat = state.partRepeats.find(r => r.id === id);
            return repeat;
        };

        const getCaseCondition = (id) => {
            const condition = state.caseConditions.find(c => c.id === id);
            return condition;
        };

        const getPartThread = (id) => {
            const thread = state.partThreads.find(t => t.id === id);
            return thread;
        };

        const getConnection = (id) => {
            const connection = state.connections.find(c => c.id === id);
            return connection;
        };

        const getActivityByPrestate = (thread_id, prestate_id) => {
            const activity = state.activities.find(a => a.prestate_id === prestate_id && a.thread_id === thread_id);
            return activity;
        };

        const getActivityByPoststate = (thread_id, poststate_id) => {
            const activity = state.activities.find(a => a.poststate_id === poststate_id && a.thread_id === thread_id);
            return activity;
        };

        const getTerminalState = (thread) => {
            const activities = state.activities.filter(a => a.thread_id === thread.id);
            const orderedActivities = _orderActivities(activities, thread.prestate_id);
            return orderedActivities[orderedActivities.length - 1].poststate_id;
        };

        const getConnections = () => state.connections;

        const getConnectionTargets = (source_id) => {
            // For a given interaction id, return the ids of the interactions that can be connected to it.
            // Rules for a valid target are:
            // 1. The target is also an interaction
            // 2. The target is not the source
            // 3. The target is not already connected to the source
            // 4. The target is not in the same role as the source
            // 5. At most only one interaction per role can be connected to the source.
            // All current connections are in the rad connections array
            // Connections are designated as {id, source_activity_id, target_activity_id} objects
            // The ordering of the source ad target is not significant

            if (!source_id) {
                return [];
            }
            const source = getActivity(source_id);
            const currentConnection = state.connections.find(con => con.interactions.some(id => id === source_id));

            const connectedRoles = currentConnection
                ? currentConnection.interactions.map(c => {
                    const activity = getActivity(c);
                    return activity.role_id;
                })
                : [];

            console.log('connectedRoles', connectedRoles);
            console.log('currentConnection', currentConnection);

            console.log('rule4', state.activities.filter(a => a.role_id !== source.role_id))
            console.log('rule5', state.activities.filter(a => !connectedRoles.includes(a.role_id)))

            const targets = state.activities
                .filter(a => a.class === 'activity interaction' && a.id !== source_id) // Rule 1 & 2
                .filter(a => !currentConnection?.interactions.includes(a.id)) // Rule 3
                .filter(a => a.role_id !== source.role_id) // Rule 4
                .filter(a => !connectedRoles.includes(a.role_id)) // Rule 5
                .map(a => a.id);
            console.log('getConnectionTargets', source, targets);
            return targets;
        };

        const orderActivities = (activities, prestate_id) => {
            return _orderActivities(activities, prestate_id);
        };

        return {
            radID: state.id,
            version: state.version,
            isConnecting,
            getRADName,
            getRADEditable,
            getRoles,
            getThreads,
            getStates,
            getActivities,
            getCaseRefinements,
            getPartRefinements,
            getPartRepeats,
            getConnections,
            getRoleThreads,
            getThreadActivities,
            getThreadCaseRefinements,
            getThreadPartRefinements,
            getThreadPartRepeats,
            getCaseConditions,
            getPartThreads,
            getPartRepeatThread,
            getSubthreads,
            getThreadStates,
            getThreadSequence,

            getActivity,
            getRole,
            getThread,
            getState,
            getCaseRefinement,
            getPartRefinement,
            getPartRepeat,
            getCaseCondition,
            getPartThread,
            getConnection,
            getActivityByPrestate,
            getActivityByPoststate,
            getTerminalState,
            getThreadPrestate,
            getConnectionTargets,
            orderActivities
        }
    }, [state, isConnecting, _orderActivities,]);

    const setDetails = useMemo(() => {
        const { getConnections, getActivityByPrestate,
            getActivityByPoststate, getTerminalState } = getDetails;

        // These methods encapsulate the RAD's logic operations and state mutations.
        const addRole = ({ position }) => {
            const rectWidth = 200;
            const rectHeight = 320;

            const id = nanoid();
            const role = {
                class: 'role', label: "new role", id, x: position.x, y: position.y, width: rectWidth, height: rectHeight
            };
            handleChange({ type: 'addRole', payload: role });
        };

        const addConnection = (source_id, target_id) => {
            const newConnection = {
                interactions: [source_id, target_id],
            }
            handleChange({ type: 'addConnection', payload: newConnection });
        };

        const removeRole = (role) => {
            // Remove the role and all its threads and activities
            handleChange({ type: 'deleteRole', payload: role.id });
        }

        const addState = () => {
            // Add a new state 
            const id = nanoid();
            const newState = {
                id,
                class: 'state',
                label: 'Unnamed State'
            };
            handleChange({ type: 'addState', payload: newState });
            return newState.id;
        }

        const addThread = ({ role_id, position }) => {
            // Add a new thread
            // We care about position as it is user manageable.
            // Note that the thread is not added to the RAD context here but in the addActivity function
            const id = nanoid();

            const newThread = {
                id,
                role_id,
                class: 'thread',
                x: position.x,
                y: position.y,
                prestate_id: null,
                //endState: null,
            };
            console.log('new thread initialised', newThread);
            return newThread;
        }

        const addRefinement = ({ activity, shape_class, thread }) => {
            // Add a refinement to the activity.
            // Refinements prestate is the activity's poststate
            // Refinements don't have poststates as such, but we add one as a holding place for the next activity
            // If thre is another activty after the refinement, we update its prestate to the new poststate.
            // This might not be what the user wants ultimately, but we have to guess at this point.
            const id = nanoid();
            const newPoststate_id = addState();
            const postActivity = getActivityByPrestate(thread.id, activity.poststate_id);

            const newRefinement = {
                id,
                class: `${shape_class}`,
                thread_id: activity.thread_id,
                role_id: activity.role_id,
                prestate_id: activity.poststate_id,
                poststate_id: newPoststate_id,
                ...otherProps(shape_class)
            };
            console.log('new refinement', newRefinement);
            const reducerType =
                shape_class === 'case-refinement' ? 'addCaseRefinement' :
                    shape_class === 'part-refinement' ? 'addPartRefinement' :
                        shape_class === 'part-repeat' ? 'addPartRepeat' : 'addRefinement';
            handleChange({ type: reducerType, payload: newRefinement });
            if (postActivity) {
                const updatedActivity = { ...postActivity, prestate_id: newPoststate_id };
                handleChange({ type: 'updateActivity', payload: updatedActivity });
            }
        }

        const addPartThread = ({ partRefinement }) => {
            // Add a new part thread to the part refinement
            handleChange({ type: 'addPartThread', payload: partRefinement });
        }

        const insertPartThread = ({ sibling }) => {
            handleChange({ type: 'insertPartThread', payload: sibling });
        }

        const addCaseCondition = ({ caseRefinement }) => {
            // Add a new case condition to the case refinement
            handleChange({ type: 'addCaseCondition', payload: caseRefinement });
        }

        const insertCaseCondition = ({ sibling }) => {
            handleChange({ type: 'insertCaseCondition', payload: sibling });
        }

        const createNewActivity = (type, prestate_id, poststate_id, thread) => {
            const id = nanoid();
            const newActivity = {
                id,
                class: `activity ${type}`,
                thread_id: thread.id,
                role_id: thread.role_id,
                prestate_id,
                poststate_id,
                ...otherProps(type) // Handle type-specific properties
            };
            console.log('new activity', newActivity);
            return newActivity;
        }

        const addActivityType = (type, thread, acquire_poststate_id) => {

            if (acquire_poststate_id === null) {
                // insert new activity under the open state
                // activity's prestate is the open state
                // activity's poststate is new, and the next activity's prestate
                const newPoststate = addState();
                const next = getActivityByPrestate(thread.id, thread.prestate_id);
                const updatedActivity = { ...next, prestate_id: newPoststate };
                const newActivity = { ...createNewActivity(type, thread.prestate_id, newPoststate, thread) };
                console.log('open new activity', newActivity);
                console.log('open updated activity', updatedActivity);

                handleChange({ type: 'addActivity', payload: [newActivity, updatedActivity] });

            }
            else {
                // insert new activity.
                //
                const newPrestate = addState();
                const prior = getActivityByPoststate(thread.id,  acquire_poststate_id);
                console.log('prior activity', acquire_poststate_id, prior);
                const updatedActivity = { ...prior, poststate_id: newPrestate };
                const newActivity = { ...createNewActivity(type, newPrestate, acquire_poststate_id, thread) };
                console.log('new activity', newActivity);
                console.log('updated activity', updatedActivity);
                handleChange({ type: 'addActivity', payload: [newActivity, updatedActivity] });

            }

            return;
        };

        const otherProps = (type) => {
            // Customize additional properties based on the activity type
            switch (type) {
                case 'interaction':
                    return { label: 'New Interaction' };
                case 'action':
                    return { label: 'New Action' };
                // Add cases for other types as necessary
                case 'start-role':
                    return { label: 'Start Role' };
                case 'trigger':
                    return { label: 'New Trigger' };
                case 'case-refinement':
                    return {
                        label: '<question>',
                    };
                case 'part-refinement':
                    return {
                        label: 'New Part Refinement',
                    };
                /*                     case 'part-repeat':
                                        return { label: 'New Part Repeat' }; */
                default:
                    return {};
            }
        };

        function addOpeningActivity({ thread, shape_class }) {
            const activityType = shape_class.replace('activity ', '');
            // Guards
            if (thread === null) {
                return;
            }
            // End of guards

            const poststate_id = addState();
            const prestate_id = addState();
            const readyThread = { ...thread, prestate_id }; //, endState: poststate_id };
            handleChange({ type: 'addThread', payload: readyThread });
            const newActivity = createNewActivity(activityType, prestate_id, poststate_id, readyThread);
            handleChange({ type: 'addActivity', payload: [newActivity, null] });
        }

        function addOpeningActivityToSubthread({ subthread, shape_class }) {
            // Add an initial activity to a subthread
            // There may or may not be activities in the subthread already, if so the new activity is inserted before the first activity
            const activityType = shape_class.replace('activity ', '');
            // Guards
            if (subthread === null) {
                return;
            }
            // End of guards
            const { prestate_id, poststate_id } = subthread;
            console.log('adding opening activitity to subthread', subthread);
            const existingActivity = getActivityByPrestate(subthread.id, prestate_id);
            console.log('existing activity', existingActivity);
            if (!existingActivity) {
                console.log('no existing activity', prestate_id);
                const newActivity = createNewActivity(activityType, prestate_id, poststate_id, subthread);
                handleChange({ type: 'addActivityToSubthread', payload: [newActivity, null] });
            }
            else {
                addActivityType(activityType, subthread, null);
            }
        }

        function addTerminalActivity({ thread, shape_class }) {
            const activityType = shape_class.replace('activity ', '');
            const poststate_id = getTerminalState(thread);
            addActivityType(activityType, thread, poststate_id);
        }

        function addActivityToState({ thread, shape_class, thread_state }) {
            const activityType = shape_class.replace('activity ', '');
            if (thread_state === thread.prestate_id) {
                addActivityType(activityType, thread, null);
                return;
            }
            else {
                // Otherwise, insert the activity with the state as post-state and a new pre-state
                // and update the prior activity's post-state to the new pre-state
                const poststate_id = thread_state;
                addActivityType(activityType, thread, poststate_id);
                return;
            }

        }

        function addActivityToActivity({ thread, shape_class, thread_state }) {
            console.log('addActivityToActivity', thread, shape_class, thread_state);
            const activityType = shape_class.replace('activity ', '');
            const poststate_id = thread_state;
            addActivityType(activityType, thread, poststate_id);
            return;

        }

        const contextVet = (action) => {
            // Validate principal actions before dispatching
            if (action.type === 'addConnection') {
                // payload is an array of two activity ids
                const interactionPair = action.payload.interactions;

                // Check if the connection already exists, that is if both activities are already connected
                const connectionExists = getConnections()
                    .some(connection => (connection.interactions
                        .includes(interactionPair[0]) && connection.interactions.includes(interactionPair[1]))
                    );
                if (connectionExists) {
                    console.log('Connection already exists', action.payload);
                    return undefined;
                }
                // check both interaction activities exist. sequence of activities is not important despite the naming.
                const sourceActivity = state.activities.find(activity => activity.id === interactionPair[0]);
                const targetActivity = state.activities.find(activity => activity.id === interactionPair[1]);
                if (!sourceActivity || !targetActivity) {
                    console.log('Invalid connection activities', action.payload);
                    return undefined;
                }
                // add an id and class name to the payload
                const newAction = { ...action, payload: { id: nanoid(), class: 'connection', interactions: interactionPair } };
                return newAction; // it checks out
            }
            if (action.type === 'deleteConnection') {
                // payload is an id
                console.log('delete a connection', action.payload);
                const connectionExists = state.connections.some(connection => connection.id === action.payload.id);
                if (!connectionExists) {
                    console.log('Connection does not exist', action.payload);
                    return undefined;
                }
                return action; // it checks out
            }
            if (action.type === 'deleteConnectionSection') {
                // User wishes to delete a section of a connection to multiple interactions
                // Payload contains the selected component and the selected node that identifies the section
                if (!action.payload.selectedNode || !action.payload.selectedComponent) {
                    console.log('Invalid connection section deletion', action.payload);
                    return undefined;
                }
                if (action.payload.selectedComponent.id !== action.payload.selectedNode.connection_id) {
                    console.log('Mismatched ids, htf did that happen', action.payload);
                    return undefined;
                }
                const connectionExists = state.connections.some(connection => connection.id === action.payload.selectedComponent.id);
                if (!connectionExists) {
                    console.log('Connection does not exist', action.payload);
                    return undefined;
                }
                const { from, to } = action.payload.selectedNode;
                // create a new action with the id and the from to pair
                // should maybe check the from to are valid as well
                const newAction = { type: action.type, payload: { id: action.payload.selectedComponent.id, from, to } };

                return newAction; // it checks out
            }
            if (action.type === 'deleteComponent') {
                // User wishes to delete a component
                // Payload contains the selected component
                const deleteTypes = {
                    'role': 'deleteRole',
                    'thread': 'deleteThread',
                    'state': 'deleteState',
                    'case-refinement': 'deleteCaseRefinement',
                    'part-refinement': 'deletePartRefinement',
                    'part-repeat': 'deletePartRepeat',
                    'part-repeat-thread': 'deletePartRepeat',
                    'case-condition': 'deleteCaseCondition',
                    'part-thread': 'deletePartThread',
                    'activity action': 'deleteActivity',
                    'activity interaction': 'deleteActivity',
                    'activity start-role': 'deleteActivity',
                    'activity trigger': 'deleteActivity',
                    'activity ellipsis': 'deleteActivity',
                    'activity': 'deleteActivity'
                };

                if (!action.payload) {
                    console.log('Invalid component deletion', action.payload);
                    return undefined;
                }

                const deleteType = deleteTypes[action.payload.class];
                console.log('deleteComponent', action.payload, deleteType);

                if (!deleteType) {
                    console.log('Invalid component class', action.payload);
                    return undefined;
                }
                const newAction =
                    action.payload.class === 'part-repeat-thread'
                        //fiddle part-repeat-thread till I think of something better
                        ? { type: deleteType, payload: { id: action.payload.part_repeat_id } }
                        : { type: deleteType, payload: { id: action.payload.id } };
                setSelectedComponent(null);
                return newAction; // it checks out
            }
            return action; // assume those things we don't check are good to go.
        };

        const handleChange = (action) => {
            const vettedAction = contextVet(action);
            if (vettedAction) {
                console.log('dispatching vetted action', vettedAction);
                dispatch(vettedAction);
                setIsUpdating(true);
            }
            else {
                console.log('Invalid action', action);
            }
        };

        return {
            handleChange,
            setSelectedComponent,
            setIsConnecting,
            addRole,
            addConnection,
            removeRole,
            addState,
            addThread,
            addRefinement,
            addPartThread,
            insertPartThread,
            addCaseCondition,
            insertCaseCondition,
            addOpeningActivity,
            addTerminalActivity,
            addActivityToState,
            addActivityToActivity,
            addOpeningActivityToSubthread,
        }
    }, [state, getDetails]);

    useEffect(() => {
        console.log('RADHandlerLogic updated');

    }, [state]);

    const getSelection = useMemo(() => {
        return {
            selectedComponent,
        }
    }, [selectedComponent]);

    return (
        <RADSelectionContext.Provider value={getSelection}>
            <RADContext.Provider value={getDetails}>
                <RADDispatchContext.Provider value={setDetails}>
                    {children}
                </RADDispatchContext.Provider>
            </RADContext.Provider>
        </RADSelectionContext.Provider>
    );
};

export default RADHandlerLogic;

export const useRADDispatch = () => {
    const context = useContext(RADDispatchContext);
    if (!context) {
        throw new Error('useRADDispatch must be used within a RADHandler Provider');
    }
    return context;
};

export const useRAD = () => {
    const context = useContext(RADContext);
    if (!context) {
        throw new Error('useRAD must be used within a RADHandler Provider');
    }
    return context;
};

export const useRADSelection = () => {
    const context = useContext(RADSelectionContext);
    if (!context) {
        throw new Error('useRADSelection must be used within a RADHandler Provider');
    }
    return context;
}