import React, { useState, createContext, useContext, useMemo } from 'react';
import { ModelContext } from '../Model/ModelProvider';

export const GeneratorContext = createContext();

const GeneratorHandlerLogic = ({ children }) => {
    const { getGenerators, setIsChanged, updateModel, getUoWEBEs } = useContext(ModelContext);
    const generatorList = getGenerators();  // Get the list of Generators from the model

    const [selectedGenerator, setSelectedGenerator] = useState(null);

    const commitChanges = (newGenList) => {
        updateModel(
            "UPDATE_MODEL_PART",
            { partKey: "gens", value: newGenList },
            () => {
                setIsChanged(true); // 
            }
        );
    };

    // Derive the unique list of used sources
    const usedSources = useMemo(() => {
        const sourceSet = new Set(generatorList.map(gen => gen.source));
        return Array.from(sourceSet);
    }, [generatorList]);

    // Derive the list of available targets
    const getAvailableTargets = (sourceId) => {
        if (!sourceId) return [];
        const takenTargets = generatorList.filter(gen => gen.source === sourceId).map(gen => gen.target);
        const avail = getUoWEBEs().filter(ebe => !takenTargets.includes(ebe.id));
        //  console.log('getAvailableTargets', sourceId, avail);
        return avail;
    };

    const saveGen = (gen) => {
        const existingGen = generatorList.find(item => item.id === gen.id);

        const updatedGens = existingGen
            ? generatorList.map(item => item.id === gen.id ? gen : item) // Update existing Generator
            : [...generatorList, gen]; // Add new Generator
        commitChanges(updatedGens);
        setSelectedGenerator(null);
    };

    const removeGenerator = (gen) => {  // Remove Genrator from list
        const newGenList = generatorList.filter(item => item.id !== gen.id);
        commitChanges(newGenList);
        setSelectedGenerator(null);
    }

    return (
        <GeneratorContext.Provider value={{saveGen,
            generatorList, selectedGenerator, setSelectedGenerator,
            removeGenerator, usedSources, getAvailableTargets
        }}>
            {children}
        </GeneratorContext.Provider>
    );
};

export default GeneratorHandlerLogic;
