import RADManagerLogic from './RADManagerLogic';
import RADManagerUI from './RADManagerUI';

const RADManager = () => {
    return (
        <RADManagerLogic>
            <RADManagerUI />
        </RADManagerLogic>
    );
};

export default RADManager;