import React, { createContext, useEffect } from 'react';
import useLocalStorage from 'use-local-storage'

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    const [theme, setTheme] = useLocalStorage('theme', defaultDark ? 'dark' : 'light'); // Default theme

    // Update data-theme attribute on the root element
    useEffect(() => {
        document.documentElement.setAttribute('data-theme', theme);
    }, [theme]);

    return (
        <ThemeContext.Provider value={{ theme, setTheme }}>
            {children}
        </ThemeContext.Provider>
    )
}