// Providers.js

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ModelProvider } from './features/Model/ModelProvider';
import { ThemeProvider } from './features/UI/ThemeContext';

const Providers = ({ children }) => {
    return (
        <ThemeProvider>
            <BrowserRouter>
                <ModelProvider>
                    {children}
                </ModelProvider>
            </BrowserRouter>
        </ThemeProvider>
    );
};

export default Providers;