import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ModelContext } from '../Model/ModelProvider';
import { GiSunflower } from "react-icons/gi";
//style
import '../../css/RAD.css';

export const RADThumbnail = ({ rad, onClick }) => {
    return (
        <div className="rad-thumbnail" onClick={() => onClick(rad.id)}>
            <p>{rad.label}</p>
            {/* Additional details or visual representation of the RAD can go here */}
        </div>
    );
};

const RADPicker = () => {
    const { getRADs } = useContext(ModelContext);
    const navigate = useNavigate(); // Use this to navigate to the RAD page

    const [isCollapsed, setIsCollapsed] = useState(false);
    const radItems = getRADs().map(item => ({
        id: item.id,
        process_id: item.process_id ? item.process_id : 'Unassigned',
        label: item.label,
        status: item.status
    }));

    const handleRADClick = (radId) => {
        console.log("RAD Clicked:", radId);
        navigate(`/rad/${radId}`); // Navigate to RADManager with radId
    };

    return (
        <div className={`rad-carousel ${isCollapsed ? 'carousel-collapsed' : ''}`}
            onClick={() => setIsCollapsed(!isCollapsed)}>
            {isCollapsed
                ? <span className="expand-icon">&#x25C4; </span>
                : <div className="text-content">
                    <h3> RAD picker 
                        <GiSunflower className="rad-icon" />
                    </h3>
                    {radItems.map(rad => (
                        <RADThumbnail key={rad.id} rad={rad} onClick={handleRADClick} />
                    ))}
                </div>}
        </div>
    );
};

export default RADPicker;
