// src/features/AppFooter/AppFooter.js
import React from 'react';

// Custom hook to handle external links
function useExternalLink(url) {
    return () => window.open(url, '_blank', 'noopener,noreferrer');
}

function AppFooter() {
    const openGGSeal = useExternalLink("https://my.greengeeks.com/seal/");

    return (
        <footer className="footer">
            <div className="centre-content">
                <div className="centre-text">Composed Software Ltd.</div>
                {/* <img src="path-to-your-other-image.jpg" alt="Other Image" className="footer-text-image" /> */}
            </div>
            <div className="footer-logo">
                <button onClick={openGGSeal} style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}>
                    <img src="https://static.greengeeks.com/ggseal/Green_4.png"
                        alt="GreenGeeks Seal"
                        style={{ width: '50%', height: 'auto' }} />
                </button>
            </div>
        </footer>
    );
}

export default AppFooter;
