export const generateSchema = (nodes, edges) => {
    if (!nodes || !edges) return [];

    // Generate schema for nodes
    const nodeSchema = nodes.flatMap(node => {
        const { name, id } = node;

        // Create CMP and CP nodes
        const cmp = { class: 'node', uow: id, id: `${id}_cmp`, label: `Manage the flow of ${name}s`, type: 'CMP', status: 'live' };
        const cp = { class: 'node', uow: id, id: `${id}_cp`, label: `Handle a ${name}`, type: 'CP', status: 'live' };

        // Generate starts, monitors, and reports relationships for each node
        const starts = { class: 'starts', id: `${id}_cmp_${id}_cp_starts`, source: cmp.id, target: cp.id, label: 'start', tail: 'A', status: 'live' };
        const monitors = { class: 'monitors', id: `${id}_cmp_${id}_cp_monitors`, source: cmp.id, target: cp.id, label: 'monitor/intervene/stop', tail: 'I', status: 'live' };
        const reports = { class: 'reports', id: `${id}_cp_${id}_cmp_reports`, source: cp.id, target: cmp.id, label: 'report', tail: 'I', status: 'live' };

        return [cmp, cp, starts, monitors, reports];
    });

    // Generate schema for edges
    const edgeSchema = edges.flatMap(edge => {
        const { source, target, processType, id: genId } = edge;
        const baseEdge = { source, target, genId, status: 'live' };

        if (source === 'External') {
            return [
                { ...baseEdge, class: 'node', uow: 'External', id: `${source}_${target}`, label: '', type: 'External' },
                {
                    ...baseEdge, class: 'external_requests', id: `${source}_${target}_${target}_cmp_external_requests`,
                    source: `${source}_${target}`,
                    target: `${target}_cmp`, label: 'request'
                }
            ];
        }

        const tail = processType === "Service" ? '"I"' : (processType === 'Task Force' ? '"E"' : '"1:1"');

        // Generate edge schema based on the processType
        return [
            { ...baseEdge, class: 'negotiates', id: `${target}_cmp_${source}_cp_negotiates`, source: `${target}_cmp`, target: `${source}_cp`, label: 'negotiate', tail: tail },
            {
                ...baseEdge, class: 'delivers', id: `${target}_cp_${source}_cp_delivers`, source: `${target}_cp`,
                target: `${source}_cp`, label: 'deliver', tail: 'I'
            },
            {
                ...baseEdge, class: 'request', id: `${source}_cp_${target}_cmp_requests`, source: `${source}_cp`,
                target: `${target}_cmp`, label: 'request', tail: 'I'
            }
        ];
    });

    return [...nodeSchema, ...edgeSchema];
};

const compare = (a, b) => {
    if (a.id < b.id) { return -1; }
    if (a.id > b.id) { return 1; }
    return 0;
};

export const mergeSchema = (schema, editableSchema) => {
    // Assuming schema and editableSchema are arrays
    const transactions = [...editableSchema].sort(compare);
    const newSchema = schema.map(s => {
        const transaction = transactions.find(t => t.id === s.id);
        if (transaction) {
            return { ...s, label: transaction.label };
        } else {
            return s;
        }
    });

    return newSchema;
};

export default mergeSchema;
