import React, { useContext, useRef,  } from "react";
import { useOutsideEvent } from "../hooks/useOutsideEvent";
import { useNavigate } from 'react-router-dom';
import { ModelContext } from '../Model/ModelProvider';
import { GiSunflower } from "react-icons/gi";

//styles
import '../../css/Themes.css';

const RADSelector = () => {
    const { getRADs } = useContext(ModelContext);
    const navigate = useNavigate(); // Use this to navigate to the RAD page

    const radRef = useRef(null);
    const [dropdown, setDropdown] = useOutsideEvent(radRef, false);

    const toggleDropdown = () => {
        setDropdown(!dropdown);
    };

    const rads = getRADs();

    return (
        <div className="menu-item" ref={radRef}>
            <button className='csbutton' onClick={toggleDropdown}
                aria-label='Select a RAD'>
                    Select
                {<GiSunflower className="rad-icon" />}
            </button>
            <ul className={`dropdown ${dropdown ? "show" : ""}`}>
                {rads.map(rad => (
                    <li key={rad.id}>
                        <button onClick={() => navigate(`/rad/${rad.id}`)}>
                            {rad.label}
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default RADSelector;