import React, { useRef, useEffect, useState } from 'react';
//import { useMemo } from 'react'; 
import * as d3 from 'd3';
import { useZoom, useDragBehavior } from './dragZoomBehaviour';
import { useContextCommands } from './contextCommands';
import { useDropLogic } from './dropBehaviour';
import { IoIosUndo, IoIosRedo } from "react-icons/io";

import useContextMenu from '../../hooks/useContextMenu';

import ZoomControls from './ZoomControls';
import { useRADSelection, useRADDispatch, useRAD } from './RADHandlerLogic';
import { useDrop } from '../DropContext';
import RADContextMenu from './RADContextMenu';
import {
    drawRoleGroups, drawThreadGroups, drawActivityGroups, drawStateLines,
    drawCaseRefinementGroups, drawPartRefinementGroups,
    drawPartRepeatGroups,
    drawInteractionLines
} from './ShapeLibrary/DrawShapes';
import { useLayoutLogic } from './expanses';

const RADDiagram = () => {
    // Contexts
    const { selectedComponent } = useRADSelection();
    const { radID, version, isConnecting, 
        getRADName,
        getRoles, getThreads,
        getRoleThreads, getThreadActivities, getThreadCaseRefinements,
        getThreadPartRefinements, getThreadPartRepeats,
        getThreadStates, getCaseConditions, getPartThreads,
        getPartRepeatThread,
        getConnectionTargets, getConnections,
        getRole, getThread, getActivity, getCaseRefinement, getPartRefinement, getPartRepeat,
        getCaseCondition, getPartThread, getConnection, getState } = useRAD();
    const { handleChange, setSelectedComponent, setIsConnecting, addConnection } = useRADDispatch();
    const { draggedShape, setDraggedShape } = useDrop();

    const viewBoxWidth = 1000;
    const viewBoxHeight = 1000;

    const diagramRef = useRef(null);
    const contextRef = useRef(null);
    const inset = { width: 150, height: 250 }

    // State
    const [position, isShowContext] = useContextMenu(contextRef, inset);
    const [svgPosition, setSvgPosition] = useState({ x: 0, y: 0 });
    const [selectedNode, setSelectedNode] = useState({});

    const [expanses, setExpanses] = useState([]);


    const { zoomIn, zoomOut, resetZoom, zoomToFit, zoomBehavior, setScale, currentScale } = useZoom(diagramRef, viewBoxWidth, viewBoxHeight);
    const { addDroppedItem } = useDropLogic(diagramRef, draggedShape, viewBoxWidth, viewBoxHeight);

    const { handleAddRole, handleRemoveRole, handleNewStartRole,
        handleNewInteraction, handleNewAction,
        handleNewTrigger, handleNewCaseRefinement, handleNewPartRefinement, handleNewPartRepeat,
        handleNewEllipsis, handleAddPartThread, handleAddCaseCondition, handleInsertPartThread, handleInsertCondition
    } = useContextCommands(selectedComponent, handleChange);

    const { computeExpanses } = useLayoutLogic()
    const { dragBehavior, dragStateBehaviour } = useDragBehavior(handleChange);

    const connectionStart = useRef(null);
    const isConnectingRef = useRef(isConnecting);

    useEffect(() => {
        isConnectingRef.current = isConnecting;
    }, [isConnecting]);

    // Create the SVG element
    useEffect(() => {
        //console.log('create svg');
        if (diagramRef.current && !diagramRef.current.querySelector('svg')) {
            const diagramSvg = d3.select(diagramRef.current)
                .append('svg')
                .attr('id', 'rad-svg')
                .attr("viewBox", `0 0 ${viewBoxWidth} ${viewBoxHeight}`)
                .on('click', () => { setSelectedNode({}) })
                .node() // Get the DOM node
            // Add a group to hold the diagram elements
            d3.select(diagramSvg).append('g')
                .attr('id', 'rad-diagram')
                .attr("cursor", "grab")
                .attr("pointer-events", "all")
        }
    }, []);

    useEffect(() => {
        /* const flatRADTree = buildFlatRADTree();
        console.log('flatRADTree', flatRADTree); */

        const newExpanses = computeExpanses();
        console.log('newExpanses', newExpanses);
        setExpanses(prevState => ({
            ...prevState,
            ...newExpanses
        }));
    }, [radID, version, computeExpanses]);

    useEffect(() => {
        console.log('useEffect for expanses changed', expanses);
        const clicked = function (event, d) {
            console.log('clicked', d);
            if (!d || !d.id || event.defaultPrevented) return;
            event.stopPropagation();
            if (isConnectingRef.current) {
                const connectionTargets = getConnectionTargets(connectionStart.current);
                if (connectionTargets.includes(d.id)) {
                    console.log('Valid connection target', connectionStart.current, d.id)
                    addConnection(connectionStart.current, d.id);
                } else {
                    console.log('Not a valid connection target');
                }
                setIsConnecting(false);
                connectionStart.current = null;
            } else {
                setSelectedNode(d);
            }
        };

        // Guards!
        if (expanses.length === 0) return;
        if (!diagramRef.current) return;

        const svg = d3.select(diagramRef.current).select('svg');
        const g = svg.select("#rad-diagram");

        const roles = getRoles();
        drawRoleGroups(g, roles, { x: 0, y: 0 }, expanses, dragBehavior(), clicked);

        // For each role, draw the activity threads
        roles.forEach(role => {
            const roleThreads = getRoleThreads(role.id);
            const roleGroup = g.select(`g.role-group[data-id="${role.id}"]`);
            drawThreadGroups(roleGroup, roleThreads, { x: 0, y: 0 }, expanses, dragBehavior(), clicked);
        });

        // For each interaction, draw the connection lines.
        // Drawing sequence matters for d3 so do this here so they are obove
        // roles and threads but beneath actvitites and states.
        const connections = getConnections();
        drawInteractionLines(g, connections, expanses, clicked);

        // For each activity thread, draw the activities, case refinements and part refinements
        const threads = getThreads();
        threads.forEach(thread => {
            const threadGroup = g.select(`g.thread-group[data-id="${thread.id}"]`);
            const activities = getThreadActivities(thread.id);
            drawActivityGroups(threadGroup, activities, { x: 0, y: 0 }, expanses, null, clicked);

            const caseRefinements = getThreadCaseRefinements(thread.id)
            // for each case refinement, append the case conditions array
            const caseRefsWithConditions = caseRefinements.map(caseRefinement => {
                const conditions = getCaseConditions(caseRefinement.id);
                return { ...caseRefinement, conditions };
            });
            drawCaseRefinementGroups(threadGroup, caseRefsWithConditions, { x: 0, y: 0 }, expanses, null, clicked);

            const partRefinements = getThreadPartRefinements(thread.id);
            const partRefsWithParts = partRefinements.map(partRefinement => {
                const partThreads = getPartThreads(partRefinement.id);
                return { ...partRefinement, partThreads };
            });
            //console.log('partRefsWithParts', partRefsWithParts);
            drawPartRefinementGroups(threadGroup, partRefsWithParts, { x: 0, y: 0 }, expanses, null, clicked);

            const partRepeats = getThreadPartRepeats(thread.id);
            const partRepeatsWithSubthread = partRepeats.map(partRepeat => {
                const partThread = getPartRepeatThread(partRepeat.id);
                return { ...partRepeat, partThread };
            });
            //console.log('partRepeatsWithSubthread', partRepeatsWithSubthread);
            drawPartRepeatGroups(threadGroup, partRepeatsWithSubthread, { x: 0, y: 0 }, expanses, null, clicked);

            // For each thread, draw the states
            const states = getThreadStates(thread.id);
            drawStateLines(threadGroup, thread.prestate_id, activities, states, expanses, clicked, dragStateBehaviour());
        });

    }, [expanses, setIsConnecting, dragBehavior, dragStateBehaviour, addConnection,
        getRoles, getRoleThreads, getThreadActivities, getThreadCaseRefinements,
        getThreadPartRefinements, getThreadPartRepeats, getThreadStates, getCaseConditions,
        getPartThreads, getPartRepeatThread, getConnections, getThreads, getConnectionTargets]);

    useEffect(() => {
        if (diagramRef.current) {
            const svg = d3.select(diagramRef.current).select('svg');
            svg.call(zoomBehavior);
        }
    }, [zoomBehavior]);

    useEffect(() => {
        // Try find a reasonable position for the context menu
        //console.log('position', position);
        const svg = diagramRef.current.querySelector('svg');
        if (!svg) return;
        const pt = svg.createSVGPoint();
        pt.x = position.x - window.scrollX;
        pt.y = position.y - window.scrollY;
        //console.log('pt', pt.x, pt.y);
        const transformedPt = pt.matrixTransform(svg.getScreenCTM().inverse());
        //console.log('transformedPt', transformedPt.x, transformedPt.y);

        //const gElem = svg.querySelector('g');
        //const transformedGPt = pt.matrixTransform(gElem.getScreenCTM().inverse());
        setSvgPosition(transformedPt);
    }, [position])

    /*
    * Diagram rendering and editing
    */

    useEffect(() => {
        // When a shape is dropped, add it to the data structure.
        // This will subsequently trigger a re-render of the diagram in another useEffect.
        if (draggedShape) {
            console.log('addDroppedItem', draggedShape);
            addDroppedItem(draggedShape);
            setDraggedShape(null); // Reset the dragged shape
        }
    }, [draggedShape, setDraggedShape, addDroppedItem]);

    useEffect(() => {
        const highlightGraphElement = (id) => {
            if (!diagramRef.current) return;
            // Clear previous highlights
            d3.select(diagramRef.current).selectAll("*")
                .classed('highlighted', false)
            if (id) {
                // console.log('highlightGraphElement', id);
                // Set the newly selected element class to 'highlighted'. CSS will handle the styling
                const element = d3.select(`[data-id='${id}']`);
                element.classed('highlighted', true);
            }
        };

        // Update the highlight based on the current graphId
        highlightGraphElement(selectedNode.id);
    }, [selectedNode]);


    useEffect(() => {
        const selectComponent = (selectedNode) => {
            selectedNode ?? console.log('selectedNode', selectedNode);
            if (!selectedNode || !selectedNode.class) {
                setSelectedComponent(null);
                return null;
            }
            // Define a mapping between class types and their lookup functions
            const lookupFunctions = {
                'role': getRole,
                'thread': getThread,
                'state': getState,
                'case-refinement': getCaseRefinement,
                'part-refinement': getPartRefinement,
                'part-repeat': getPartRepeat,
                'case-condition': getCaseCondition,
                'part-thread': getPartThread,
                'part-repeat-thread': getPartThread,
            };

            // Retrieve the lookup function based on the selectedNode's class
            const lookupFunction = lookupFunctions[selectedNode.class];

            // If there is a valid lookup function for the class, use it
            if (lookupFunction) {
                //console.log('lookupFunction', lookupFunction, selectedNode.id);
                const selectComponent = lookupFunction(selectedNode.id);
                //console.log('selectComponent result', selectComponent);
                if (selectComponent) {
                    setSelectedComponent(selectComponent);
                    return;
                }
            }
            else if (selectedNode.class.includes('activity')) {
                const selectActivity = getActivity(selectedNode.id);
                if (selectActivity) {
                    setSelectedComponent(selectActivity);
                    return;
                }
            }
            else if (selectedNode.class === 'connection') {
                const selectConnection = getConnection(selectedNode.connection_id);
                if (selectConnection) {
                    setSelectedComponent(selectConnection);
                    return;
                }
            }
            else {
                // If no valid component was found or the class didn't match, set to null
                console.log('No valid component found for selectedNode', selectedNode);
                setSelectedComponent(null);
                return;
            }
        };
        selectComponent(selectedNode);
    }, [selectedNode, setSelectedComponent, getRole, getThread, getState, getCaseRefinement, getPartRefinement, getPartRepeat, getCaseCondition, getPartThread, getActivity, getConnection]);

    const handleUndo = (e) => {
        e.preventDefault();
        console.log('undo');
    }

    const handleRedo = (e) => {
        e.preventDefault();
        console.log('redo');
    }

    const handleCleanUp = (e) => {
        e.preventDefault();
        console.log('cleanup');
        handleChange({ type: 'cleanRAD' });
    }

    const handleNewConnection = (e) => {
        console.log('handleNewConnection', selectedComponent);
        setIsConnecting(true);
        connectionStart.current = selectedComponent.id;
        //console.log('connectionStart', connectionStart.current);
        // Acquire a target
    };

    const handleDeleteThread = (e) => {
        console.log('handleDeleteThread', selectedComponent);
        handleChange({ type: 'deleteThread', payload: { id: selectedComponent.id } });
    };

    const handleDeleteRepeat = (e) => {
        console.log('handleDeleteRepeat', selectedComponent);
        if (selectedComponent.class === 'part-repeat-thread') {
            handleChange({ type: 'deletePartRepeat', payload: { id: selectedComponent.part_repeat_id } });
        }
        else {
            handleChange({ type: 'deletePartRepeat', payload: { id: selectedComponent.id } });
        }
    };

    const handleDeleteConnectionSection = (e) => {
        console.log('handleDeleteConnectionSection', selectedComponent);
        handleChange({ type: 'deleteConnectionSection', payload: { selectedComponent, selectedNode } });
    };

    const handleDeleteConnection = (e) => {
        console.log('handleDeleteConnection', selectedComponent);
        handleChange({ type: 'deleteConnection', payload: { id: selectedComponent.id } });
    };

    return (
        <div id='rad-display'>
            <div className='rad-bar rad-bar-controls'>
                <ul className='button-container'>
                    <li>
                        <button className='csbutton' onClick={(e) => handleCleanUp(e)}>Clean up</button>
                    </li>
                    <li>
                        <button className='csbutton' onClick={(e) => handleUndo(e)}>
                            <IoIosUndo className='menu-symbol' />
                        </button>
                    </li>
                    <li>
                        <button className='csbutton' onClick={(e) => handleRedo(e)}>
                            <IoIosRedo className='menu-symbol' />
                        </button>
                    </li>
                </ul>
                <div className="centre-content">
                    <div className="centre-text">
                        {getRADName()}
                    </div>
                </div>
                <ZoomControls onZoomIn={zoomIn} onZoomOut={zoomOut} onReset={resetZoom} onFit={zoomToFit} setScale={setScale} currentScale={currentScale} />
            </div>
            <p> Current selected item: {selectedComponent?.id}</p>
            <div className='rad-diagram-container' >
                <div ref={contextRef}>
                    <div ref={diagramRef}>
                    </div>
                    {isShowContext &&
                        <RADContextMenu svgPosition={svgPosition}
                            selectedComponent={selectedComponent}
                            handleAddRole={handleAddRole}
                            handleNewStartRole={handleNewStartRole}
                            handleNewInteraction={handleNewInteraction}
                            handleNewAction={handleNewAction}
                            handleNewTrigger={handleNewTrigger}
                            handleNewCaseRefinement={handleNewCaseRefinement}
                            handleNewPartRefinement={handleNewPartRefinement}
                            handleNewPartRepeat={handleNewPartRepeat}
                            handleRemoveRole={handleRemoveRole}
                            handleNewConnection={handleNewConnection}
                            handleDeleteConnection={handleDeleteConnection}
                            handleDeleteConnectionSection={handleDeleteConnectionSection}
                            handleNewEllipsis={handleNewEllipsis}
                            handleDeleteThread={handleDeleteThread}
                            handleDeleteRepeat={handleDeleteRepeat}
                            handleAddPartThread={handleAddPartThread}
                            handleAddCaseCondition={handleAddCaseCondition}
                            handleInsertPartThread={handleInsertPartThread}
                            handleInsertCondition={handleInsertCondition}
                        />
                    }
                </div>
            </div>
        </div>
    );
}

export default React.memo(RADDiagram);