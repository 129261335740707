export const shapes = [
    {
        class: 'role',
        id: 'role',
        type: 'rect',
        width: 30,
        height: 50,
        rx: 5,
        ry: 5,
        label: 'Role'
    },
    {
        class: 'activity trigger',
        id: 'trigger',
        type: 'path',
        width: 20,
        height: 10,
        label: 'Trigger'
    },
    {
        class: 'activity interaction',
        id: 'interaction',
        type: 'rect',
        width: 20,
        height: 20,
        label: 'Interaction'
    },
    {
        class: 'activity action',
        id: 'action',
        type: 'rect',
        width: 20,
        height: 20,
        label: 'Action'
    },
    {
        class: 'activity start-role',
        id: 'start-role',
        type: 'rect',
        width: 20,
        height: 20,
        label: 'Start Role'
    },
    {
        class: 'activity ellipsis',
        id: 'ellipsis',
        type: 'path',
        width: 20,
        height: 20,
        label: '...'
    },
    {
        class: 'case-refinement',
        id: 'case-refinement',
        type: 'polygon',
        width: 30,
        height: 30,
        label: 'Case Refine'
    },
    {
        class: 'part-refinement',
        id: 'part-refinement',
        type: 'polygon',
        width: 30,
        height: 30,
        label: 'Part Refine'
    },
    {
        class: 'part-repeat',
        id: 'part-repeat',
        type: 'polygon',
        width: 30,
        height: 30,
        label: 'Part repeat'
    },
    {
        class: 'state-label',
        id: 'state-label',
        type: 'path',
        width: 10,
        height: 6,
        rx: 10,
        ry: 5,
        label: 'State Label'
    }
];