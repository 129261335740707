const FullScreenButton = ({ fullScreen, toggleScreenMode, element_id }) => {
    return (
        <ul className="button-container">
            <li className="menu-item">
                <button className="csbutton"
                    onClick={() => toggleScreenMode(document.getElementById(element_id))}
                >
                    {fullScreen ? 'Exit Fullscreen' : 'Full Screen'}
                </button>
            </li>
        </ul>
    );
};

export default FullScreenButton;