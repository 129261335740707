import React from 'react'
import backgroundImage from './riva.jpg'; // Ensure you import the image correctly

const Help = () => {
    const backgroundStyle = {
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '100%', // This sets the height to full view height
        color: 'white', // Assuming you want white text for visibility
    };

    const aboutStyle = {
        padding: '20px',
        backgroundColor: 'rgba(0,0,0,0.9)', // Dark background for the text box for readability
        color: 'white',
        borderRadius: '10px', // Optional: for rounded corners
        maxWidth: '600px', // Maximum width of the text box
        textAlign: 'center', // Center the text inside the box
    };
    return (
        <div className='page' >
            <div className='window' style={backgroundStyle}>
                <div style={aboutStyle}>
                    <h2>Riva BPM </h2>
                    <p>Riva BPM is an approach to business process management developed by
                        Martyn A. Ould that is based on the idea that business process architectures
                        are best described as a set of business entities and the dynamic relationships
                        between them.
                        The print book is no longer in print but copies are available from Amazon at
                    </p>
                    <p>
                        <a href="https://www.amazon.co.uk/Pocess-Management-Entity-Relationship-Approach/dp/190612423X">
                            Business Process Management - a rigorous approach
                        </a>
                    </p>
                    <p>
                        Other sellers may/probably have better prices.
                    </p>
                    <p>
                        A full pdf version is available free at
                    </p>
                    <p>
                        <a href="http://www.veniceconsulting.co.uk/BPMARAdistributionfolder/BPMARAroot.htm">
                            Venice Consulting: Business Process Management - a rigorous approach
                        </a>
                    </p>
                    <p>
                        and general resources are also available from Martyn's website at
                    </p>
                    <p>
                        <a href="http://www.veniceconsulting.co.uk/veniceresources.htm">
                            Venice Consulting
                        </a>
                    </p>
                    <p>
                        The Role Activity Diagram (RAD) has been developed by Martyn A. Ould as a
                        way of describing business processes that are inherently concurrent and
                        asynchronous. It has been somewhat overtaken by the BPMN standard but
                        is still a useful tool for describing business processes.
                        Personally I find it easier to use than BPMN, but that is a matter of taste.
                        In general I find notations with a small set of symbols end up being more
                        expressive than those with a large set of symbols (ahem - BPMN).
                    </p>
                    <p>
                        This application is very much a work in progress. Don't expect it to be
                        perfect or even finished. If you find bugs or have suggestions for improvements please
                        contact me on the main contact page.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Help