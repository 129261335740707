import React, {useRef} from 'react';
import { useOutsideEvent } from '../hooks/useOutsideEvent';
import { BsCaretDownFill } from 'react-icons/bs';

//styles
import '../../css/Menus.css';

const SetZoom = ({ label, setScale }) => {

    const scaleRef = useRef(null);
    const [dropdown, setDropdown] = useOutsideEvent(scaleRef, false);
    const toggleDropdown = () => setDropdown(!dropdown);
    useOutsideEvent(scaleRef, false);

    return (
        <div ref={scaleRef} style={{position: 'relative', zIndex: '10000'}}>
            <button className="csbutton"
                type="button"
                aria-haspopup="menu"
                aria-expanded={dropdown ? "true" : "false"}
                onClick={toggleDropdown}
            >
                {`${(label * 100).toFixed(0)}%`} <BsCaretDownFill className='dropdown-caret' />
            </button>
            <ul className={`dropdown ${dropdown ? "show" : ""}`}>
                <li className="menu-item">
                    <button onClick={() => setScale(1)}>Reset</button>
                </li>
                <li className="menu-item">
                    <button onClick={() => setScale(0.5)}>50%</button>
                </li>
                <li className="menu-item">
                    <button onClick={() => setScale(0.75)}>75%</button>
                </li>
                <li className="menu-item">
                    <button onClick={() => setScale(1)}>100%</button>
                </li>
                <li className="menu-item">
                    <button onClick={() => setScale(1.5)}>150%</button>
                </li>
                <li className="menu-item">
                    <button onClick={() => setScale(2)}>200%</button>
                </li>
            </ul>
        </div>
    );
};

export default SetZoom;
