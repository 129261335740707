// EBEHandlerUI.js
import React from "react";
import EBEForm from "./EBEForm";
import EBEList from "./EBEList";
import CollapsibleComponent from "../UI/CollapsibleComponent";

import "../../css/Editor.css";

const EBEEditor = () => {

    return (
            <CollapsibleComponent
                label="EBE Editor"
            >          
                <EBEForm />
                <EBEList />
        </CollapsibleComponent >
    );
};

export default EBEEditor;
