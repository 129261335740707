import React from 'react';
import GeneratorForm from './GeneratorForm';
import GeneratorList from './GeneratorList';
import CollapsibleComponent from '../UI/CollapsibleComponent';
import '../../css/Editor.css';

const GeneratorUI = () => {
    return (
        <CollapsibleComponent label="Dynamic Relationship Editor">
            <GeneratorForm />
            <GeneratorList />
        </CollapsibleComponent>
    );
};

export default GeneratorUI;
