import { useState, useContext, useEffect, useRef } from "react";
import { GeneratorContext } from "./GeneratorLogic";
import { ModelContext } from "../Model/ModelProvider";
import { BsTrash } from "react-icons/bs";
import { nanoid } from 'nanoid';

// Styles
import "../../css/Form.css";

const initialState = { id: '', source: '', target: '', description: 'generates', processType: '' }

const GeneratorForm = () => {
    const { saveGen, selectedGenerator, setSelectedGenerator, removeGenerator, getAvailableTargets } = useContext(GeneratorContext);
    const { getUoWEBEs } = useContext(ModelContext);
    const [generatorData, setGeneratorData] = useState(selectedGenerator || initialState);


    useEffect(() => {
        setGeneratorData(selectedGenerator || initialState);
    }, [selectedGenerator]);

    const [isChanged, setIsChanged] = useState(false)

    const [sourceId, setSourceId] = useState(selectedGenerator?.source || '');
    const [availableTargets, setAvailableTargets] = useState([]);

    useEffect(() => {
        const targets = getAvailableTargets(generatorData.source);
        setAvailableTargets(targets);
    }, [generatorData.source, getAvailableTargets]);


    const setSource = (event) => {
        setSourceId(event.target.value)
        handleChange(event)
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;
        setGeneratorData((values) => ({ ...values, [name]: value }));
        setIsChanged(true);
    };

    const handleKeyDown = (event) => {
        const trimmedText = event.target.value.trim();
        if ((event.key === "Enter" || event.key === "Tab") && trimmedText) {
            event.preventDefault();
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const id = generatorData.id ? generatorData.id : nanoid()
        const generator = { ...generatorData, id }; // replace id in GeneratorData, isn't strictly necessary as name chnge is disabled but...
        saveGen(generator);
    };

    const handleReset = () => {
        // back out current changes
        setGeneratorData(generatorData || initialState);
        setIsChanged(false);
        setSelectedGenerator(null);
    };

    const displayNames = useRef({})
    useEffect(() => {
        if (generatorData) {
            const uowEBEs = getUoWEBEs();
            const sourceEBE = uowEBEs.find(s => s.id === generatorData.source);
            const targetEBE = uowEBEs.find(s => s.id === generatorData.target);

            displayNames.current = {
                sourceName: (generatorData.source === 'External') ? 'External' : (sourceEBE ? sourceEBE.name : 'No Source Selected'),
                targetName: targetEBE ? targetEBE.name : 'No Target Selected',
            }
        }
    }, [generatorData, getUoWEBEs])

    const [formReady, setFormReady] = useState(false)
    useEffect(() => {
        generatorData.source && generatorData.target && generatorData.processType
            ? setFormReady(true)
            : setFormReady(false)
    },
        [generatorData.source, generatorData.target, generatorData.processType])

    const onDelete = () => {
        removeGenerator(generatorData);
        setIsChanged(false);
    };

    const resetForm = () => {
        // clear the form and selected gen
        setGeneratorData(initialState)
        setIsChanged(false)
        setSelectedGenerator(null);
    }

    const handleClear = () => {
        resetForm()
    }

    const PROCTYPE = ['Service', 'Task Force', '1:1']
    return (
        <>
            <form className="form-inline">
                <fieldset> <legend style={{ fontStyle: 'italic' }}> Add/edit a relationship</legend>
                    {/* Display read-only if in edit mode  */}
                    <>
                        <span className='rotext'
                            style={{
                                display: (!selectedGenerator) ? 'none' : 'inline-block'
                            }}>
                            {displayNames.current.sourceName}
                        </span>
                        <select name='source'
                            value={sourceId}
                            onChange={(e) => setSource(e)}
                            style={{ display: (generatorData.id) ? 'none' : 'inline-block' }}
                        >
                            <option value="" style={{ fontStyle: 'italic' }}>Select source</option>
                            <option value="External">External</option>
                            {getUoWEBEs().map((source, index) => {
                                return <option key={source.id} value={source.id}>{source.name}</option>;
                            })}
                        </select>
                    </>
                    <textarea
                        name='description'
                        value={generatorData.description}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        disabled={!generatorData.source}
                        placeholder="generates"
                        rows='1'
                        cols='32'
                    />

                    <span className='rotext'
                        style={{
                            display: (!selectedGenerator) ? 'none' : 'inline-block'
                        }}>
                        {displayNames.current.targetName}
                    </span>
                    <select name='target' value={generatorData.target} onChange={handleChange} disabled={!generatorData.source}
                        style={{ display: (selectedGenerator) ? 'none' : 'inline-block' }}
                    >
                        <option value="" style={{ fontStyle: 'italic' }}>Select target</option>
                        {availableTargets.map((target) => {
                            return <option key={target.id} value={target.id}>{target.name}</option>;
                        })}
                    </select>

                    <select name='processType' value={generatorData.processType} onChange={handleChange} disabled={!generatorData.target}>
                        <option value="" style={{ fontStyle: 'italic' }}>Select process type</option>
                        {PROCTYPE.map(t => <option key={t} value={t}>{t}</option>)}
                    </select>
                    <br />
                    <div className="button-container">
                        <button name='submitBtn' type='button' className='csbutton' disabled={!(formReady && isChanged)}
                            onClick={handleSubmit} >Save</button>
                        <div className="right-buttons">
                            <button name='restBtn' type='button' className='csbutton' disabled={!isChanged}
                                onClick={handleReset}  >Reset</button>
                            <button name='clearBtn' type='button' className='csbutton' disabled={!generatorData.source}
                                onClick={handleClear}  >Clear</button>
                            <span className="trashCanButton"
                                title="Delete this relation!"
                                style={{
                                    visibility: (!selectedGenerator) ? 'hidden' : 'visible'
                                }}
                                onClick={onDelete}>
                                <BsTrash className="cs-icon" ></BsTrash>
                            </span>
                        </div>
                    </div>
                </fieldset>
            </form>
            <div className='footer'>
                <div className='centre-content'>
                    <figcaption>
                        Select existing relationships from the list to edit them here.
                    </figcaption>
                </div>
            </div>
        </>
    );
};

export default GeneratorForm;
