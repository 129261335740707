import { useContext, useState } from 'react';
import { ModelContext } from './ModelProvider';
import uploadJsonFile from '../IO/fileUpload';
import downloadJsonFile from '../IO/fileDownload';

export const ModelFileLogic = ({ children }) => {
    const { getModel, getModelMeta, updateModel, isChanged, setIsChanged } = useContext(ModelContext);
    const [dropdown, setDropdown] = useState(false);
    const [error, setError] = useState(null);
    const [showModelForm, setShowModelForm] = useState(false);

    const saveModelToFile = () => {
        const model = getModel();
        const modelFileName = getModelMeta().name.replace(/\s/g, "") || "model";
        downloadJsonFile(model, modelFileName + ".json");
        updateModel("SAVE_MODEL", null, () => {
            console.log("model export to file requested");
            setIsChanged(false); //
        });
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        uploadJsonFile(file, (err, content) => {
            if (err) {
                setError(err.message);
            } else {
                updateModel("CHANGE_MODEL", { ...content }, () => {
                    console.log("model updated");
                    isChanged && setIsChanged(false); // reset isChanged flag after loading
                });
                setError(null);
            }
        });
    };

    const handleFileSelect = (event) => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "application/json";
        input.onchange = (event) => {
            handleFileChange(event);
            input.remove(); // Ensure the input element is removed after handling the file
        };
        document.body.appendChild(input);
        input.click();
    };

    const resetModel = () => {
        updateModel("RESET_MODEL", null, () => {
            console.log("new model requested");
            setIsChanged(false); // 
            setShowModelForm(true);
        });
    };

    return children({
        dropdown, setDropdown, error, showModelForm, setShowModelForm,
        saveModelToFile, handleFileSelect, resetModel, isChanged
    });
};

export default ModelFileLogic;