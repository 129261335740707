import {  useContext, } from 'react'
import {SecondCutContext} from './SecondCutLogic'
import PAD2Diagram from './PAD2Diagram';
import PAD2Editor from './PAD2Editor';
import DeliveryChainEditor from './DeliveryChainEditor';
import ModalDialog from '../ModalDialog';
import HResizable from '../../UI/Resizer';
import RADPicker from '../../RADHandler/RADPicker';

const SecondCutUI = ({ isActive }) => {
    const { isModalOpen, handleMerge, handleReplace } = useContext(SecondCutContext);

    console.log("SecondCutUI isActive:", isActive);
    if (!isActive) return null;

    return (
        <div className='tabcontent pad-container' style={{ position: "relative" }}>
            <div className='flex-pane'>
                    <HResizable minWidth={300}>
                        <PAD2Diagram />
                    </HResizable>
                    <div className='flex-column'>
                        <DeliveryChainEditor />
                        <PAD2Editor />
                    </div>
                    <RADPicker />
                </div>
            <ModalDialog
                isOpen={isModalOpen}
                message="The second-cut has changed due to edits upstream. You have no choice, any changes here will be overwritten?"
                onConfirm={handleMerge}
                onCancel={handleReplace}
            />
        </div>
    )
}

export default SecondCutUI