import React from 'react';

const TriggerIcon = ({ width = 20, height = 10, className = '', style = {} }) => {
    return (
        <svg width={`${width}px`} height={`${height}px`} viewBox={`0 0 ${width * 3 / 2} ${height * 3 / 2}`}
            className={`trigger ${className}`} style={style}>
            {/* Arrow */}
            <path d={`M5,5 l${width * 0.25},0 l${width * 0.15},${height * 0.15} l${width * 0.35},0 v-${height * 0.15} l${width * 0.25},${height * 0.25} l-${width * 0.25},${height * 0.25} v-${height * 0.15} l-${width * 0.35},0 l-${width * 0.15},${height * 0.15} l-${width * 0.25},0 l${width * 0.15},-${height * 0.25} Z`} />

            {/* Short lines at the top and bottom */}
            {/* <line x1={width * 3 / 4} y1={0} x2={width * 3 / 4} y2={width / 4} />
            <line x1={width * 3 / 4} y1={height * 5 / 4} x2={width * 3 / 4} y2={height * 3 / 2} /> */}
        </svg>
    );
};

export default TriggerIcon;
