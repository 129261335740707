import React, { useEffect, useCallback, useRef } from 'react'
import * as d3 from 'd3'
import { graphviz } from 'd3-graphviz'


const GraphvizDiagramInteractive = React.forwardRef(({ data, graphId, setGraphId, children, contextRef }, ref) => {

// The ref parameter is a necessity for the forwardRef not to raise an error, but can I get it to work? No, I fucking well cannot.
// So instead, use this internal ref to do what actually works.
    const vizRef = useRef(null)
    const resetZoom = useCallback(() => {
        if (vizRef.current) {
            graphviz(vizRef.current).resetZoom();
        }
    }, []);

    useEffect(() => {
        if (!vizRef.current) return;

        const handleKeyUp = function (event) {
            console.log('keyup event: ', event)
            if (event.key === 'Escape') {
                setGraphId('');
            }
        };

        if (data) {
            const attachEventListeners = () => {
                const graphContainer = d3.select(vizRef.current);
                const padContainer = d3.select(vizRef.current).select(function () { return this.closest('.pad-container') });
                
                graphContainer.selectAll('.node,.edge').on('click', function (event, d) {
                    console.log('click event: ', event)
                    // Handle node/edge click events
                    const clickedElement = d3.select(this);
                    setGraphId(clickedElement.attr('id'))
                });
                graphContainer.selectAll('.node').on("contextmenu", function (event, d) {
                    console.log('contextmenu event: ', event)
                    // Handle node contextmenu events
                    event.preventDefault();
                    const clickedElement = d3.select(this);
                    setGraphId(clickedElement.attr('id'))
                });
                // unselect if mouse exits the general PAD area
                padContainer.on("mouseleave", function (event) {
                    setGraphId('')
                });

                // keyup outside of nodes
                d3.select(document).on("keyup", handleKeyUp);

                // Handle click outside of nodes/edges
                graphContainer.on("click", function (event) {
                    if (!event.target.closest('.node, .edge')) {
                        console.log('click outside event: ', event)
                        setGraphId('');
                    }
                });

            };
            const render = () => {
                graphviz(vizRef.current)
                    .keyMode('id')
                    .width('100%')
                    .height('100%')
                    .fit(true)
                    .zoomScaleExtent([0.5, 2])
                    .renderDot(data)
                    .on("end", attachEventListeners);
            };

            render();
        }

        // Cleanup 
        return () => {
            d3.select(document).on("keyup", null); // Detach document-wide keyup event listener
            // If needed, detach other event listeners similarly
        };

    }, [data, setGraphId]);

    const highlightGraphElement = useCallback((id) => {
        if (!vizRef.current) return;
        // Clear previous highlights
        d3.select(vizRef.current).selectAll('.node, .edge')
            .classed('highlighted', false)
        if (id) {
            // Set the newly selected element class to 'highlighted'. CSS will handle the styling
            const element = d3.select(`#${id}`);
            element.classed('highlighted', true);
        }
    }, []);

    useEffect(() => {
        // Update the highlight based on the current graphId
        highlightGraphElement(graphId);
    }, [graphId, highlightGraphElement]);

    return (
        <div ref={contextRef} className='flex-container'>
            <div className='graph-frame' ref={vizRef} >
            </div>
            <div style={{ padding: '10px' }} >
                <div className='block-buttons'>
                    <button type='button' className='csbutton'
                        onClick={resetZoom}
                        style={{ display: "block" }}
                    >
                        ⇆ Re-centre
                    </button>
                    {children}
                </div>
            </div>
        </div>
    )
}
)

export default GraphvizDiagramInteractive