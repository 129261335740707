const NoPage = () => {

    return (
    <div className='page'>
        <h1>404</h1>
    </div>
    )
};

export default NoPage;
