import React, { createContext, useContext, useState } from 'react';

const DropContext = createContext();

export const DropProvider = ({ children }) => {
    const [draggedShape, setDraggedShape] = useState(null);
    const [isDragging, setIsDragging] = useState(false);

    return (
        <DropContext.Provider value={{ draggedShape, setDraggedShape, isDragging, setIsDragging }}>
            {children}
        </DropContext.Provider>
    );
};

export const useDrop = () => useContext(DropContext);
