import React, { useContext } from 'react';
import { ModelContext } from './ModelProvider';
import ModelMenu from './ModelMenu';

//styles
import '../../css/Menus.css'

const ModelMetadata = ({ modelName }) => {
  const { isChanged } = useContext(ModelContext);

  return (
    <div className="model-bar">
      <ul className="button-container">
        <li>
          <ModelMenu />
        </li>
      </ul>
      <div className="centre-content">
        <div className="centre-text">
          {modelName}
          {isChanged && <span> (You have unsaved changes!)</span>}
        </div>
      </div>
    </div>
  );
}

export default ModelMetadata
