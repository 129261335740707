// EBEController.js

import React, { useState, createContext, useContext } from "react";
import { ModelContext } from "../Model/ModelProvider";

export const EBEContext = createContext();

const EBEController = ({ children }) => {
    const { getEBEs, setIsChanged, updateModel } = useContext(ModelContext);
    const ebeList = getEBEs();  // Get the list of EBEs from the model

    const [selectedEBE, setSelectedEBE] = useState(null);
    const [partialName, setPartialName] = useState("");

    const commitChanges = (newEBELIist) => {
        updateModel(
            "UPDATE_MODEL_PART",
            { partKey: "ebes", value: newEBELIist },
            () => {
                setIsChanged(true); // 
            }
        );
    };

    const saveEBE = (ebe) => {
        const existingEBE = ebeList.find(item => item.id === ebe.id);

        const updatedEBEs = existingEBE
            ? ebeList.map(item => item.id === ebe.id ? ebe : item) // Update existing EBE
            : [...ebeList, ebe]; // Add new EBE
        commitChanges(updatedEBEs);
        setSelectedEBE(null);
    };

    const removeEBE = (ebe) => {  // Remove EBE from list
        alert('Any Generator relationships will also be deleted')
        updateModel(
            "DELETE_EBE",
            { value: ebe.id },
            () => {
                setIsChanged(true); // 
            }
        );
        setPartialName("");
        setSelectedEBE(null);
    }
    return (
        <EBEContext.Provider value={{ ebeList, selectedEBE, setSelectedEBE, saveEBE, removeEBE, partialName, setPartialName }}>
            {children}
        </EBEContext.Provider>
    );
};

export default EBEController;
