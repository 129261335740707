import React, { useState, } from 'react';
import ThemePaletteSelector from '../UI/ThemePaletteSelector';
import { Outlet, NavLink as Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';


//styles
import '../../css/Navbar.css'
import '../../css/Menus.css'

const NavBar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav >
            <nav className="navbar">
                <ul>
                    <li className='menu-item'>
                        <Link to="/" >Home</Link>
                    </li>
                    <li className='menu-item'>
                        <Link to="/rad">RAD Tool</Link>
                    </li>
                    <li className='menu-item'>
                        <Link to="/about">About</Link>
                    </li>
                    <li className='menu-item'>
                        <Link to="/examples">Examples</Link>
                    </li>
                </ul>
                <div className="centre-content">
                    <h1 className='navbar-title'>RIVA Business Process Management </h1>
                </div>
                <div className="menu-icon" onClick={toggleMenu}>
                    <FaBars />
                </div>
                <ul className={`nav-menu ${isOpen ? 'active' : ''}`}>
                    <li>
                        <ThemePaletteSelector />
                    </li>
                    <li className='menu-item'>
                        <Link to="/language">Language</Link>
                    </li>
                    <li className='menu-item'>
                        <Link to="/settings">Settings</Link>
                    </li>
                    <li className='menu-item'>
                        <Link to="/help">Help</Link>
                    </li>
                </ul>
            </nav>
            <Outlet />
        </nav>)
}

export default NavBar
