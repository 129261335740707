import React, { Suspense, lazy } from 'react';
import UOWDiagramLogic from './UOWDiagramLogic';

const UOWDiagramUI = lazy(() => import('./UOWDiagramUI'));

const UOWDiagram = () => {
    return (
        <UOWDiagramLogic>
            {({ isCollapsed, setIsCollapsed, graph }) => (
                <Suspense fallback={<div>Loading diagram...</div>}>
                    <UOWDiagramUI
                        isCollapsed={isCollapsed}
                        setIsCollapsed={setIsCollapsed}
                        graph={graph}
                    />
                </Suspense>
            )}
        </UOWDiagramLogic>
    );
};

export default UOWDiagram;
