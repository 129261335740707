import React from 'react';

const PartRepeatIcon = ({ width = 20, height = 20, className = '', style = {} }) => {
    return (
        <svg width={`${width * 2}px`} height={`${height * 3 / 2}px`} viewBox={`0 0 ${width * 4} ${height * 2}`}
            className={`part-refinement ${className}`} style={style}>
            {/* Triangle  */}
            <polygon points={`${width / 3},${height * 11 / 8} ${width * 4 / 3},${height * 11 / 8} ${width * 5 / 6},${height / 4}`} fill="none" />

            {/* Short line at the  bottom */}
            <line x1={width * 5 / 6} y1={height * 11 / 8} x2={width * 5 / 6} y2={height * 7 / 4} />

            {/* Draw a line that extends out to the left from the mid-height of the polygon and curves upwards, about 12px long in total */}
            <path d={`M${width / 8},${height * 3 / 4} q-5,0 -10,-10`} fill="none" />

            {/* Sideline */}
            <line x1={width / 8} y1={height * 3 / 4} x2={width / 2} y2={height * 3 / 4} />

            {/* Asterisk */}
            <text x={width+2} y={height *3/2} fontSize={36} textAnchor="right">*</text>

        </svg>
    );
};


export default PartRepeatIcon;