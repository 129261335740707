// ModalDialog.js
import React from 'react';

//styles
import "../../css/ModalDialog.css";
const ModalDialog = ({ isOpen, message, onConfirm, onCancel }) => {
    if (!isOpen) return null;

    return (
        <div className="modal">
            <div className="modal-content">
                <p>{message}</p>
                <div className="button-container">
                    <div className="right-buttons">
                        <button className='csbutton' onClick={onConfirm}>Merge</button>
                        <button className='csbutton' onClick={onCancel}>Replace</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalDialog;
