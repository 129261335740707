import React from 'react'
import backgroundImage from './riva.jpg'; // Ensure you import the image correctly

const Examples = () => {
    const backgroundStyle = {
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '100%', // This sets the height to full view height
        color: 'white', // Assuming you want white text for visibility
    };

    const aboutStyle = {
        padding: '20px',
        backgroundColor: 'rgba(0,0,0,0.65)', // Dark background for the text box for readability
        color: 'white',
        borderRadius: '10px', // Optional: for rounded corners
        maxWidth: '600px', // Maximum width of the text box
        textAlign: 'center', // Center the text inside the box
    };
    return (
        <div className='page' >
            <div className='window' style={backgroundStyle}>
                <div style={aboutStyle}>
                    <h2>Examples </h2>
                    
                </div>
            </div>
        </div>
    );
}

export default Examples;