
const RoleIcon = ({ width = 20, height = 30, className = '', style = {} }) => {

    return (
        <svg 
            width={`${width}px`}
            height={`${height}px`}
            viewBox={`0 0 ${width * 3 / 2} ${height * 3 / 2}`}
            className={`role-group ${className}`} style={style}>
            <Role width={width} height={height} at={{ x: width / 4, y: height / 4 }} />
        </svg>
    );
};

export default RoleIcon;

// Draw the Role as a group of  elements
export const Role = ({ width = 30, height = 50, className = '', style = {}, at }) => {
    // Ensure `at` has default values to prevent rendering issues
    at = at || { x: 0, y: 0 };

    // Correct way to apply translation in React SVG
    const transform = `translate(${at.x},${at.y})`;

    return (
        <g transform={transform} className={`role ${className}`} style={style}>
            <rect className='role' x={0} y={0} width={width} height={height}
                rx={width / 10} ry={height / 10} fill="none" />
        </g>
    );
};