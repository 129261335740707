// Desc: This component is responsible for editing data in the RAD

import React, { useState, useEffect } from 'react';
import { useRADDispatch, useRAD } from './RADHandlerLogic';

const RADDataEditor = () => {
    const { handleChange } = useRADDispatch();
    const { radID, getRADEditable } = useRAD();


    const [data, setData] = useState(getRADEditable() || {});
    const [active, setActive] = useState(false);

    const handleDataChange = (event) => {
        const name = event.target.name;
        console.log('target name', name, event.target.value);
        const value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;
        setData((values) => ({ ...values, [name]: value }));
        setActive(true);
    };

    const handleComponentReset = () => {
        // back out current changes
        setData(getRADEditable() || {});
        setActive(false);
    };

    useEffect(() => {
        setData(getRADEditable() || {});
    }, [radID, getRADEditable]);

    const handleSubmit = (event) => {
        // Save the changes to the selected component. Might be a bit risky using unqualified data here.
        event.preventDefault();
        handleChange({ type: 'updateRAD', payload: { ...data } });
        setActive(false);
    };

    const handleKeyDown = (event) => {
        const trimmedText = event.target.value.trim();
        if ((event.key === "Enter" || event.key === "Tab") && trimmedText) {
            event.preventDefault();
            // Step to the next field.
            const form = event.target.form;
            const index = [...form].indexOf(event.target);
            form.elements[index + 1].focus();
        }
    };

    return (
        <div className="rad-data-container">
            {radID
                &&
                <>
                    <h3>RAD Editor</h3>
                    <form >
                        <fieldset>
                            <legend>{data?.label || ""}</legend>
                            <label>
                                ID:
                                <input
                                    type="text"
                                    name="id"
                                    value={radID}
                                    disabled
                                />
                            </label>
                            <br />
                            {!data.process_id &&
                                <label>
                                    Label:
                                    <input
                                        type="text"
                                        name="label"
                                        value={data?.label || ""}
                                        placeholder="Enter a new value..."
                                        onChange={handleDataChange}
                                        pattern="[ a-zA-Z0-9_\-]+"
                                        onKeyDown={handleKeyDown}
                                        disabled={!radID}
                                    />
                                </label>}
                            <label>
                                Description:
                                <textarea
                                    name="description"
                                    value={data?.description || ""}
                                    onChange={handleDataChange}
                                    onKeyDown={handleKeyDown}
                                    disabled={!radID}
                                />
                            </label>
                            <label>
                                Viewpoint:
                                <textarea
                                    name="viewpoint"
                                    value={data?.viewpoint || ""}
                                    onChange={handleDataChange}
                                    disabled={!radID}
                                />
                            </label>
                            <div className="button-container">
                                <button
                                    name="submitBtn"
                                    type="button"
                                    className="csbutton"
                                    disabled={!data || !active}
                                    onClick={handleSubmit}
                                >
                                    Save
                                </button>
                                <div className="right-buttons">
                                    <button
                                        name="restBtn"
                                        type="button"
                                        className="csbutton"
                                        disabled={!data || !active}
                                        onClick={handleComponentReset}
                                    >
                                        Reset
                                    </button>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </>

            }
        </div>
    );
}

export default RADDataEditor;