import React, { useContext, useState, useEffect } from 'react'
import { ModelContext } from '../../Model/ModelProvider'
import ShortCircuitList from './ShortCircuitList'
import { getDeliveryChains } from './utils'

const DeliveryChainEditor = () => {
    const { getPAD2 } = useContext(ModelContext)
    const { updatePADEditableSchema } = useContext(ModelContext)
    const { editableSchema } = getPAD2();

    const [deliveryChains, setDeliveryChains] = useState([]);

    // Effect to update delivery chains when editableSchema changes
    useEffect(() => {
        console.log("PAD2SecondCut: delivery chains and short circuits effect");
        if (!editableSchema) return;
        const deliveries = editableSchema.filter(s => s.class === 'delivers');
        if (deliveries.length === 0) {
            setDeliveryChains([]);
            return null
        }
        const deliveryChains = getDeliveryChains(editableSchema)
        if (deliveryChains.length === 0) {
            setDeliveryChains([]);
            return null
        }
        const newDeliveryChains = deliveryChains.filter(c => c.live === 'liveChain');
        setDeliveryChains(newDeliveryChains);
    }, [editableSchema]);


    const traverseDeliveries = (current, last, deliveries, deadChain = []) => {
        console.log('traverseDeliveries', editableSchema)
        if (!deliveries) return [];
        const next = deliveries.find(m => m.source === current.target);
        console.log('traverseDeliveries: next', next)   
        const updatedDeadChain = [...deadChain, current];

        if (next && next.id !== last.id) return traverseDeliveries(next, last, deliveries, updatedDeadChain);
        const deadChains = [...updatedDeadChain, (next ? next : last)];
        return deadChains;
    };

    const getDeliveriesBetween = (deliveries, first, last) => {
        // Given a chain of deliveries, return all deliveries between the first and last nodes in the chain
        return traverseDeliveries(first, last, deliveries);
    };

    const [selectedChain, setSelectedChain] = useState(undefined)

    const [isCollapsed, setIsCollapsed] = useState(false)

    const handleClick = (id) => {
        console.log('showchain: ', id)
        selectedChain === id
            ? setSelectedChain(undefined)
            : setSelectedChain(id)
    }

    const handleShortCircuit = (chain) => {
        console.log('short circuit: ', chain)
        setShortCircuit(chain)
    }

    const renderedListItems = deliveryChains
        .map(deliveryChain => {
            return <DeliveryChainListItem key={deliveryChain.id}
                chain={deliveryChain}
                handleClick={handleClick}
                handleShortCircuit={handleShortCircuit}
                selectedChain={selectedChain} />
        })

    const setShortCircuit = (schain) => {
        // If a short-circuit has been requested, make dead all delivery edges 
        // in the chain, and add a new one between the first and last nodes in the chain.
        // Drop any existing short-circuits that are within the new one.
        const safeSchema = JSON.parse(JSON.stringify(editableSchema));
        const first = schain.at(-1).source
        const last = schain.at(0).target
        const deliveries = safeSchema.filter(s => s.class === 'delivers');
        const newId = `${first}_${last}_delivers`
        const deliver = {
            id: newId,
            class: 'delivers',
            source: first,
            target: last,
            label: 'deliver (sc)',
            tail: 'I',
            status: 'live',
            actionType: 'short-circuit'
        }

        const begin = schain.at(-1)
        const end = schain.at(0)
        const killChain = getDeliveriesBetween(deliveries, begin, end)

        const newMaster = safeSchema.filter(m => !killChain.map(c => c.id).includes(m.id) && !schain.map(c => c.id).includes(m.id))
        const makeDead =
            safeSchema
                .filter(m => m.actionType !== 'short-circuit' && killChain.map(c => c.id).includes(m.id))
                .map(m => ({ ...m, status: 'dead', replacedBy: newId }))
            ;

        const newSchema = [...newMaster, ...makeDead, deliver];
        console.log('newSchema', newSchema);

        updatePADEditableSchema('pad2', newSchema, true);
        console.log('short-circuit complete', editableSchema)
        return null;
    };

    return (
        <>
            {editableSchema
                ? <div >
                    <button className={isCollapsed ? 'collapsible closed' : 'collapsible open'} type='button'
                        onClick={() => setIsCollapsed(!isCollapsed)}>
                        Delivery Chain Editor
                    </button>
                    <div className='content' style={{ display: isCollapsed ? 'none' : 'block', overflowX: 'auto' }}>
                        <div className='liheader'>
                            <h3>Short-circuitable chains</h3>
                            {renderedListItems.length > 0
                                ? <ul className="js-model-list" position='relative'>
                                    {renderedListItems}
                                </ul>
                                : <><p> There are no multi-step delivery chains.</p>
                                    <hr></hr></>
                            }
                        </div>
                        <ShortCircuitList handleClick={handleClick} selectedChain={selectedChain} />
                    </div>
                </div>
                : <p> Schema has not been generated.</p>
            }
        </>
    )
}

const DeliveryChainListItem = ({ chain, handleClick, handleShortCircuit, selectedChain }) => {

    const { getPAD2 } = useContext(ModelContext)
    const { editableSchema } =  getPAD2();

    if (!chain.id) {
        return null
    }
    const { id, live, deliveryChain } = chain
    console.log('deliveryChain', deliveryChain.at(-1).source)
    const { label: initLabel } = editableSchema.find(s => s.class === 'node' && s.id === deliveryChain.at(-1).source);
    const showChain = deliveryChain.reduceRight((prev, curr) => prev + ' ➜ ' + curr.targetNode, initLabel)
    const handleMouseOver = () => {
        //console.log('mouse over chain')
    }

    return (
        <li id={id} onClick={() => { handleClick(id) }} onMouseOver={() => { handleMouseOver() }}
            className={selectedChain === id
                ? 'checked'
                : ''}
            style={{
                paddingLeft: '50px'
            }}
        >
            <em style={{ color: (live === 'liveChain') ? '' : 'gray' }}>
                {showChain}
            </em>
            <div className='right-button-container'>
                <button type='button' className='csbutton'
                    onClick={() => { handleShortCircuit(deliveryChain) }}
                    title="Short-circuit this delivery chain."
                    style={{ display: "block", visibility: (id === selectedChain) ? 'visible' : 'hidden' }}
                >
                    ☇ Short-circuit
                </button>
            </div>
        </li >
    )
}

export default DeliveryChainEditor