import React, { Suspense } from 'react';
import BigPicture from '../../DiagramHandler/BigPicture';

const GraphvizDiagramInteractive = React.lazy(() => import('../../DiagramHandler/GraphvizDiagramInteractive'));
const GraphvizDiagram = React.lazy(() => import('../../DiagramHandler/GraphvizDiagram'));

const PAD1DiagramUI = ({ graph, graphId, setGraphId, restore }) => {
    const caption = 'First-cut process architecture diagram';

    return (
        <>
            {graph ?
                <div className='flex-column'>
                    <Suspense fallback={<div>Loading diagram...</div>}>
                        <BigPicture Component={GraphvizDiagram} componentProps={{ data: graph, title: caption }} />
                        <GraphvizDiagramInteractive data={graph} graphId={graphId} setGraphId={setGraphId} >
                            <button type='button' className='csbutton'
                                onClick={restore}
                                style={{ display: "block" }}
                            >
                                ⏪ Start again
                            </button>
                        </GraphvizDiagramInteractive>
                    </Suspense>
                    <div className='footer'>
                        <div className='centre-content'>
                            <figcaption>{caption}</figcaption>
                        </div>
                    </div>
                </div>
                : <p style={{ textAlign: 'center' }}>Nothing to see here yet!</p>
            }
        </>
    );
};

export default PAD1DiagramUI;