import React, { useRef } from 'react';
import { BsCaretDown } from 'react-icons/bs';
import { useOutsideEvent } from '../hooks/useOutsideEvent';
import ModelForm from './ModelForm';

const ModelMenuUI = ({
    error, showModelForm, setShowModelForm,
    saveModelToFile, handleFileSelect, resetModel, isChanged
}) => {
    const dropdownref = useRef(null);
    const [dropdown, setDropdown] = useOutsideEvent(dropdownref, false);

    useOutsideEvent(dropdownref, () => setDropdown(false));

    const toggleDropdown = () => {
        setDropdown(!dropdown);
    };

    return (
        <div ref={dropdownref} className="menu-item">
            <button className="csbutton"
                type="button"
                aria-haspopup="menu"
                aria-expanded={dropdown ? "true" : "false"}
                onClick={toggleDropdown}
            >
                Model <BsCaretDown className='dropdown-caret' />
            </button>
            <ul className={`dropdown ${dropdown ? "show" : ""}`}>
                <li className="menu-item">
                    <a href="/#" onClick={handleFileSelect}>
                        📁 Import
                    </a>
                    {error && <p>Error: {error}</p>}
                </li>
                <li className="menu-item">
                    <a
                        href="/#"
                        onClick={saveModelToFile}
                        className={!isChanged ? "disabled-link" : ""}
                    >
                        💾 Export
                    </a>
                </li>
                <li className="menu-item">
                    <a href="/#" onClick={resetModel}>
                        📄 New Model
                    </a>
                </li>
                <li className="menu-item">
                    <a href="/#" onClick={() => setShowModelForm(true)}>
                        ✏️ Edit Model
                    </a>
                </li>
            </ul>
            {showModelForm && <ModelForm onClose={() => setShowModelForm(false)} />}
        </div>
    );
};

export default ModelMenuUI;
