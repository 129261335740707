import { useState, useEffect } from "react";
import { throttle } from "lodash"; // Throttling function from lodash

export const useOutsideEvent = (el, initialState) => {
  const [isActive, setIsActive] = useState(initialState);

  useEffect(() => {
    if (!el.current) return;
    // Throttle the event handler to improve performance
    const handler = throttle((event) => {
      if (el.current && !el.current.contains(event.target)) {
        setIsActive(false);
      }
    }, 100); // Adjust throttle time as needed

    if (isActive) {
      document.addEventListener("mousemove", handler);
      document.addEventListener("mousedown", handler);
    }

    return () => {
      document.removeEventListener("mousemove", handler);
      document.removeEventListener("mousedown", handler);
      handler.cancel(); // Cancel any trailing invocations in lodash throttle
    };
  }, [isActive, el]);

  return [isActive, setIsActive];
};
