import React from 'react'
import backgroundImage from './riva.jpg'; // Ensure you import the image correctly

const About = () => {
    const backgroundStyle = {
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '100%', // This sets the height to full view height
        color: 'white', // Assuming you want white text for visibility
    };

    const aboutStyle = {
        padding: '20px',
        backgroundColor: 'rgba(0,0,0,0.65)', // Dark background for the text box for readability
        color: 'white',
        borderRadius: '10px', // Optional: for rounded corners
        maxWidth: '600px', // Maximum width of the text box
        textAlign: 'center', // Center the text inside the box
    };
    return (
        <div className='page' >
            <div className='window' style={backgroundStyle}>
                <div style={aboutStyle}>
                    <h2>About this Application </h2>
                    <p>The Riva BPM application is a tool for describing and designing
                        business process architectures and activities.
                        It helps the analyst working with the business users to describe the
                        core business entities that define what the organisation
                        is about and show how they relate to one another.
                        From there the tool automatically generates
                        a business process architecture, providing the analyst with
                        heuristics to then refine it to describe reality 'as-is' or 'to-be'.
                        Each process in the architecture can then be described using the
                        RAD (Role Activity Diagram) Editor. RADs are excellent for handling
                        the inherent concurrency and general noisiness of real business process
                        management.
                    </p>
                    <p>
                        This tool can be used to describe as-is processes, design to-be processes,
                        generate operating and training documentation to ISO standards, and even provide
                        a route map for IT development. It integrates well with strategic business
                        requirements planning and goal modelling.
                    </p>
                    <p>
                        Riva BPM is an unique tool for organizations seeking to streamline and optimize their
                        process architecture, driving efficiency and innovation in business
                        process management.</p>
                    <p>
                        This application is very much a work in progress. Don't expect it to be
                        perfect or even finished. If you find bugs or have suggestions for improvements please
                        contact me on the main contact page.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default About;