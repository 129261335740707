import React, { useContext } from 'react';
import { ModelContext } from './ModelProvider';
import ModelMetadata from './ModelMetadata';
import { VResizable } from '../UI/Resizer';
import EBEHandler from '../EBEHandler/EBEHandler';
import GeneratorHandler from '../GeneratorHandler/GeneratorHandler';
import UOWDiagram from '../UOWDiagramHandler/UOWDiagram';
import PADHandler from '../PADHandler/PADHandler';


//styles
import '../../css/Window.css'
import '../../css/gvsvg.css' // Graphviz styles
import "../../css/Button.css"; // Button styles
import '../../css/Model.css' // Menu styles


const Model = () => {
    const { getModelMeta } = useContext(ModelContext);
    const modelName = getModelMeta().name;
    return (
        <div>
            <ModelMetadata modelName={modelName} />
            <div className='model-container'>
                <VResizable minHeight={200}>
                    <div className='pane'>
                        <EBEHandler />
                    </div>
                    <div className='pane'>
                        <GeneratorHandler />
                    </div>
                    <div className='pane'>
                        <UOWDiagram />
                    </div>
                </VResizable>
                <div className='window'>
                    <div className='pane'>
                        <PADHandler />
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Model;