import { useState, useEffect, useCallback } from 'react';

const useContextMenu = (el, inset = {width:0, height:0}) => {
    //inset is used to stop menu going off screen.
    const [isActive, setIsActive] = useState(false);
    const [pos, setPos] = useState({ x: 0, y: 0 });


    const handler = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        isActive && setIsActive(false)
    },
        [isActive]);

    const handleContextMenu = useCallback(
        (event) => {
            if (el.current !== null && el.current.contains(event.target)) {
                console.log('context menu', event.target)
                event.preventDefault();
                event.stopPropagation();
                let eventLeft = event.pageX
                let eventTop = event.pageY
                let elBottom = el.current.offsetTop + el.current.offsetHeight
                let elRight = el.current.offsetLeft + el.current.offsetWidth
                let posX = eventLeft < elRight - inset.width ? eventLeft : elRight - inset.width - 10
                let posY = eventTop < elBottom - inset.height ? eventTop : elBottom - inset.height - 10
                setPos({ x: posX, y: posY });
                console.log('context pos', posX, posY);
                setIsActive(true);
            }
        },
        [setIsActive, setPos, el, inset]
    )


    useEffect(() => {
        document.addEventListener("click", handler);
        document.addEventListener("touchstart", handler);
        document.addEventListener("contextmenu", handleContextMenu);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("click", handler);
            document.removeEventListener("touchstart", handler);
            document.removeEventListener("contextmenu", handleContextMenu);
        };
    });

    return [pos, isActive]
}

export default useContextMenu