import { MdDeleteForever } from "react-icons/md";
import RoleIcon from "./ShapeLibrary/RoleIcon";
import StartRoleIcon from "./ShapeLibrary/StartRoleIcon";
import InteractionIcon from "./ShapeLibrary/InteractionIcon";
import ActionIcon from "./ShapeLibrary/ActionIcon";
import TriggerIcon from "./ShapeLibrary/TriggerIcon";
import CaseRefinementIcon from "./ShapeLibrary/CaseRefinementIcon";
import PartRefinementIcon from "./ShapeLibrary/PartRefinementIcon";
import PartRepeatIcon from "./ShapeLibrary/PartRepeatIcon";
import ConnectionIcon from "./ShapeLibrary/ConnectionIcon";
import SpringIcon from "./ShapeLibrary/SpringIcon";

import '../../../css/RAD.css';

const RADContextMenu = ({ svgPosition, selectedComponent,
    handleAddRole, handleNewStartRole, handleNewInteraction, handleNewAction,
    handleNewTrigger, handleNewCaseRefinement, handleNewPartRefinement, handleNewPartRepeat,
    handleRemoveRole, handleNewConnection, handleDeleteConnection, handleDeleteConnectionSection,
    handleNewEllipsis,
    handleDeleteThread,
    handleDeleteRepeat,
    handleAddCondition,
    handleInsertCondition,
    handleAddPartThread,
    handleInsertPartThread,
}) => {

    return (
        <div className="context-menu" style={{ top: svgPosition.y, left: svgPosition.x }} >
            <ul className="menu">
                {!selectedComponent
                    &&
                    <>
                        <li className="menu__item"
                            onClick={(e) => handleAddRole(e)}>
                            <RoleIcon className='ctx_menu_icon' />
                            Add a new role
                        </li>

                    </>
                }
                {selectedComponent?.class === 'role' &&
                    <>
                        <li className="menu__item"
                            onClick={(e) => handleRemoveRole(e)}>
                            <MdDeleteForever className='ctx_menu_icon menu__trash' />
                            Remove selected role
                        </li>
                        <li className="menu__item" onClick={(e) => handleNewStartRole(e)}>
                            <StartRoleIcon className='ctx_menu_icon' />
                            <span > Start a role in a new thread</span>
                        </li>
                        <li className="menu__item" onClick={(e) => handleNewInteraction(e)}>
                            <InteractionIcon className='ctx_menu_icon' />
                            Add an interaction in a new thread
                        </li>
                        <li className="menu__item" onClick={(e) => handleNewAction(e)}>
                            <ActionIcon className='ctx_menu_icon' />
                            Add an action in a new thread
                        </li>
                        <li className="menu__item" onClick={(e) => handleNewTrigger(e)}>
                            <TriggerIcon className='ctx_menu_icon' />
                            Add a trigger in a new thread
                        </li>
                        <li className="menu__item" onClick={(e) => handleNewEllipsis(e)}>
                            <SpringIcon className='ctx_menu_icon' />
                            Add an ellipsis in a new thread
                        </li>
                    </>
                }
                {
                    selectedComponent && selectedComponent.class === 'thread' &&
                    <>
                        <li className="menu__item" onClick={(e) => handleNewStartRole(e)}>
                            <StartRoleIcon className='ctx_menu_icon' />
                            <span> Start a role on selected thread</span>
                        </li>
                        <li className="menu__item" onClick={(e) => handleNewInteraction(e)}>
                            <InteractionIcon className='ctx_menu_icon' />
                            Add an interaction to selected thread
                        </li>
                        <li className="menu__item" onClick={(e) => handleNewAction(e)}>
                            <ActionIcon className='ctx_menu_icon' />
                            Add an action to selected thread
                        </li>
                        <li className="menu__item" onClick={(e) => handleNewTrigger(e)}>
                            <TriggerIcon className='ctx_menu_icon' />
                            Add a trigger to selected thread
                        </li>
                        <li className="menu__item" onClick={(e) => handleNewEllipsis(e)}>
                            <SpringIcon className='ctx_menu_icon' />
                            Add an ellipsis to selected thread
                        </li>
                        <hr />
                        <li className="menu__item" onClick={(e) => handleDeleteThread(e)}>
                            <MdDeleteForever className='ctx_menu_icon menu__trash' />
                            Delete the thread
                        </li>
                    </>
                }
                {
                    selectedComponent?.class.includes('interaction') &&
                    <>
                        <li className="menu__item" onClick={(e) => handleNewConnection(e)}>
                            <ConnectionIcon className='ctx_menu_icon' />
                            Connect to another interaction
                        </li>
                        <hr />
                    </>
                }
                {
                    selectedComponent?.class.includes('activity') &&
                    <>
                        <li className="menu__item" onClick={(e) => handleNewStartRole(e)}>
                            <StartRoleIcon className='ctx_menu_icon' />
                            <span>Insert Start a role</span>
                        </li>
                        <li className="menu__item" onClick={(e) => handleNewInteraction(e)}>
                            <InteractionIcon className='ctx_menu_icon' />
                            Insert interaction
                        </li>
                        <li className="menu__item" onClick={(e) => handleNewAction(e)}>
                            <ActionIcon className='ctx_menu_icon' />
                            Insert action
                        </li>
                        <li className="menu__item" onClick={(e) => handleNewTrigger(e)}>
                            <TriggerIcon className='ctx_menu_icon' />
                            Insert trigger
                        </li>
                        <hr />
                        <li className="menu__item" onClick={(e) => handleNewCaseRefinement(e)}>
                            <CaseRefinementIcon className='ctx_menu_icon' />
                            Insert a case refinement
                        </li>
                        <li className="menu__item" onClick={(e) => handleNewPartRefinement(e)}>
                            <PartRefinementIcon className='ctx_menu_icon' />
                            Insert a part refinement
                        </li>
                        <li className="menu__item" onClick={(e) => handleNewPartRepeat(e)}>
                            <PartRepeatIcon className='ctx_menu_icon' />
                            Insert a part repeat
                        </li>
                        <hr />
                        <li className="menu__item" onClick={(e) => handleNewEllipsis(e)}>
                            <SpringIcon className='ctx_menu_icon' />
                            Insert an ellipsis
                        </li>
                    </>
                }
                {
                    selectedComponent?.class === 'connection' &&
                    <> {selectedComponent.interactions.length > 2 &&
                        <li className="menu__item" onClick={(e) => handleDeleteConnectionSection(e)}>
                            <ConnectionIcon className='ctx_menu_icon' />
                            Delete this section
                        </li>}
                        <li className="menu__item" onClick={(e) => handleDeleteConnection(e)}>
                            <ConnectionIcon className='ctx_menu_icon' />
                            Delete the connection
                        </li>
                        <hr />
                    </>
                }
                {
                    selectedComponent?.class === 'case-refinement' &&
                    <li className="menu__item" onClick={(e) => handleAddCondition(e)}>
                        <CaseRefinementIcon className='ctx_menu_icon' />
                        Add a condition
                    </li>
                }
                {
                    selectedComponent?.class === 'part-refinement' &&
                    <li className="menu__item" onClick={(e) => handleAddPartThread(e)}>
                        <PartRefinementIcon className='ctx_menu_icon' />
                        Add a part-thread
                    </li>
                }

                {
                    (selectedComponent?.class === 'case-condition' ||
                        selectedComponent?.class === 'part-thread')
                    &&
                    <>
                        <li className="menu__item" onClick={(e) => handleNewStartRole(e)}>
                            <StartRoleIcon className='ctx_menu_icon' />
                            Start a role on selected sub-thread
                        </li>
                        <li className="menu__item" onClick={(e) => handleNewInteraction(e)}>
                            <InteractionIcon className='ctx_menu_icon' />
                            Add an interaction to selected sub-thread
                        </li>
                        <li className="menu__item" onClick={(e) => handleNewAction(e)}>
                            <ActionIcon className='ctx_menu_icon' />
                            Add an action to selected sub-thread
                        </li>
                        <li className="menu__item" onClick={(e) => handleNewTrigger(e)}>
                            <TriggerIcon className='ctx_menu_icon' />
                            Add a trigger to selected sub-thread
                        </li>
                        <li className="menu__item" onClick={(e) => handleNewEllipsis(e)}>
                            <SpringIcon className='ctx_menu_icon' />
                            Add an ellipsis to selected sub-thread
                        </li>
                        <hr />
                        {(selectedComponent?.class === 'case-condition')
                            &&
                            <>
                                <li className="menu__item" onClick={(e) => handleInsertCondition(e)}>
                                    <CaseRefinementIcon className='ctx_menu_icon' />
                                    Insert a condition
                                </li>
                                <hr />
                                <li className="menu__item" onClick={(e) => handleDeleteThread(e)}>
                                    <MdDeleteForever className='ctx_menu_icon menu__trash' />
                                    Delete the sub-thread
                                </li>
                            </>
                        }
                        {(selectedComponent?.class === 'part-thread')
                            &&
                            <>
                                <li className="menu__item" onClick={(e) => handleInsertPartThread(e)}>
                                    <PartRefinementIcon className='ctx_menu_icon' />
                                    Insert a part-thread
                                </li>
                                <hr />
                                <li className="menu__item" onClick={(e) => handleDeleteThread(e)}>
                                    <MdDeleteForever className='ctx_menu_icon menu__trash' />
                                    Delete the sub-thread
                                </li>
                            </>
                        }
                    </>
                }
                {
                    (selectedComponent?.class === 'part-repeat-thread' ||
                        selectedComponent?.class === 'part-repeat')
                    &&
                    <>
                        <li className="menu__item" onClick={(e) => handleNewStartRole(e)}>
                            <StartRoleIcon className='ctx_menu_icon' />
                            Start a role on selected part repeat
                        </li>
                        <li className="menu__item" onClick={(e) => handleNewInteraction(e)}>
                            <InteractionIcon className='ctx_menu_icon' />
                            Add an interaction to selected part repeat
                        </li>
                        <li className="menu__item" onClick={(e) => handleNewAction(e)}>
                            <ActionIcon className='ctx_menu_icon' />
                            Add an action to selected part repeat
                        </li>
                        <li className="menu__item" onClick={(e) => handleNewTrigger(e)}>
                            <TriggerIcon className='ctx_menu_icon' />
                            Add a trigger to selected part repeat
                        </li>
                        <li className="menu__item" onClick={(e) => handleNewEllipsis(e)}>
                            <SpringIcon className='ctx_menu_icon' />
                            Add an ellipsis to selected part repeat
                        </li>
                        <hr />
                        <li className="menu__item" onClick={(e) => handleDeleteRepeat(e)}>
                            <MdDeleteForever className='ctx_menu_icon menu__trash' />
                            Delete the part repeat
                        </li>
                    </>
                }
            </ul>
        </div>
    )
}

export default RADContextMenu;