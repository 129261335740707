import { useRADDispatch, useRAD } from './RADHandlerLogic';

export const useContextCommands = (selectedComponent) => {
    const { addRole, removeRole, addThread,
        addActivityToActivity, addOpeningActivity, addTerminalActivity,
        addOpeningActivityToSubthread,
        addRefinement, addPartThread, addCaseCondition,
        insertPartThread, insertCaseCondition
    } = useRADDispatch();

    const {
        getThread, getRole, getActivity, getPartRepeatThread,
        getPartThread, getCaseCondition } = useRAD();

    const handleAddRole = (event) => {
        if (selectedComponent) return;
        const position = { x: event.pageX, y: event.pageY }
        //console.log('position', position);
        addRole({ position })
    };
    const handleRemoveRole = (event) => {
        if (!selectedComponent) return;
        // Ask for confirmation
        if (window.confirm('Do you really want to remove this role?')) {
            removeRole(selectedComponent)
        }
    };

    const handleNewInteraction = (event) => {
        if (!selectedComponent) return;
        const shape_class = 'interaction';
        handleNewActivity(event, shape_class, selectedComponent);
    };
    const handleNewAction = (event) => {
        if (!selectedComponent) return;
        const shape_class = 'action';
        handleNewActivity(event, shape_class, selectedComponent);
    };
    const handleNewStartRole = (event) => {
        if (!selectedComponent) return;
        const shape_class = 'start-role';
        console.log('selectedComponent in handler', selectedComponent);
        handleNewActivity(event, shape_class, selectedComponent);
    };
    const handleNewTrigger = (event) => {
        if (!selectedComponent) return;
        const shape_class = 'trigger';
        handleNewActivity(event, shape_class, selectedComponent);
    };

    const handleNewEllipsis = (event) => {
        if (!selectedComponent) return;
        const shape_class = 'ellipsis';
        handleNewActivity(event, shape_class, selectedComponent);
    }

    const handleNewRefinement = (shape_class, selectedComponent) => {
        const { id, thread_id } = selectedComponent;
        const activity = getActivity(id);
        const thread = getThread(thread_id);
        addRefinement({ activity, shape_class, thread });
    }

    const handleNewCaseRefinement = () => {
        if (!selectedComponent) return;
        const shape_class = 'case-refinement';
        handleNewRefinement(shape_class, selectedComponent);
    }

    const handleNewPartRefinement = () => {
        if (!selectedComponent) return;
        const shape_class = 'part-refinement';
        handleNewRefinement(shape_class, selectedComponent);
    }

    const handleNewPartRepeat = () => {
        if (!selectedComponent) return;
        const shape_class = 'part-repeat';
        console.log('new part repeat', selectedComponent);
        handleNewRefinement(shape_class, selectedComponent);
    }

    const handleAddPartThread = () => {
        if (!selectedComponent) return;
        addPartThread({ partRefinement: selectedComponent });
    }

    const handleAddCaseCondition = (event) => {
        if (!selectedComponent) return;
        addCaseCondition({ caseRefinement: selectedComponent });
    }

    const handleInsertPartThread = () => {
        if (!selectedComponent) return;
        insertPartThread({ sibling: selectedComponent });
    }

    const handleInsertCondition = () => {
        if (!selectedComponent) return;
        insertCaseCondition({ sibling: selectedComponent });
    }

    const handleNewActivity = (event, shape_class, selectedComponent) => {
        console.log('handleNewActivity', selectedComponent);
        if (selectedComponent.class === 'thread') {
            const thread_id = selectedComponent.id;
            const thread = getThread(thread_id);
            addTerminalActivity({ thread, shape_class })
        }
        else if (selectedComponent.class === 'role') {

            const role_id = selectedComponent.id;
            const role = getRole(role_id);
            if (!role) {
                return;
            }

            const newThread = addThread({ role_id, position: { x: 0, y: 0 } });
            // Add an activity node to the new thread, once the thread is created
            newThread.then((thread) => {
                addOpeningActivity({ thread, shape_class });
            });
        }
        else if (selectedComponent.class.includes('activity')) {
            const { id, thread_id, poststate_id } = selectedComponent;
            const activity = getActivity(id);
            console.log('activity', id, activity, shape_class);
            if (activity) {
                const thread = getThread(thread_id);
                addActivityToActivity({ thread, shape_class, thread_state: poststate_id });
            }
        }
        else if (selectedComponent.class === 'part-repeat-thread'
            || selectedComponent.class === 'part-repeat'
            || selectedComponent.class === 'part-thread'
            || selectedComponent.class === 'case-condition') {
            const { id } = selectedComponent;
            const subthread = selectedComponent.class === 'part-repeat-thread'
                ? getPartRepeatThread(selectedComponent.part_repeat_id)
                : selectedComponent.class === 'part-repeat'
                    ? getPartRepeatThread(id)
                    : selectedComponent.class === 'part-thread'
                        ? getPartThread(id)
                        : getCaseCondition(id);

            console.log('subthread', id, subthread, shape_class);
            if (subthread) {
                addOpeningActivityToSubthread({ subthread, shape_class });
            }
        }
        else {
            console.log('add shape not implemented yet');
        }
    };

    return {
        handleAddRole, handleRemoveRole, handleNewInteraction, handleNewAction, handleNewStartRole,
        handleNewTrigger, handleNewCaseRefinement, handleNewPartRefinement, handleNewPartRepeat,
        handleNewEllipsis, handleAddPartThread, handleAddCaseCondition, handleInsertPartThread,
        handleInsertCondition
    };
}

