import PAD1DiagramLogic from './PAD1DiagramLogic';
import PAD1DiagramUI from './PAD1DiagramUI';

const PAD1Diagram = () => {
    return (
        <PAD1DiagramLogic>
            {({ graph, graphId, setGraphId, restore }) => (
                <PAD1DiagramUI
                    graph={graph}
                    graphId={graphId}
                    setGraphId={setGraphId}
                    restore={restore}
                />
            )}
        </PAD1DiagramLogic>
    );
};

export default PAD1Diagram;