import React, { useState, useContext, useRef } from 'react'
import PAD2EditorItem from './PAD2EditorItem';
import { ModelContext } from '../../Model/ModelProvider'


const PAD2Editor = () => {
    const { getPAD2 } = useContext(ModelContext);
    const { editableSchema } = getPAD2();

    const [isCollapsed, setIsCollapsed] = useState(false)

    const renderedListItems = editableSchema
        .filter(s => s.class === 'node' && s.status === 'live')
        .map((item) => {
            return <PAD2EditorItem
            // add a stringify to the key to avoid duplicate key error
            key={JSON.stringify(item)}
            process={item} />
        })

    const listRef = useRef(null)

    return (
        <>
            {editableSchema
                ? <div className='pane'>
                    <button className={isCollapsed ? 'collapsible closed' : 'collapsible open'} type='button'
                        onClick={() => setIsCollapsed(!isCollapsed)}>
                        PAD Second-Cut Editor
                    </button>
                    <div className='content' style={{ display: isCollapsed ? 'none' : 'block' }}>
                        <div className='pad-header' >
                            <h3> PAD List</h3>
                        </div>
                        <ul ref={listRef}
                            className="js-model-list js-pad-list"
                            position='relative'>
                            {renderedListItems}
                        </ul>
                        <footer className='footer'>
                            <div className="footer-content">
                                <div className="footer-text" 
                                style = {{fontStyle: 'italic', fontSize: 10}}>
                                    This is what you do here.
                                </div>
                            </div>
                        </footer>

                    </div>
                </div>
                : <p> Schema has not been generated.</p>}
        </>
    )
}

export default PAD2Editor