import React, { useContext } from 'react'
import { ModelContext } from '../../Model/ModelProvider'

const DeadPath = ({ id, deadPath }) => {
    const { getPAD2 } = useContext(ModelContext)
    const { editableSchema } = getPAD2();
    const { label: initLabel } = editableSchema.find(s => s.id === deadPath.at(-1)?.source)

    const path = deadPath.reduceRight((prev, curr) => prev + ' ➜ ' + curr.targetNode, initLabel)

    return (
        <li id={id}     >
            <em style={{
                color: 'gray',
                paddingLeft: '50px'
            }}>{path}</em>
        </li >
    )
}

export default DeadPath