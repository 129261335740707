import React, { useEffect, createContext, useReducer, useState, useMemo } from "react";
import initialModel from "./InitialModel";

// Context
const ModelContext = createContext();

// Reducer. Clone using JSON.parse(JSON.stringify()) to avoid mutating state
function modelReducer(state, action) {
  switch (action.type) {
    case "INITIALIZE":
      console.log("initialize action in modelReducer", action);
      return { ...action.payload };
    case "UPDATE_MODEL_PART":
      console.log("update part action in modelReducer", action);
      const { partKey, value } = action.payload;
      return {
        ...state,
        [partKey]: value,
      };
    case "UPDATE_MODEL":
    case "CHANGE_MODEL":
      console.log("update model action in modelReducer", action);
      return {
        ...action.payload, // payload is the entire model
      };
    case "SAVE_MODEL":
      console.log("save model", action);
      return {
        ...state, // 
      };
    case "RESET_MODEL":
      console.log("reset model action in modelReducer", action);
      return {
        ...initialModel,
      };
    case "DELETE_EBE": {
      // A delete EBE action is dispatched from the EBEHandler
      // and removes the EBE from the model and any associated Generators
      const id = action.payload.value;
      const newEBEs = state.ebes.filter((ebe) => ebe.id !== id);
      const newGens = state.gens.filter((gen) => gen.source !== id && gen.target !== id);
      return {
        ...state,
        ebes: newEBEs,
        gens: newGens,
      }
    };
    case "UPDATE_PAD_SCHEMA": {
      const { padKey, newSchema } = action.payload;
      console.log("reducer UPDATE_PAD_SCHEMA", padKey, newSchema);
      return {
        ...state,
        [padKey]: {
          ...state[padKey],
          schema: JSON.parse(JSON.stringify(newSchema)),
        }
      }
    };
    case "REPLACE_PAD_SCHEMA": {
      const { padKey, newSchema } = action.payload;
      console.log("reducer REPLACE_PAD_SCHEMA", padKey, newSchema);
      return {
        ...state,
        [padKey]: {
          ...state[padKey],
          schema: JSON.parse(JSON.stringify(newSchema)),
          replaced: true,
        }
      }
    };
    case "UPDATE_PAD_EDITABLESCHEMA": {
      const { padKey, editSchema, modified } = action.payload;
      console.log("reducer UPDATE_PAD_EDITABLESCHEMA", padKey, editSchema, modified);
      console.log("reducer UPDATE_PAD_EDITABLESCHEMA newschema", JSON.parse(JSON.stringify(editSchema)));
      return {
        ...state,
        [padKey]: {
          ...state[padKey],
          editableSchema: JSON.parse(JSON.stringify(editSchema)),
          modified: modified,
          replaced: false,
        }
      }
    };
    case "REPLACE_PAD_EDITABLESCHEMA": {
      const { padKey, editSchema } = action.payload;
      console.log("reducer REPLACE_PAD_EDITABLESCHEMA", padKey, editSchema);
      return {
        ...state,
        [padKey]: {
          ...state[padKey],
          editableSchema: JSON.parse(JSON.stringify(editSchema)),
          modified: false,
          replaced: false,
        }
      }
    };
    case "PAD_EDITABLESCHEMA_UNCHANGED": {
      const { padKey } = action.payload;
      console.log("reducer PAD_EDITABLESCHEMA_UNCHANGED", padKey);
      return {
        ...state,
        [padKey]: {
          ...state[padKey],
          modified: false,
          replaced: false,
        }
      }
    };
    case "ADD_RAD": {
      console.log("reducer ADD_RAD", action.payload);
      return { ...state, rads: [...state.rads, action.payload] };
    }
    case "UPDATE_RAD": {
      return { ...state, rads: state.rads.map(rad => rad.id === action.payload.id ? { ...rad, ...action.payload } : rad) };
    }
    case "DELETE_RAD": {
      console.log("reducer DELETE_RAD", action.payload);
      return { ...state, rads: state.rads.filter(rad => rad.id !== action.payload.id) };
    }
    case "DELETE_ALL_RADS": {
      return { ...state, rads: [] };
    }
    default:
      return state;
  }
}

const ModelProvider = ({ children }) => {
  const [isChanged, setIsChanged] = useState(false);

  const [model, dispatch] = useReducer(modelReducer, {}, () => {
    console.log("init function in ModelProvider");
    const savedData = localStorage.getItem("model");
    setIsChanged(false);
    return savedData ? JSON.parse(savedData) : { ...initialModel };
  });

  const updateModel = (type, payload, sideEffectCallback) => {
    return new Promise((resolve, reject) => {
      try {
        dispatch({
          type: type,
          payload: payload,
        });

        if (sideEffectCallback) {
          sideEffectCallback();
        }

        resolve(); 
      } catch (error) {
        reject(error); 
      }
    });
  };

  const updatePADEditableSchema = (padKey, editSchema, modified = false) => {
    console.log("updatePADEditableSchema", padKey, editSchema, modified);
    dispatch({
      type: "UPDATE_PAD_EDITABLESCHEMA",
      payload: { padKey, editSchema: [...editSchema], modified }
    });
    setIsChanged(true);
  };

  const replacePADSchema = (padKey, newSchema) => {
    console.log("replacePADSchema", padKey, newSchema);
    dispatch({
      type: "REPLACE_PAD_SCHEMA",
      payload: { padKey, newSchema: [...newSchema], replaced: true }
    });
  };

  const replacePADEditableSchema = (padKey, force = false) => {
    console.log("replacePADEditableSchema", padKey, force);
    if (model && padKey && model[padKey] && (
      force === true ||
      JSON.stringify(model[padKey].editableSchema) !== JSON.stringify(model[padKey].schema))) {
      console.log("model provider Replacing PAD editable schema,", force ? "forced" : 'unforced,', padKey ? `for ${padKey}` : '');
      dispatch({
        type: "REPLACE_PAD_EDITABLESCHEMA",
        payload: { padKey: padKey, editSchema: [...model[padKey].schema] }
      });
    }
    else {
      console.log("model provider PAD editable schema unchanged,", padKey ? `for ${padKey}` : '');
      dispatch({
        type: "PAD_EDITABLESCHEMA_UNCHANGED",
        payload: { padKey: padKey }
      });
    }
  };

  useEffect(() => {

    const storedModel = localStorage.getItem("model");
    const currentModel = JSON.stringify(model);
    if (!storedModel || storedModel !== currentModel) {
      console.log("set local storage in ModelProvider");
      localStorage.setItem("model", currentModel);
    }
  }, [model]);


  //Shared functions
  const getModel = () => {
    return model;
  };

  const getModelMeta = () => {
    return model.modelMeta;
  };

  const getEBEs = () => {
    return model.ebes;
  };
  // Derive the filtered list of EBEs that are UoWs
  const uowEBEs = useMemo(() =>
    Array.isArray(model.ebes) ? model.ebes.filter(ebe => ebe.isUOW) : [],
    [model.ebes]
  );

  const getUoWEBEs = () => {
    return uowEBEs;
  };

  // Get EBE by id from list of EBEs
  const getEBEById = (id) => {
    return model.ebes.find(ebe => ebe.id === id);
  };


  const generators = useMemo(() =>
    Array.isArray(model.gens) ? model.gens : [],
    [model.gens]
  );

  const getGenerators = () => {
    return generators;
  };

  const getGeneratorById = (id) => {
    return model.gens.find(gen => gen.id === id);
  };

  const getPAD1 = () => {
    return model.pad1;
  };

  const getPAD2 = () => {
    return model.pad2;
  };

  const getRoles = () => {
    return model.roles;
  };

  const getRoleById = (id) => {
    return model.roles.find(role => role.id === id);
  };

  const rads = useMemo(() =>
    Array.isArray(model.rads) ? model.rads : [],
    [model.rads]
  );

  const getRADs = () => {
    return rads;
  };

  const getRADById = (id) => {
    return model.rads.find(rad => rad.id === id);
  };


  const context = {
    getModel, getModelMeta,
    isChanged, setIsChanged, updateModel,
    getEBEs, getEBEById, getGenerators, getGeneratorById,
    getUoWEBEs,
    replacePADSchema,
    replacePADEditableSchema,
    updatePADEditableSchema, 
    getPAD1, getPAD2, 
    getRADs, getRADById,
    getRoles, getRoleById
  }
  return (
    <ModelContext.Provider value={context}>
      {children}
    </ModelContext.Provider>
  );
};

export { ModelContext, ModelProvider, };
