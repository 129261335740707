// In a parent component or page
import EBEController from "./EBEController";
import EBEEditor from "./EBEEditor";

const EBEHandler = () => (
  <EBEController>
    <EBEEditor />
  </EBEController>
);

export default EBEHandler;
