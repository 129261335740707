import React from 'react';
import { useRAD } from './RADHandlerLogic';
import RADDiagram from './RADDiagramWIP';
import RADEditor from './RADEditor';
//import FeedbackConsole from './FeedbackConsole';
import RADLayout from './RADLayout';

const RADHandlerUI = () => {
    const { radID } = useRAD();
    console.log('RADHandlerUI', radID);
    return (
        <div className="flex-column">
            {radID && (
                <>
                    <div id="rad-handler-ui" className="flex-container" style={{ position: "relative" }}>
                        <RADDiagram />
                        <RADEditor />
                    </div>
                    <div className="flex-container" style={{ position: "relative" }}>
                        <RADLayout />
                    </div>
                    {/* <FeedbackConsole messages={messages} clear={clearMessages} /> */}
                </>
            )}
        </div>
    );
};

export default RADHandlerUI;
