import React, { useState, useRef } from 'react';
import Modal from 'react-modal';
import { usePrintComponent } from '../hooks/usePrintComponent'; // Update the path as needed


//styles
import '../../css/Print.css';
import '../../css/ModalWindow.css';
const BigPicture = ({ Component, componentProps }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);
    const componentRef = useRef();
    const printComponent = usePrintComponent();

    return (
        <div>
            <div className='right-button-container' >
                <button className='csbutton' onClick={openModal}>
                    🡥 Expand
                </button>
                <Modal isOpen={modalIsOpen} onRequestClose={closeModal}
                    contentLabel="Expanded View"
                    appElement={document.getElementById('root')}
                    className="modal-content"
                >
                    <div className="big-picture">
                        <div className="right-button-container">
                            <div className="block-buttons">
                                <button className='csbutton' onClick={() => printComponent(componentRef)}>🖨️ Print</button>
                                <button className='csbutton' onClick={closeModal}>🡧 Close</button>
                            </div>
                        </div>
                        <Component {...componentProps} ref={componentRef} />
                    </div>
                </Modal>
            </div>
        </div>
    );
}

export default BigPicture;
