// Desc: This component is responsible for editing the selected component in the RAD

import React, { useState, useEffect } from 'react';
import { useRADDispatch, useRADSelection } from './RADHandlerLogic';

const RADComponentEditor = () => {
    const { handleChange } = useRADDispatch();
    const { selectedComponent } = useRADSelection();

    const [componentData, setComponentData] = useState(selectedComponent);
    const [active, setActive] = useState(false);

    const handleComponentChange = (event) => {
        const { name, type, checked, value } = event.target;
        setComponentData(prevData => ({
            ...prevData,
            [name]: type === "checkbox" ? checked : value,
        }));
        setActive(true);
    };

    const handleComponentReset = () => {
        // back out current changes
        setComponentData(selectedComponent);
        setActive(false);
    };

    useEffect(() => {
        setComponentData(selectedComponent);
    }, [selectedComponent]);

    const [tableRows, setTableRows] = useState([]);
    useEffect(() => {
        // Check if componentData is an object and has properties
        if (componentData && typeof componentData === 'object') {
            const rows = Object.keys(componentData).map(key => (
                <tr key={key}>
                    <td>{key}</td>
                    <td>{componentData[key]}</td>
                </tr>
            ));
            setTableRows(rows);
        }
    }, [componentData]);

    const handleComponentDelete = () => {
        if (selectedComponent) {
            handleChange({ type: 'deleteComponent', payload: { ...componentData } });
        }
        else {
            console.log('Not implemented:', selectedComponent.class);
        }
        setActive(false);
    };

    const handleAddCondition = () => {
        if (selectedComponent) {
            handleChange({ type: 'addCaseCondition', payload: { ...componentData } });
        }
        else {
            console.log('Not implemented:', selectedComponent.class);
        }
        setActive(false);
    };

    const handleInsertCondition = () => {
        if (selectedComponent) {
            handleChange({ type: 'insertCaseCondition', payload: { ...componentData } });
        }
        else {
            console.log('Not implemented:', selectedComponent.class);
        }
        setActive(false);
    };

    const handleAddPartThread = () => {
        if (selectedComponent) {
            handleChange({ type: 'addPartThread', payload: { ...componentData } });
        }
        else {
            console.log('Not implemented:', selectedComponent.class);
        }
        setActive(false);
    };

    const handleInsertPartThread = () => {
        if (selectedComponent) {
            handleChange({ type: 'insertPartThread', payload: { ...componentData } });
        }
        else {
            console.log('Not implemented:', selectedComponent.class);
        }
        setActive(false);
    };

    const handleSubmit = (event) => {
        // Save the changes to the selected component. Might be a bit risky using unqualified componentData here.
        event.preventDefault();
        if (selectedComponent && selectedComponent.class === 'role') {
            handleChange({ type: 'updateRole', payload: { ...componentData } });
        }
        else if (selectedComponent && selectedComponent.class.includes('activity')) {
            handleChange({ type: 'updateActivity', payload: { ...componentData } });
        }
        else if (selectedComponent && selectedComponent.class === 'state') {
            handleChange({ type: 'updateState', payload: { ...componentData } });
        }
        else if (selectedComponent && selectedComponent.class === 'case-refinement') {
            handleChange({ type: 'updateCaseRefinement', payload: { ...componentData } });
        }
        else if (selectedComponent && selectedComponent.class === 'part-refinement') {
            handleChange({ type: 'updatePartRefinement', payload: { ...componentData } });
        }
        else if (selectedComponent && selectedComponent.class === 'part-repeat') {
            handleChange({ type: 'updatePartRepeat', payload: { ...componentData } });
        }
        else if (selectedComponent && selectedComponent.class === 'case-condition') {
            handleChange({ type: 'updateCaseCondition', payload: { ...componentData } });
        }
        else if (selectedComponent && selectedComponent.class === 'part-thread') {
            handleChange({ type: 'updatePartThread', payload: { ...componentData } });
        }
        else {
            console.log('Not implemented:', selectedComponent.class);
        }
        setActive(false);
    };

    const handleKeyDown = (event) => {
        const trimmedText = event.target.value.trim();
        if ((event.key === "Enter" || event.key === "Tab") && trimmedText) {
            event.preventDefault();
            // Step to the next field.
            const form = event.target.form;
            const index = [...form].indexOf(event.target);
            form.elements[index + 1].focus();
        }
    };

    return (
        <div className="rad-data-container">
            {selectedComponent?.id && selectedComponent?.class !== 'connection'
                &&
                <>
                    <hr />
                    <h3>{selectedComponent.class.split(' ')
                        .filter(word => word.toLowerCase() !== 'activity')
                        .join(' ') // 
                        .replace(/^\w/, c => c.toUpperCase())} Editor
                    </h3>
                    <form >
                        <fieldset>
                            {''}
                            <br />
                            {componentData?.class !== 'part-repeat' &&
                                <label>
                                    Label:
                                    <input
                                        type="text"
                                        name="label"
                                        value={componentData?.label || ""}
                                        placeholder="Enter a new value..."
                                        onChange={handleComponentChange}
                                        pattern="[ a-zA-Z0-9_\-]+"
                                        onKeyDown={handleKeyDown}
                                        disabled={!componentData}
                                    />
                                </label>
                            }
                            {componentData?.class === 'role' &&
                                <>
                                    <label>
                                        Pre-exists:
                                        <input
                                            type="checkbox"
                                            name="preexists"
                                            checked={!!componentData.preexists}
                                            onChange={handleComponentChange}
                                        />
                                    </label>
                                    <br />
                                    <label>
                                        Multiple:
                                        <input
                                            type="checkbox"
                                            name="multiple"
                                            checked={componentData.multiple}
                                            onChange={handleComponentChange}
                                        />
                                    </label>
                                </>
                            }
                            {componentData?.class === 'activity interaction'
                                &&
                                <>
                                    <label>
                                        Initiator:
                                        <input
                                            type="checkbox"
                                            name="initiator"
                                            checked={!!componentData.initiator}
                                            onChange={handleComponentChange}
                                        />
                                    </label>
                                </>
                            }
                            {componentData?.class === 'state' &&
                                <label>
                                    Show in RAD:
                                    <input
                                        type="checkbox"
                                        name="show"
                                        checked={!!componentData.show}
                                        onChange={handleComponentChange}
                                    />
                                </label>
                            }
                            {componentData?.class !== 'part-repeat' &&
                                <div className="button-container">
                                    <button
                                        name="submitBtn"
                                        type="button"
                                        className="csbutton"
                                        disabled={!componentData?.label || !active}
                                        onClick={handleSubmit}
                                    >
                                        Save
                                    </button>
                                    <div className="right-buttons">
                                        <button
                                            name="restBtn"
                                            type="button"
                                            className="csbutton"
                                            disabled={!componentData || !active}
                                            onClick={handleComponentReset}
                                        >
                                            Reset
                                        </button>
                                        {componentData?.class !== 'state' &&
                                            <button
                                                name="deleteBtn"
                                                type="button"
                                                className="csbutton"
                                                disabled={!componentData}
                                                onClick={handleComponentDelete}
                                            >
                                                Delete
                                            </button>}
                                    </div>
                                </div>
                            }
                            {componentData?.class === 'part-repeat' &&
                                <div className="button-container">
                                    <button
                                        name="deleteBtn"
                                        type="button"
                                        className="csbutton"
                                        disabled={!componentData}
                                        onClick={handleComponentDelete}
                                    >
                                        Delete
                                    </button>
                                </div>
                            }
                            {componentData?.class === 'case-refinement' &&
                                <div className="button-container">
                                    <button
                                        name="addConditionBtn"
                                        type="button"
                                        className="csbutton"
                                        disabled={!componentData?.label}
                                        onClick={handleAddCondition}
                                    >
                                        Add a condition
                                    </button>
                                </div>
                            }
                            {componentData?.class === 'case-condition' &&
                                <div className="button-container">
                                    <button
                                        name="insertConditionBtn"
                                        type="button"
                                        className="csbutton"
                                        disabled={!componentData?.label}
                                        onClick={handleInsertCondition}
                                    >
                                        Insert a condition (before)
                                    </button>
                                </div>
                            }
                            {componentData?.class === 'part-refinement' &&
                                <div className="button-container">
                                    <button
                                        name="addPartThreadBtn"
                                        type="button"
                                        className="csbutton"
                                        disabled={!componentData?.label}
                                        onClick={handleAddPartThread}
                                    >
                                        Add a thread
                                    </button>
                                </div>
                            }
                            {componentData?.class === 'part-thread' &&
                                <div className="button-container">
                                    <button
                                        name="insertPartThreadBtn"
                                        type="button"
                                        className="csbutton"
                                        disabled={!componentData?.label}
                                        onClick={handleInsertPartThread}
                                    >
                                        Insert a thread (before)
                                    </button>
                                </div>
                            }
                            <div>
                                <hr />
                                <h3>Component Data</h3>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableRows}
                                    </tbody>
                                </table>
                            </div>
                        </fieldset>
                    </form>
                </>
            }
        </div>
    );
}

export default RADComponentEditor;