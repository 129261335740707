import React, { useRef, useEffect } from 'react';
import '../../css/Window.css';


//This component is use to vertically resize a window.
export const VResizable = ({ children, minHeight = 200 }) => {
    const resizableRef = useRef(null);
    const handleRef = useRef(null);

    useEffect(() => {
        const resizableElement = resizableRef.current;
        const handleElement = handleRef.current;

        const onMouseDown = (event) => {
            event.preventDefault();
            window.addEventListener('mousemove', onMouseMove);
            window.addEventListener('mouseup', onMouseUp);
        };

        const onMouseMove = (event) => {
            const dy = event.clientY - resizableElement.getBoundingClientRect().top;
            if (dy > minHeight) {
                resizableElement.style.height = `${dy}px`;
            }
        };

        const onMouseUp = () => {
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
        };

        handleElement.addEventListener('mousedown', onMouseDown);

/*         const onResize = () => {
            if (window.innerWidth < 768) {
                resizableElement.style.height = '100%';
            }
        };

        window.addEventListener('resize', onResize); */

        return () => {
            handleElement.removeEventListener('mousedown', onMouseDown);
            //window.removeEventListener('resize', onResize);
        };
    }, [minHeight]);

    return (
        <div ref={resizableRef} className="window">
            {children}
            <div ref={handleRef} className="v-resizer"></div>
        </div>
    );
};


// This component is used to resize a hpane in a window. 
const HResizable = ({ children, minWidth = 300 }) => {
    const resizableRef = useRef(null);
    const handleRef = useRef(null);

    useEffect(() => {
        const resizableElement = resizableRef.current;
        const handleElement = handleRef.current;

        const onMouseDown = (event) => {
            event.preventDefault();
            window.addEventListener('mousemove', onMouseMove);
            window.addEventListener('mouseup', onMouseUp);
        };

        const onMouseMove = (event) => {
            const dx = event.clientX - resizableElement.getBoundingClientRect().left;
            if (dx > minWidth) {
                resizableElement.style.width = `${dx}px`;
            }
        };

        const onMouseUp = () => {
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
        };

        handleElement.addEventListener('mousedown', onMouseDown);

        const onResize = () => {
            if (window.innerWidth < 768) {
                resizableElement.style.width = '100%';
            }
        };

        window.addEventListener('resize', onResize);

        return () => {
            handleElement.removeEventListener('mousedown', onMouseDown);
            window.removeEventListener('resize', onResize);
        };
    }, [minWidth]);

    return (
        <div ref={resizableRef} className="hpane">
            {children}
            <div ref={handleRef} className="h-resizer"></div>
        </div>
    );
};

export default HResizable;